import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useAppSelector } from '../../../../../../hooks';
import { ComponentSize, TextColor } from '../../../../../../types';
import { Icon, Icons, TextButton, Typography } from '../../../../../shared';
import AddPhoneNumber from './AddPhoneNumber';
import PhoneNumberRow from './PhoneNumberRow';

interface UserPhoneNumbersProps {
  // The current user's data.
  user: { id: string; phoneNumbers: string[] };
}

const UserPhoneNumbers = ({ user }: UserPhoneNumbersProps) => {
  // State
  const [newPhoneNumberIds, setNewPhoneNumberIds] = useState<string[]>([]);

  // Redux
  const {
    settings: { isOpen },
  } = useAppSelector((state) => state.modal);

  // Handles adding a new phone number row.
  const addNewPhoneNumberField = useCallback(() => {
    if (user.phoneNumbers.length + newPhoneNumberIds.length < 3) {
      setNewPhoneNumberIds((prev) => [...prev, uuidv4()]);
    }
  }, [user.phoneNumbers.length, newPhoneNumberIds.length]);

  // Handles removing a phone number row.
  const removeNewPhoneNumber = useCallback((id: string) => {
    setNewPhoneNumberIds((prev) => prev.filter((item) => item !== id));
  }, []);

  useEffect(() => {
    if (isOpen) {
      setNewPhoneNumberIds([]);
    }
  }, [isOpen]);

  return (
    <div className="flex flex-col gap-2">
      {!user.phoneNumbers.length && (
        <div className="flex items-center gap-4 rounded-2xl bg-error px-4 py-2">
          <Icons icon={Icon.CIRCLE_ALERT} color={TextColor.DESTRUCTIVE} size={ComponentSize.LARGE} />
          <Typography color={TextColor.DESTRUCTIVE}>
            Verify the phone number(s) you will call practice prospects from.
          </Typography>
        </div>
      )}
      {user.phoneNumbers.map((phoneNumber, index) => (
        <PhoneNumberRow key={phoneNumber} index={index} phoneNumber={phoneNumber} userId={user.id} />
      ))}
      {newPhoneNumberIds.map((id, index) => (
        <AddPhoneNumber index={index} key={id} user={user} onHide={() => removeNewPhoneNumber(id)} />
      ))}
      <TextButton
        text="Add phone number"
        startIcon={Icon.PLUS}
        fullWidth
        onClick={addNewPhoneNumberField}
        disabled={user.phoneNumbers.length + newPhoneNumberIds.length >= 3}
        tooltip={
          user.phoneNumbers.length + newPhoneNumberIds.length >= 3
            ? 'You can only add up to 3 phone numbers'
            : undefined
        }
      />
    </div>
  );
};

export default UserPhoneNumbers;
