import { Getter, PaginationState } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ButtonColor,
  ButtonVariant,
  DataTableWithActions,
  Icon,
  IconButton,
  MediaPlayer,
  SortableHeader,
  Typography,
  TypographySize,
  UserBadge,
} from '../../components';
import { DATE_COLUMN_WIDTH, PAGINATION_PAGE_SIZE } from '../../constants';
import { useAppSelector, useGetFiltersFromParams } from '../../hooks';
import { useGetCallsMutation } from '../../services';
import {
  CallerInfo,
  CallHistoryFilters,
  CallSortingFilters,
  ComponentSize,
  CustomSortingState,
  DateFormat,
  ProspectColumnData,
  Roles,
  SortingOrder,
  TextColor,
  TimeFormat,
} from '../../types';
import { conditionalArray, conditionalObject, formatDuration, getCallerInfo, getDateFilter } from '../../utils';
import CallHistoryFooter from './CallHistoryFooter';
import useCallActions from './useCallActions';
import CallDetailsDrawer from './CallDetailsDrawer';

const DURATION_COLUMN_WIDTH = '86px';
const CALLER_COLUMN_WIDTH = '250px';

/**
 * Gets the caller filter based on the caller value.
 * If the caller is a phone number, filter by incoming phone number.
 * If the caller is a user ID, filter by user ID.
 */
const parseCallerFilter = (callers?: string[]) => {
  if (!callers) return {};

  const phoneNumbers: string[] = [];
  const userIds: string[] = [];

  callers.forEach((caller) => {
    if (caller.startsWith('+')) {
      phoneNumbers.push(caller);
    } else {
      userIds.push(caller);
    }
  });

  return {
    ...(phoneNumbers.length > 0 && { incomingPhoneNumber: phoneNumbers }),
    ...(userIds.length > 0 && { userId: userIds }),
  };
};

const CallHistoryPage = () => {
  // State to track the clicked row.
  const [clickedRowIndex, setClickedRowIndex] = useState<number | undefined>(undefined);
  // State for pagination settings.
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: PAGINATION_PAGE_SIZE,
  });
  // State for sorting settings.
  const [sorting, setSorting] = useState<CustomSortingState>({
    sortBy: CallSortingFilters.START_TIME,
    sortOrder: SortingOrder.DESC,
  });

  // Redux
  const user = useAppSelector((state) => state.auth.user);
  const isSalesRep = useMemo(() => user?.role === Roles.SALES_REP, [user?.role]);

  // Mutations
  // Fetch calls with loading state management.
  const [getCalls, { data, isLoading }] = useGetCallsMutation();
  const calls = data?.calls || [];
  const totalPages = data?.pagination.totalPages || 0;

  // Ref to the media player element.
  const mediaPlayerRef = useRef<HTMLDivElement | null>(null);

  // Custom hooks
  const filters: CallHistoryFilters = useGetFiltersFromParams();

  const clickedCall = useMemo(() => {
    if (clickedRowIndex === undefined) return;
    return calls[clickedRowIndex];
  }, [calls, clickedRowIndex]);

  const actions = useCallActions(() => setClickedRowIndex(undefined), clickedCall);

  // Reset to page 1 when filters change.
  useEffect(() => {
    if (pagination.pageIndex !== 1) {
      setPagination((prev) => ({ ...prev, pageIndex: 1 }));
    }
  }, [filters, setPagination]);

  const fetchCalls = useCallback(() => {
    getCalls({
      personaId: filters.prospect,
      startTime: getDateFilter(filters.callDate),
      tags: filters.tags,
      pagination,
      sorting,
      ...conditionalObject(!isSalesRep, parseCallerFilter(filters.caller)),
    });
  }, [isSalesRep, filters, pagination, sorting]);

  // Fetch calls whenever filters or pagination settings change.
  useEffect(() => {
    fetchCalls();
  }, [fetchCalls]);

  if (!user) return null;

  // Transform the fetched calls data to match the table's expected row structure.
  const parsedCalls = calls.map((call) => ({
    callDate: call.startTime,
    duration: call.callDuration || 0,
    prospect: {
      name: `${call.practiceProspect.firstName} ${call.practiceProspect.lastName}`,
      jobTitle: call.practiceProspect.jobTitle,
      company: call.practiceProspect.accountName,
    },
    caller: getCallerInfo(call, user),
  }));

  const renderSortableHeader = useCallback(
    (sortingId: CallSortingFilters, title: string) => (
      <SortableHeader title={title} sorting={sorting} setSorting={setSorting} sortingId={sortingId} />
    ),
    [sorting, setSorting]
  );

  // Define columns for the data table.
  const columns = [
    {
      accessorKey: 'duration',
      header: () => renderSortableHeader(CallSortingFilters.CALL_DURATION, 'Duration'),
      width: DURATION_COLUMN_WIDTH,
      cell: ({ getValue }: { getValue: Getter<number> }) => {
        const duration = getValue();
        return formatDuration(duration);
      },
    },
    // Conditionally include the "Caller" column if the user is not a sales representative.
    // This is because a sales rep can only see their own call history.
    ...conditionalArray(!isSalesRep, {
      accessorKey: 'caller',
      header: () => renderSortableHeader(CallSortingFilters.CALLER, 'Caller'),
      width: CALLER_COLUMN_WIDTH,
      cell: ({ getValue }: { getValue: Getter<CallerInfo | undefined> }) => {
        const caller = getValue();
        if (!caller) return 'N/A';
        return <UserBadge title={caller.name} imgSrc={caller.picture} hideAvatarIfNoImgSrc />;
      },
    }),
    {
      accessorKey: 'prospect',
      header: () => renderSortableHeader(CallSortingFilters.PROSPECT, 'Prospect'),
      cell: ({ getValue }: { getValue: Getter<ProspectColumnData> }) => {
        const { name, jobTitle, company } = getValue();
        return <UserBadge title={name} subtitle={`${company} | ${jobTitle}`} />;
      },
    },
    {
      accessorKey: 'callDate',
      header: () => renderSortableHeader(CallSortingFilters.START_TIME, 'Call date'),
      width: DATE_COLUMN_WIDTH,
      cell: ({ getValue }: { getValue: Getter<Date> }) => {
        const date = new Date(getValue());
        return (
          <div className="flex flex-col gap-1">
            <Typography color={TextColor.SECONDARY} size={TypographySize.CAPTION}>
              {dayjs(date).format(TimeFormat.SHORT_TIME)}
            </Typography>
            <Typography size={TypographySize.CAPTION}>{dayjs(date).format(DateFormat.MONTH_DAY)}</Typography>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="flex flex-col items-center gap-8">
        <div className="flex flex-col gap-2">
          <IconButton
            icon={Icon.FAST_FORWARD}
            variant={ButtonVariant.GHOST}
            color={ButtonColor.SECONDARY}
            onClick={fetchCalls}
            tooltip="Refresh"
            size={ComponentSize.X_SMALL}
          />
          <DataTableWithActions
            actions={actions}
            columns={columns}
            isLoading={isLoading}
            data={parsedCalls}
            clickedRowIndex={clickedRowIndex}
            setClickedRowIndex={setClickedRowIndex}
            paginationControls={{ pagination, totalPages, setPagination }}
          />
        </div>
        <CallHistoryFooter />
      </div>

      {/* Call Details Drawer */}
      <CallDetailsDrawer mediaPlayerRef={mediaPlayerRef} />
      {/* MediaPlayer Drawer */}
      <MediaPlayer ref={mediaPlayerRef} />
    </>
  );
};

export default CallHistoryPage;
