import { useCallback } from 'react';
import { useGenerateUploadUrlMutation, useUploadFileMutation } from '../services';

const UPLOAD_FILE_ERROR_MSG = 'Failed to upload file';

const useUploadFile = () => {
  const [generateUploadUrl, { isLoading: isGeneratingUploadUrl }] = useGenerateUploadUrlMutation();
  const [uploadFile, { isLoading: isUploadingFile }] = useUploadFileMutation();

  const handleUploadFile = useCallback(
    async (selectedFile: File) => {
      if (!selectedFile) {
        return { error: 'No file selected' };
      }

      const { type, name, size } = selectedFile;

      try {
        // Generate Upload URL.
        const generateUploadUrlPayload = { type, name, size };
        const { data: generatedUploadUrlData, error: generateUploadUrlError } =
          await generateUploadUrl(generateUploadUrlPayload);

        const uploadUrl = generatedUploadUrlData?.presignedUrl;

        // Check if the upload URL generation was not successful.
        if (generateUploadUrlError || !uploadUrl) {
          return { error: UPLOAD_FILE_ERROR_MSG };
        }

        // Upload File to the Generated URL.
        const { error: uploadFileError } = await uploadFile({
          file: selectedFile,
          uploadUrl,
        });

        // Check if the upload to URL was not successful
        if (uploadFileError) {
          return { error: UPLOAD_FILE_ERROR_MSG };
        }
      } catch (error) {
        console.error(`${UPLOAD_FILE_ERROR_MSG}: `, error);
        return { error: UPLOAD_FILE_ERROR_MSG };
      }
    },
    [generateUploadUrl, uploadFile]
  );

  return { uploadFile: handleUploadFile, isLoading: isGeneratingUploadUrl || isUploadingFile };
};

export default useUploadFile;
