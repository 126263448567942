import { ButtonColor, ButtonVariant, TextButton, Typography, TypographySize, TypographyWeight } from '../../shared';

interface UploadModalSubmitSectionProps {
  children: React.ReactNode;
  isLoading: boolean;
  isSubmitDisabled: boolean;
  title: string;
  onSubmit: () => void;
}

const UploadModalSubmitSection = ({
  children,
  isLoading,
  isSubmitDisabled,
  title,
  onSubmit,
}: UploadModalSubmitSectionProps) => {
  return (
    <div className="flex flex-1 flex-col gap-4">
      <Typography size={TypographySize.H4} weight={TypographyWeight.SEMI_BOLD}>
        {title}
      </Typography>
      <div className="flex flex-col items-end gap-2">
        {children}
        <TextButton
          text="Submit"
          onClick={onSubmit}
          color={ButtonColor.SECONDARY}
          disabled={isSubmitDisabled}
          variant={ButtonVariant.OUTLINE}
          loading={isLoading}
        />
      </div>
    </div>
  );
};

export default UploadModalSubmitSection;
