import { useEffect } from 'react';
import { Banner, BannerType, Icon } from '../../components/shared';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { BannerTypes, closeBanner, openBanner, openZeusModal } from '../../redux/reducers';

const Banners = () => {
  const dispatch = useAppDispatch();
  const banners = useAppSelector((state) => state.modal.banners);
  const impersonatedUser = useAppSelector((state) => state.auth.impersonatedUser);

  useEffect(() => {
    if (impersonatedUser && !banners.includes(BannerTypes.ZEUS)) {
      // Open banner when impersonating a user.
      dispatch(openBanner(BannerTypes.ZEUS));
    } else if (!impersonatedUser && banners.includes(BannerTypes.ZEUS)) {
      // Close banner on ending user impersonation.
      dispatch(closeBanner(BannerTypes.ZEUS));
    }
  }, [banners, impersonatedUser, dispatch]);

  return (
    <div>
      {banners.includes(BannerTypes.ZEUS) && (
        <Banner
          text={`Zeus mode: Impersonating ${impersonatedUser?.name} at ${impersonatedUser?.organization?.name}`}
          icon={Icon.TRAFFIC_CONE}
          type={BannerType.WARNING}
          endAction={{
            title: 'Change user',
            onClick: () => {
              dispatch(openZeusModal());
            },
          }}
        />
      )}
    </div>
  );
};

export default Banners;
