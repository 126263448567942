/**
 * The size of the range
 */
export enum RangeSize {
  X_SMALL = 'xs',
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg',
}
/**
 * Props for the Range component.
 */
export interface RangeProps {
  /** The current value of the range input */
  value: number;
  /** The size of the range input */
  size?: RangeSize;
  /** The minimum value of the range */
  min?: number;
  /** The maximum value of the range */
  max?: number;
  /** Whether to show the step label (optional) */
  showStep?: boolean;
  /** The step increment for the range input (optional) */
  step?: number;
  /** Callback function called when the value changes */
  onChange?: (value: number) => void;
}
