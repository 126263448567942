import { FloatingFocusManager, FloatingOverlay, FloatingPortal, useMergeRefs } from '@floating-ui/react';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { DropdownPlacement } from '../../../types';
import './Dropdown.css';
import { useDropdownContext } from './DropdownContext';

/**
 * DropdownContent component that renders the content of the dropdown.
 * It uses a portal to render the dropdown in a different part of the DOM.
 */
const DropdownContent = forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(function DropdownContent(
  { className, style, ...props },
  propRef
) {
  // Get the floating context and other context values.
  const { context: floatingContext, ...context } = useDropdownContext();

  // Merge refs for the floating element and the prop ref.
  const ref = useMergeRefs([context.refs.setFloating, propRef]);

  // If the dropdown is not open, return null to avoid rendering.
  if (!floatingContext.open) return null;

  const { x: arrowX, y: arrowY } = context.middlewareData.arrow ?? {};

  const staticSide =
    {
      top: DropdownPlacement.BOTTOM,
      right: DropdownPlacement.LEFT,
      bottom: DropdownPlacement.TOP,
      left: DropdownPlacement.RIGHT,
    }[context.placement.split('-')[0]] ?? '';

  return (
    <FloatingOverlay lockScroll>
      <FloatingPortal>
        <FloatingFocusManager context={floatingContext} modal={context.modal}>
          <div>
            <div className="pointer-events-auto fixed inset-0" aria-hidden="true" />
            <span
              ref={ref}
              style={{
                position: context.strategy, // Positioning strategy (absolute or fixed).
                top: context.y ?? 0, // Top position of the floating element.
                left: context.x ?? 0, // Left position of the floating element.
                ...context.floatingStyles, // Additional floating styles.
                ...style, // Inline styles from props.
              }}
              aria-labelledby={context.labelId} // ARIA label ID for accessibility.
              aria-describedby={context.descriptionId} // ARIA description ID for accessibility.
              {...context.getFloatingProps(props)} // Additional floating props.
              className={clsx('display-scrollbar-sm menu dropdown-content', className)}
            >
              {props.children}
              {context.arrowRef && (
                <div
                  ref={context.arrowRef}
                  className=" absolute h-2 w-2 rotate-45 bg-inherit"
                  style={{
                    left: arrowX != null ? `${arrowX}px` : '',
                    top: arrowY != null ? `${arrowY}px` : '',
                    [staticSide]: '-4px',
                  }}
                />
              )}
            </span>
          </div>
        </FloatingFocusManager>
      </FloatingPortal>
    </FloatingOverlay>
  );
});

export default DropdownContent;
