import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ConnectionState {
  hasInternetConnection: boolean;
}

const INITIAL_CONNECTION_STATE: ConnectionState = {
  hasInternetConnection: true,
};

const connectionReducer = createSlice({
  name: 'connection',
  initialState: INITIAL_CONNECTION_STATE,
  reducers: {
    setConnectionStatus: (state, action: PayloadAction<boolean>) => {
      state.hasInternetConnection = action.payload;
    },
  },
});

export const { setConnectionStatus } = connectionReducer.actions;
export default connectionReducer.reducer;
