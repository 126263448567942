import { useAppSelector } from '../../../../../hooks';
import TabHeader from '../TabHeader';
import AddUser from './AddUser';
import UserTable from './UserTable';

const OrganizationTab = () => {
  const organization = useAppSelector((state) => state.auth.organization);

  if (!organization) return null;

  return (
    <div className="flex h-full w-full flex-col">
      <TabHeader title={organization.name}>
        <AddUser />
      </TabHeader>
      <UserTable />
    </div>
  );
};

export default OrganizationTab;
