import { useCallback, useState } from 'react';
import { useHandleApiResponse, useToast } from '../../hooks';
import { AccessDeniedPageStep, Alignment, RequestAccessRoles } from '../../types';
import { useSendAccessRequestEmailMutation } from '../../services';
import { isValidEmail } from '../../utils';
import AccessDeniedLayout from './AccessDeniedLayout';
import { AlertType, TextInput, Typography, TypographySize } from '../../components';
import { INPUT_WIDTH } from '../../constants';

const ERROR_MSG = 'Failed to request access';
const SUCCESS_MSG = 'Access request sent!';

interface WorkEmailStepProps {
  selectedRole: RequestAccessRoles | undefined;
  icp: string;
  setCurrentStep: (step: AccessDeniedPageStep) => void;
}

const WorkEmailStep = ({ selectedRole, icp, setCurrentStep }: WorkEmailStepProps) => {
  // State
  const [error, setError] = useState('');
  const [workEmail, setWorkEmail] = useState('');

  // Hooks
  const { showToast } = useToast();
  const handleApiResponse = useHandleApiResponse();

  // API
  const [requestAccess, { isLoading: isRequestingAccess }] = useSendAccessRequestEmailMutation();

  const handleSubmitRequestAccess = useCallback(async () => {
    if (!selectedRole || !icp) return;

    if (!workEmail || !isValidEmail(workEmail)) return setError('Please enter a valid email.');

    try {
      const response = await requestAccess({ email: workEmail, role: selectedRole, icp });
      handleApiResponse({
        response,
        errorMsg: ERROR_MSG,
        onSuccess: () => setCurrentStep(AccessDeniedPageStep.COMPLETE),
        successMsg: SUCCESS_MSG,
      });
    } catch (error) {
      showToast({ message: ERROR_MSG, type: AlertType.ERROR });
    }
  }, [selectedRole, workEmail, icp, requestAccess, handleApiResponse, showToast, setCurrentStep]);

  return (
    <AccessDeniedLayout
      title="Last step! How can we reach you?"
      actionButtonText="Send me a Practice Prospect"
      onActionButtonClick={handleSubmitRequestAccess}
      buttonLoading={isRequestingAccess}
    >
      <Typography size={TypographySize.H5} alignment={Alignment.CENTER}>
        Where should we send your Practice Prospect?
      </Typography>

      <TextInput
        placeholder="Work email"
        onChange={(e) => {
          setWorkEmail(e.target.value);
          setError('');
        }}
        value={workEmail}
        error={error}
        width={INPUT_WIDTH}
      />
    </AccessDeniedLayout>
  );
};

export default WorkEmailStep;
