export enum RequestAccessRoles {
  SALES_REP = 'SALES_REP',
  SALES_MANAGER = 'MANAGER',
  SALES_ENABLEMENT = 'ENABLEMENT',
  SALES_LEADERSHIP = 'LEADERSHIP',
  OTHER = 'OTHER',
}

export type SendAccessRequestEmailPayload = {
  email: string;
  role: RequestAccessRoles;
  icp: string;
};

export enum AccessDeniedPageStep {
  SELECT_ROLE,
  PROSPECT_EXAMPLE,
  WORK_EMAIL,
  COMPLETE,
}
