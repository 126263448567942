import { ComponentSize } from '../../../../types';
import { SelectSize } from './BaseSelect.types';

export const MULTI_SELECT_CHECKBOX_SIZE = 12;
export const SELECT_DROPDOWN_MAX_HEIGHT = 300;

export const SELECT_ICON_SIZE: Record<SelectSize, ComponentSize> = {
  [ComponentSize.SMALL]: ComponentSize.X_SMALL,
  [ComponentSize.MEDIUM]: ComponentSize.SMALL,
  [ComponentSize.LARGE]: ComponentSize.MEDIUM,
};
