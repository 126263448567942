import { useCallback, useEffect, useState } from 'react';

const useButtonHandlers = ({
  onClick,
  onHover,
  onKeyDown,
}: {
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onHover?: (hover: boolean) => void;
  onKeyDown?: (e: KeyboardEvent) => void;
}) => {
  const [hover, setHover] = useState(false);

  const handleOnClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (onClick) {
        e.stopPropagation();
        e.preventDefault();
        onClick(e);
      }
    },
    [onClick]
  );

  const handleOnMouseEnter = useCallback(() => {
    setHover(true);
    // Call the onHover callback if it exists.
    if (onHover) {
      onHover(true);
    }
  }, [onHover, setHover]);

  const handleOnMouseLeave = useCallback(() => {
    setHover(false);
    // Call the onHover callback if it exists.
    if (onHover) {
      onHover(false);
    }
  }, [onHover, setHover]);

  useEffect(() => {
    if (!onKeyDown) return;
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);

  return {
    hover,
    handleOnClick,
    handleOnMouseEnter,
    handleOnMouseLeave,
  };
};

export default useButtonHandlers;
