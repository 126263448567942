import { useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../../../../hooks';
import { closeScorecardForm, openScorecardForm } from '../../../../../redux/reducers/modalReducer';

import { ScorecardTemplate } from '../../../../../types';
import TabHeader from '../TabHeader';
import ScorecardForm from './ScorecardForm';
import ScorecardsTable from './ScorecardsTable';

const ScorecardsTab = () => {
  // State to store the scorecard ID being edited
  const [editingScorecardId, setEditingScorecardId] = useState<string | undefined>(undefined);

  const dispatch = useAppDispatch();
  const isScorecardFormOpen = useAppSelector((state) => state.modal.settings.scorecardForm.isOpen);

  const handleOpenScorecardForm = () => dispatch(openScorecardForm());

  // Handler on editing a scorecard
  const handleEditScorecard = (scorecard: ScorecardTemplate) => {
    setEditingScorecardId(scorecard.id);
    handleOpenScorecardForm();
  };

  // Handler to reset the editing scorecard state
  const handleResetEditingScorecard = () => {
    setEditingScorecardId(undefined);
    dispatch(closeScorecardForm());
  };

  // Handler on create new scorecard
  const handleCreateNewScorecard = () => {
    setEditingScorecardId(undefined);
    handleOpenScorecardForm();
  };

  // Render the scorecard form
  if (isScorecardFormOpen) {
    return <ScorecardForm onClose={handleResetEditingScorecard} scorecardId={editingScorecardId} />;
  }

  // Render the scorecards table
  return (
    <div className="flex h-full w-full flex-col gap-8">
      <TabHeader title="Scorecard templates" handleCreateNew={handleCreateNewScorecard} />
      <ScorecardsTable onEditScorecard={handleEditScorecard} onCreateNewScorecard={handleCreateNewScorecard} />
    </div>
  );
};

export default ScorecardsTab;
