import { AppOrganization, DBOrganization } from '../types';

export const transformToAppOrg = (org: DBOrganization): AppOrganization => {
  const { displayName, ...rest } = org || {};
  return {
    ...rest,
    name: displayName,
    // unnecessary but just a precaution
    createdAt: org.createdAt ? new Date(org.createdAt) : undefined,
    updatedAt: org.updatedAt ? new Date(org.updatedAt) : undefined,
    renewalDate: org.renewalDate ? new Date(org.renewalDate) : undefined,
  };
};
