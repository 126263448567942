import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Avatar,
  ButtonColor,
  Icon,
  IconButton,
  Spinner,
  TextButton,
  Typography,
  TypographySize,
  TypographyWeight,
} from '../../../../components';
import { SCORECARDS_WIDTH } from '../../../../constants';
import { useAppSelector } from '../../../../hooks';
import { useGetCallScorecardQuery } from '../../../../services';
import { BaseScorecardTemplate, ComponentSize, ScorecardAnswer, ScorecardTemplate, TextColor } from '../../../../types';
import CallScorecardSections from './CallScorecardSections';
import NewCallScorecard from './NewCallScorecard';

interface CallScorecardProps {
  isScorecardOpen: boolean;
  setIsScorecardOpen: (isScorecardOpen: boolean) => void;
}

const CallScorecard = ({ isScorecardOpen, setIsScorecardOpen }: CallScorecardProps) => {
  const { callSid } = useParams();

  // Fetch the scorecard for the current call
  const { data: existingScorecard, isLoading: isScorecardLoading } = useGetCallScorecardQuery(callSid ?? '', {
    skip: !callSid,
  });

  // Saves scorecards answers
  const [answers, setAnswers] = useState<ScorecardAnswer[] | undefined>(undefined);

  // State to handle selected scorecard template
  const [scorecardTemplate, setScorecardTemplate] = useState<BaseScorecardTemplate | ScorecardTemplate | undefined>(
    undefined
  );

  const { user } = useAppSelector((state) => state.auth);

  // If this is an existing scorecard or not
  const isExistingScorecard = useMemo(() => existingScorecard?.id, [existingScorecard]);

  // Memoized sections to render
  const sections = useMemo(() => {
    if (isExistingScorecard) {
      return existingScorecard?.scorecardTemplate?.sections;
    } else if (scorecardTemplate && 'sections' in scorecardTemplate) {
      return scorecardTemplate.sections;
    }
  }, [existingScorecard, scorecardTemplate]);

  // Initialize answers with existing scorecard data if it exists
  useEffect(() => {
    if (!isExistingScorecard) return;

    // Flatten the sections and map to answers
    const initialAnswers = existingScorecard?.scorecardTemplate?.sections.flatMap((section) =>
      section.questions
        .map((question) => ({
          id: question?.answers?.[0]?.id,
          questionId: question?.id,
          response: question?.answers?.[0]?.response,
        }))
        // Filter out undefined responses
        .filter((answer) => answer.response !== undefined)
    );
    setAnswers(initialAnswers || []);
  }, [existingScorecard]);

  const handleClearAnswers = () => {
    if (!isExistingScorecard) setAnswers(undefined);
  };

  // Close scorecard and reset scorecard and scorecard template
  const handleCloseScorecard = useCallback(() => {
    setIsScorecardOpen(false);
    setScorecardTemplate(undefined);
    setAnswers(undefined);
    handleClearAnswers();
  }, [setIsScorecardOpen, setScorecardTemplate, setAnswers, handleClearAnswers]);

  // If this call wasn't scored before then anyone can score it
  // If it was scored then only the one who scored it can edit the scoring
  const canUserEdit = isExistingScorecard ? existingScorecard?.user?.id === user?.id : true;

  // Show scorecard button if scorecard is not open
  // TODO: calculate postion from the top dynamically
  if (!isScorecardOpen) {
    return (
      <div className="absolute right-4 top-14 flex animate-bounce-horizontal items-start justify-end">
        <TextButton
          text="Scorecard"
          className="!origin-bottom-right !-rotate-90"
          onClick={() => setIsScorecardOpen(!isScorecardOpen)}
        />
      </div>
    );
  }

  return (
    <div
      className="display-scrollbar-sm flex h-full flex-col overflow-auto rounded-l-lg border-l shadow-md"
      style={{
        minWidth: SCORECARDS_WIDTH,
        maxWidth: SCORECARDS_WIDTH,
      }}
    >
      <div className="flex flex-grow flex-col gap-2 p-6 pb-0">
        <div className="relative flex items-center">
          <div className="absolute -right-3 -top-3">
            <IconButton
              icon={Icon.CLOSE}
              size={ComponentSize.X_SMALL}
              color={ButtonColor.SECONDARY}
              onClick={handleCloseScorecard}
              rounded
            />
          </div>
        </div>
        <div className="flex flex-grow flex-col gap-4">
          <Typography size={TypographySize.H2} weight={TypographyWeight.SEMI_BOLD} color={TextColor.SECONDARY}>
            {isExistingScorecard ? existingScorecard?.scorecardTemplate?.name : 'Scorecard'}
          </Typography>
          {isScorecardLoading && (
            <div className="flex h-full items-center justify-center">
              <Spinner size={ComponentSize.SMALL} />
            </div>
          )}

          {!isScorecardLoading && (
            <>
              {/* If no existing scorecard, show scorecard selector and create new scorecard button */}
              {!isExistingScorecard && (
                <NewCallScorecard scorecardTemplate={scorecardTemplate} setScorecardTemplate={setScorecardTemplate} />
              )}

              {/* If existing scorecard is found, show scored by who info */}
              {isExistingScorecard && (
                <div className="flex gap-2">
                  <Typography color={TextColor.SECONDARY}>Scored by:</Typography>
                  <Avatar
                    size={ComponentSize.X_SMALL}
                    label={existingScorecard?.user?.name}
                    imgSrc={existingScorecard?.user?.picture}
                  />
                  <Typography color={TextColor.SECONDARY}>{existingScorecard?.user?.name}</Typography>
                </div>
              )}

              {/* Show scorecard data and actions if a scorecard is selected or existing scorecard is found*/}
              {sections?.length && (
                <CallScorecardSections
                  scorecardId={existingScorecard?.id}
                  scorecardTemplateId={scorecardTemplate?.id}
                  scorecardName={existingScorecard?.scorecardTemplate?.name || scorecardTemplate?.name}
                  sections={sections}
                  answers={answers}
                  setAnswers={setAnswers}
                  canUserEdit={canUserEdit}
                  handleCloseScorecard={handleCloseScorecard}
                  handleClearAnswers={handleClearAnswers}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CallScorecard;
