import { useCallback, useState } from 'react';
import { TextInput, Typography, TypographySize, TypographyWeight } from '../../components';
import { INPUT_WIDTH } from '../../constants';
import { AccessDeniedPageStep, Alignment, RequestAccessRoles } from '../../types';
import { isValidURL } from '../../utils';
import AccessDeniedLayout from './AccessDeniedLayout';

interface ProspectExampleStepProps {
  icp: string;
  setIcp: (icp: string) => void;
  setCurrentStep: (step: AccessDeniedPageStep) => void;
  selectedRole?: RequestAccessRoles;
}

const ProspectExampleStep = ({ selectedRole, icp, setIcp, setCurrentStep }: ProspectExampleStepProps) => {
  const [error, setError] = useState('');
  const isSalesRep = selectedRole === RequestAccessRoles.SALES_REP;

  const handleSubmitProspectExample = useCallback(() => {
    if (!icp || !isValidURL(icp)) return setError('Please enter a valid URL.');
    setCurrentStep(AccessDeniedPageStep.WORK_EMAIL);
  }, [icp, setCurrentStep, setError]);

  const handleScheduleDemo = useCallback(() => {
    if (process.env.REACT_APP_CALENDLY_SCHEDULER_URL) {
      window.location.replace(process.env.REACT_APP_CALENDLY_SCHEDULER_URL);
    }
  }, []);

  return (
    <>
      {isSalesRep && (
        <AccessDeniedLayout
          title="Ready to get FullyRamped?"
          actionButtonText="Send me a Practice Prospect"
          onActionButtonClick={handleSubmitProspectExample}
        >
          <div className="flex flex-col items-center gap-8">
            <div className="flex flex-col items-center">
              <Typography size={TypographySize.H5} alignment={Alignment.CENTER}>
                We’ll set up a free Practice Prospect for you to test out.
              </Typography>
              <Typography size={TypographySize.H5} alignment={Alignment.CENTER} weight={TypographyWeight.SEMI_BOLD}>
                Can you share an example of your ICP?
              </Typography>
            </div>

            <TextInput
              placeholder="Linkedin URL of example prospect"
              onChange={(e) => {
                setIcp(e.target.value);
                setError('');
              }}
              error={error}
              value={icp}
              width={INPUT_WIDTH}
            />
          </div>
        </AccessDeniedLayout>
      )}
      {!isSalesRep && (
        <AccessDeniedLayout
          title="Ready to get FullyRamped?"
          actionButtonText="Schedule demo"
          onActionButtonClick={handleScheduleDemo}
        >
          <Typography size={TypographySize.H5} alignment={Alignment.CENTER}>
            Get the most out of FullyRamped for your organization with a demo from one of our experts.
          </Typography>
        </AccessDeniedLayout>
      )}
    </>
  );
};

export default ProspectExampleStep;
