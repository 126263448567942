import { ReactNode } from 'react';
import { Icon, IconComponent } from '../Icons';
import { TypographyComponent } from '../Typography';

export enum DialogType {
  CONFIRM = 'confirm',
  DEFAULT = 'default',
  SETTINGS = 'settings',
}

export interface DialogProps {
  // Content to be rendered inside the modal.
  children: ReactNode;
  // Controls the visibility of the modal.
  isOpen: boolean;
  // Optional class name to be applied to the modal.
  className?: string;
  // Optional description text.
  description?: string | TypographyComponent;
  // Optional footer to be rendered at the bottom of the modal.
  footer?: ReactNode;
  // Whether to hide the close button.
  hideCloseButton?: boolean;
  // Optional icon to be displayed before the title.
  icon?: Icon | IconComponent;
  // Optional title text.
  title?: string;
  // Optional type of the modal that determines its width.
  type?: DialogType;
  // Optional width of the modal.
  width?: string | number;
  // Optional callback function to run when the modal is requested to close.
  onClose?: () => void;
}
