import { useAppDispatch, useAppSelector } from '../../../hooks';
import { closeUploadModal } from '../../../redux/reducers';
import { Orientation, TextColor } from '../../../types';
import { Dialog, DialogType, Divider, Typography, TypographySize } from '../../shared';

import { useEffect, useState } from 'react';
import { PRIVACY_POLICY_URL } from '../../../constants';
import UploadModalSubmitFile from './UploadModalSubmitFile';
import UploadModalSubmitLink from './UploadModalSubmitLink';

const UploadModal = () => {
  // Redux
  const isOpen = useAppSelector((state) => state.modal.isUploadModalOpen);
  const dispatch = useAppDispatch();

  // States
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [linkInput, setLinkInput] = useState('');

  const [fileError, setFileError] = useState('');
  const [linkError, setLinkError] = useState('');

  const [isFileSubmitted, setIsFileSubmitted] = useState(false);
  const [isLinkSubmitted, setIsLinkSubmitted] = useState(false);

  // Reset state on closing the modal.
  useEffect(() => {
    if (!isOpen) {
      setSelectedFile(undefined);
      setLinkInput('');

      setFileError('');
      setLinkError('');

      setIsFileSubmitted(false);
      setIsLinkSubmitted(false);
    }
  }, [isOpen]);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={() => {
        dispatch(closeUploadModal());
      }}
      title="Improve your organization’s Practice Prospects"
      type={DialogType.SETTINGS}
      footer={
        <Typography size={TypographySize.CAPTION} color={TextColor.SECONDARY}>
          What you upload and share is private to your workspace. It is used to create realistic practice prospects, we
          do not train AI models using this information.&nbsp;
          <span className="cursor-pointer underline" onClick={() => window.open(PRIVACY_POLICY_URL, '_blank')}>
            Privacy policy
          </span>
        </Typography>
      }
      description="Make your prospects more realistic by sharing material on your ICP, training material, past calls, or industry specific knowledge."
    >
      <div className="flex gap-4">
        <UploadModalSubmitFile
          error={fileError}
          setError={setFileError}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          isFileSubmitted={isFileSubmitted}
          setIsFileSubmitted={setIsFileSubmitted}
        />
        <Divider type={Orientation.VERTICAL} />
        <UploadModalSubmitLink
          linkInput={linkInput}
          setLinkInput={setLinkInput}
          error={linkError}
          setError={setLinkError}
          isLinkSubmitted={isLinkSubmitted}
          setIsLinkSubmitted={setIsLinkSubmitted}
        />
      </div>
    </Dialog>
  );
};

export default UploadModal;
