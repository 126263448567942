import { useMergeRefs } from '@floating-ui/react';
import clsx from 'clsx';
import { cloneElement, forwardRef, isValidElement } from 'react';
import { useTooltipContext } from './TooltipContext';

/**
 * TooltipTrigger component that serves as the element which triggers the tooltip.
 * It supports being wrapped around a child element or as a standalone span.
 */
const TooltipTrigger = forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement> & { asChild?: boolean; fullWidth?: boolean }
>(function TooltipTrigger({ children, asChild = false, fullWidth, ...props }, propRef) {
  const context = useTooltipContext();
  const childrenRef = (children as React.HTMLProps<HTMLElement>).ref as React.RefObject<HTMLElement>;
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

  // If asChild is true and children is a valid React element,
  // clone the element and pass props.
  if (asChild && isValidElement(children)) {
    return cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        'data-state': context.open ? 'open' : 'closed',
      }) as React.Attributes & React.HTMLAttributes<HTMLElement>
    );
  }

  // Render a span element as the default trigger.
  return (
    <span
      ref={ref}
      data-state={context.open ? 'open' : 'closed'}
      {...context.getReferenceProps(props)}
      className={clsx('flex items-center', fullWidth ? 'w-full' : 'w-fit')}
    >
      {children}
    </span>
  );
});

export default TooltipTrigger;
