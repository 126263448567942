import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { closeWebCall, WebCallData } from '../../../redux/reducers';
import { Orientation } from '../../../types';
import { Divider, getMaxTagsAndNotesHeight, TagsAndNotesCell } from '../../shared';
import WebCallActions from './WebCallActions';
import WebCallProspect from './WebCallProspect';
import WebCallStatus from './WebCallStatus';
import WebCallUpNext from './WebCallUpNext';

// Use your own Twilio number for testing in development by passing it to startCall.
// const TWILIO_NUMBER = '+18589464419';

interface WebCallModalContentProps {
  currWebCall: WebCallData;
  isCalling: boolean;
  isConnected: boolean;
  isHangingUp: boolean;
  isMuted: boolean;
  startTime: number;
  mute: () => void;
  unmute: () => void;
  startCall: (number: string, userId: string) => void;
  stopCall: () => void;
}

const WebCallModalContent = ({
  currWebCall,
  isCalling,
  isConnected,
  isHangingUp,
  isMuted,
  startTime,
  mute,
  unmute,
  startCall,
  stopCall,
}: WebCallModalContentProps) => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.auth.user?.id);

  const maxTagsHeight = getMaxTagsAndNotesHeight();

  const { currProspect, nextProspect } = currWebCall;

  // Handles calling the given number.
  const callNumber = useCallback(
    (number: string) => {
      if (userId) {
        startCall(number, userId);
      }
    },
    [userId, startCall]
  );

  // Handles closing the web call modal.
  const onCloseWebCallModal = useCallback(() => {
    dispatch(closeWebCall());
  }, [dispatch]);

  // Start a new call whenever the prospect changes.
  useEffect(() => {
    const timeoutId = setTimeout(() => callNumber(currProspect.associatedPhoneNumber), 0);
    return () => clearTimeout(timeoutId);
  }, [currProspect, callNumber]);

  // End the call when the user unloads the page.
  useEffect(() => {
    const handleBeforeUnload = () => {
      stopCall();
      onCloseWebCallModal();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [stopCall, onCloseWebCallModal]);

  return (
    <div className="flex items-center gap-8" style={{ height: maxTagsHeight }}>
      <div className="flex-1">
        <WebCallProspect prospect={currProspect} />
      </div>
      <Divider type={Orientation.VERTICAL} />
      <div className="flex-[1.5]">
        <TagsAndNotesCell
          prospectId={currProspect.personaId}
          prospectTags={currProspect.tags}
          notes={currProspect.notes}
        />
      </div>
      <Divider type={Orientation.VERTICAL} />
      <div className="flex-1">
        <WebCallStatus
          isCalling={isCalling}
          isConnected={isConnected}
          isHangingUp={isHangingUp}
          startTime={startTime}
          redial={() => callNumber(currProspect.associatedPhoneNumber)}
        />
      </div>
      <Divider type={Orientation.VERTICAL} />
      <div className="flex-[1.25]">
        {isCalling && (
          <WebCallActions endCall={stopCall} isHangingUp={isHangingUp} isMuted={isMuted} mute={mute} unmute={unmute} />
        )}
        {!isCalling && <WebCallUpNext currentProspect={currProspect} nextProspect={nextProspect} />}
      </div>
    </div>
  );
};

export default WebCallModalContent;
