/**
 * Conditionally inserts key-value pairs into an array.
 */
export const conditionalArray = <T>(condition: boolean, ...elements: T[]): T[] => (condition ? elements : []);

/**
 * Conditionally inserts key-value pairs into an object.
 */
export const conditionalObject = <T>(condition: boolean, elements: T): Partial<T> => {
  return condition ? { ...elements } : {};
};
