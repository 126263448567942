import { io } from 'socket.io-client';

const SOCKET_URL = process.env.REACT_APP_API_ROOT_URL as string;

export const socket = io(SOCKET_URL, {
  autoConnect: false,
});

socket.on('connect', () => {
  console.log('Socket connected successfully');
});

socket.on('disconnect', (reason) => {
  console.log(`Socket disconnected: ${reason}`);
});
