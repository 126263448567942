/**
 * Checks if a phone number is valid.
 */
export const isValidPhoneNumber = (phoneNumber: string): boolean => {
  const phoneNumberPattern = /^\+\d{11,18}$/;
  return phoneNumberPattern.test(phoneNumber);
};

/**
 * Utility function to check if a URL is valid.
 */
export const isValidURL = (url: string): boolean => {
  try {
    // Try to construct a new URL object
    new URL(url);
    return true;
  } catch {
    // If an error is thrown, it's not a valid URL
    return false;
  }
};

/**
 * Utility function to check if an email is valid.
 */
export const isValidEmail = (email: string): boolean => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

/**
 * Validate a country code input.
 */
export function isValidCountryCode(newCode: string): boolean {
  if (newCode === '+') return true;
  if (!Number(newCode)) return false; // Only allow number inputs.
  return newCode.length >= 1 && newCode.length <= 4; // Allow country codes with a maximum of 3 digits.
}
