import { DropdownOptions } from './Dropdown.types';
import DropdownContext from './DropdownContext';
import { useDropdown } from './useDropdown';

/** Dropdown component that provides context for dropdown functionality. */
function Dropdown({ children, modal = false, ...restOptions }: DropdownOptions) {
  const dropdown = useDropdown({ modal, ...restOptions });

  // Provide the dropdown context to its children.
  return <DropdownContext.Provider value={dropdown}>{children}</DropdownContext.Provider>;
}

export default Dropdown;
