import { Alignment, TextColor, TooltipType, TypographyMaxLines } from '../../../types';

export enum TypographySize {
  CAPTION = 'caption',
  PARAGRAPH = 'paragraph',
  H5 = 'h5',
  H4 = 'h4',
  H3 = 'h3',
  H2 = 'h2',
  H1 = 'h1',
}

export enum TypographyWeight {
  NORMAL = 'normal',
  MEDIUM = 'medium',
  SEMI_BOLD = 'semi-bold',
  BOLD = 'bold',
}

export interface TypographyProps {
  /** Content of the text. */
  children: React.ReactNode;
  /** Alignment of the text. */
  alignment?: Alignment;
  /** Additional class names to apply to the text. */
  className?: string;
  /** Color of the text. */
  color?: TextColor | string;
  /** Maximum number of lines to display before truncating. */
  maxLines?: TypographyMaxLines;
  /** Whether to prevent the text from wrapping. */
  noWrap?: boolean;
  /** Size of the text. */
  size?: TypographySize;
  /** Tooltip to display when hovering over the text. */
  tooltip?: TooltipType;
  /** Whether to underline the text. */
  underline?: boolean;
  /** Weight of the text. */
  weight?: TypographyWeight;
  /** Callback function to execute when the text is clicked. */
  onClick?: (e: React.MouseEvent<HTMLSpanElement>) => void;
}

export type TypographyComponent = React.ReactElement<TypographyProps>;
