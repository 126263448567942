import { JWT_NAMESPACE_APP_METADATA_CLAIM, JWT_NAMESPACE_ORG_CLAIM, JWT_NAMESPACE_ROLES_CLAIM } from '../constants';

export type Auth0AppMetaData = {
  onboarded?: {
    [key: string]: boolean;
  };
};

/** Represents an organization as stored in Auth0. */
export type Auth0Organization = {
  displayName: string; // Organization display name.
  name: string; // organization name written in an id form.
  id: string; // Organization ID.
};

/** Auth0 organization roles, ordered from top to bottom. */
export enum Auth0Roles {
  ADMIN = 'admin',
  MANAGER = 'manager',
  SALES_REP = 'sales-rep',
  SUPER_ADMIN = 'super-admin',
}

export enum Permissions {
  // Can do all actions.
  ADMIN_ACCESS = '*:dashboard',
  // Can disable/enable a user in the organization.
  DISABLE_USER = 'disable:user',
  // Can edit a user in the organization or themselves (ie. Name, Phone numbers, etc).
  EDIT_USER = 'edit:user',
  // Can promote/denote a user in the organization.
  EDIT_USER_ROLE = 'edit-role:user',
  // Can flag a call.
  FLAG_CALL = 'flag:call',
  // Can invite a user to the organization.
  INVITE_USER = 'invite:user',
  // Can edit organization details (ie. Name, Logo, etc)
  EDIT_ORG = 'manage:org',
  // User can onboard users without phone numbers including themselves.
  ONBOARD_USER = 'onboard:user',
  // Can unflag a call.
  UNFLAG_CALL = 'unflag:call',
  // Can view all users in the organization.
  VIEW_ORG_USERS = 'view-users:org',
  // Can view all candidates in the organization.
  VIEW_CANDIDATE = 'view:candidate',
}

export type Auth0JwtClaims = {
  [JWT_NAMESPACE_APP_METADATA_CLAIM]: Auth0AppMetaData;

  // Extracting the organization data claim
  [JWT_NAMESPACE_ORG_CLAIM]: Auth0Organization;

  // Extracting the role data claim
  [JWT_NAMESPACE_ROLES_CLAIM]: Auth0Roles[]; // Array of roles associated with the user.

  permissions: Permissions[]; // Array of permissions associated with the user.
};
