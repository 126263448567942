import { Icon } from '../Icons';

export enum BannerType {
  DEFAULT = 'DEFAULT',
  WARNING = 'WARNING',
}

export interface BannerProps {
  /** Banner text */
  text: React.ReactNode;
  /** Banner's call-to-action button info */
  endAction?: { title: string; onClick: (e: React.MouseEvent) => Promise<void> | void };
  /** Banner icon */
  icon?: Icon;
  /** Banner type */
  type?: BannerType;
}
