import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { AlertType, Icon, MultiSelect } from '../../../../components';
import { useAppDispatch, useToast } from '../../../../hooks';
import { updateProspectTags } from '../../../../redux/reducers';
import { useApplyTagsMutation, useCreateTagMutation, useGetTagsQuery } from '../../../../services';
import { DropdownPlacement, Tag, TagColor } from '../../../../types';
import ManageTagsTrigger from './ManageTagsTrigger';

const CREATE_TAG_OPTION = 'CREATE_TAG_OPTION';
const APPLY_TAGS_ERROR_MSG = 'Failed to edit tags';

interface ManageTagsDropdownProps {
  isOpen: boolean;
  prospectId: string;
  prospectTags: Tag[];
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const ManageTagsDropdown = ({ isOpen, prospectId, prospectTags, setIsOpen }: ManageTagsDropdownProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [tagsToApply, setTagsToApply] = useState<Tag[]>([]);
  const [tagsToRemove, setTagsToRemove] = useState<Tag[]>([]);

  const { data: tags = [], isLoading: isTagsLoading } = useGetTagsQuery();
  const [applyTags, { isLoading: isApplyingTags }] = useApplyTagsMutation();
  const [createTag, { isLoading: isCreatingTag }] = useCreateTagMutation();

  const dispatch = useAppDispatch();
  const { showToast } = useToast();

  const options = tags.map((tag) => ({ color: tag.color, label: tag.name, value: tag.id }));

  // The selected tags in the dropdown.
  const selectedTags = useMemo(
    () => prospectTags.filter((tag) => !tagsToRemove.some((removeTag) => removeTag.id === tag.id)).concat(tagsToApply),
    [prospectTags, tagsToApply, tagsToRemove]
  );
  // The selected tags in the dropdown parsed into SelectOption format.
  const selectedOptions = useMemo(
    () => selectedTags.map((tag) => ({ label: tag.name, value: tag.id })),
    [selectedTags]
  );

  const handleCreateTag = useCallback(async () => {
    if (!searchValue.length) return;

    try {
      const randomColor = Object.values(TagColor)[Math.floor(Math.random() * Object.values(TagColor).length)];
      await createTag({ name: searchValue, color: randomColor });
    } catch (error) {
      showToast({ message: `Failed to create tag "${searchValue}"`, type: AlertType.ERROR });
    }
  }, [searchValue, createTag, showToast]);

  const handleApplyTags = useCallback(async () => {
    try {
      if (tagsToApply.length === 0 && tagsToRemove.length === 0) {
        return; // Nothing has changed, so we return early
      }

      // The tags to apply are the selected tags that do not currently exist in the prospect's tags.
      const tagIdsToApply = tagsToApply.map((tag) => tag.id);

      // The tags to remove are the prospect's tags that are not selected anymore.
      const tagIdsToRemove = tagsToRemove.map((tag) => tag.id);

      await applyTags({ prospectId, tagsToApply: tagIdsToApply, tagsToRemove: tagIdsToRemove });
      // Update the prospect's tags in the state to immediately reflect the changes.
      dispatch(updateProspectTags({ personaId: prospectId, tags: selectedTags }));
      showToast({ message: 'Tags applied successfully', type: AlertType.SUCCESS });
    } catch (error) {
      console.error(`${APPLY_TAGS_ERROR_MSG}: `, error);
      showToast({ message: APPLY_TAGS_ERROR_MSG, type: AlertType.ERROR });
    }
  }, [prospectId, tagsToApply, tagsToRemove, selectedTags, applyTags, dispatch, showToast]);

  const onMultiSelectChange = useCallback(
    (newValues?: string[]) => {
      const newTags = tags.filter((tag) => newValues?.includes(tag.id));
      const tagsToApply = newTags.filter((newTag) => !prospectTags.find((oldTag) => oldTag.id === newTag.id));
      const tagsToRemove = prospectTags.filter((oldTag) => !newTags.find((newTag) => newTag.id === oldTag.id));
      setTagsToApply(tagsToApply);
      setTagsToRemove(tagsToRemove);
    },
    [tags, prospectTags]
  );

  return (
    <MultiSelect
      controlledOpenProps={{ isOpen, setIsOpen }}
      options={options}
      selected={selectedOptions}
      onChange={onMultiSelectChange}
      placement={DropdownPlacement.BOTTOM_START}
      disabled={isTagsLoading}
      searchProps={{
        searchValue,
        setSearchValue,
        placeholder: 'Type to filter or create',
        disabled: isApplyingTags || isCreatingTag,
        createOption: {
          icon: Icon.PLUS,
          label: `Create new tag "${searchValue}"`,
          value: CREATE_TAG_OPTION,
          onClick: handleCreateTag,
        },
      }}
      customButton={<ManageTagsTrigger active={isOpen} showBadge={!prospectTags.length} setIsOpen={setIsOpen} />}
      onClose={handleApplyTags}
    />
  );
};

export default ManageTagsDropdown;
