import clsx from 'clsx';
import { ComponentSize } from '../../../types';
import { CHECKBOX_SIZE } from './Checkbox.constants';
import { CheckboxProps } from './Checkbox.types';

const Checkbox = ({ checked, setChecked, size = ComponentSize.SMALL }: CheckboxProps) => {
  return (
    <input
      type="checkbox"
      className={clsx('checkbox rounded-sm !outline-none', typeof size !== 'number' && CHECKBOX_SIZE[size])}
      checked={checked}
      onChange={(e) => setChecked(e.target.checked)}
      style={typeof size === 'number' ? { width: size, height: size } : undefined}
    />
  );
};

export default Checkbox;
