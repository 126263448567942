import clsx from 'clsx';
import { ComponentSize, TextColor } from '../../../types';
import { Icons } from '../Icons';
import { Typography, TypographySize, TypographyWeight } from '../Typography';
import { TYPE_TO_ALERT_TYPE, TYPE_TO_ICON } from './Alert.constants';
import { AlertProps, AlertType } from './Alert.types';

const Alert = ({ message, title, type = AlertType.INFO }: AlertProps) => {
  // Info alerts have a light background, so we use dark text for better contrast.
  const textColor = type === AlertType.INFO ? TextColor.PRIMARY : TextColor.WHITE;
  return (
    <div className={clsx('alert w-80 border-none', TYPE_TO_ALERT_TYPE[type])}>
      <Icons icon={TYPE_TO_ICON[type]} color={textColor} size={ComponentSize.LARGE} />
      <div className="flex flex-col gap-1">
        <Typography color={textColor} size={TypographySize.H5} weight={TypographyWeight.SEMI_BOLD}>
          {title}
        </Typography>
        <Typography
          color={textColor}
          // Use smaller text size for message when there's a title to create visual hierarchy.
          size={title ? TypographySize.CAPTION : TypographySize.H5}
        >
          {message}
        </Typography>
      </div>
    </div>
  );
};

export default Alert;
