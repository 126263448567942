import { useEffect, useRef } from 'react';

/**
 * Custom hook to observe element resize with a debounced callback.
 *
 * @param ref - The ref object to the element to observe.
 * @param callback - The callback function to be invoked on resize.
 * @param delay - The debounce delay in milliseconds.
 */
const useObserveElementResize = (
  ref: React.RefObject<HTMLElement>,
  callback: (entries: ResizeObserverEntry[]) => void,
  delay = 100
) => {
  const timeoutId = useRef<number | undefined>(undefined);

  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    // Clears existing timeout.
    const clearTimeout = () => {
      if (timeoutId.current !== undefined) {
        window.clearTimeout(timeoutId.current);
      }
    };

    const debouncedCallback = (entries: ResizeObserverEntry[]) => {
      // Clear any existing timeout to reset the debounce period.
      clearTimeout();
      // Set a new timeout to invoke the callback after the specified delay.
      timeoutId.current = window.setTimeout(() => callback(entries), delay);
    };

    // Initialize the ResizeObserver with the debounced callback
    const resizeObserver = new ResizeObserver(debouncedCallback);
    resizeObserver.observe(element);

    // Cleanup function to disconnect the observer and clear any pending timeouts.
    return () => {
      resizeObserver.disconnect();
      clearTimeout();
    };
  }, [ref, callback, delay]);
};

export default useObserveElementResize;
