import { useCallback, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { ButtonColor, Dropdown, DropdownContent, DropdownItem, DropdownTrigger, Icon, TextButton } from '../../shared';

enum PlaybackSpeed {
  SLOWEST = '0.75',
  NORMAL = '1',
  FAST_1_25 = '1.25',
  FAST_1_5 = '1.5',
  FAST_1_75 = '1.75',
  FAST_2 = '2',
  FASTEST = '2.5',
}

// Formats the playback speed string into a label format.
const formatSpeed = (speed: string) => {
  return speed.replace(/^0\./, '.') + 'x';
};

interface SpeedButtonProps {
  waveSurfer: WaveSurfer;
}

const SpeedButton = ({ waveSurfer }: SpeedButtonProps) => {
  // State to control the dropdown open/close state.
  const [isSpeedDropdownOpen, setIsSpeedDropdownOpen] = useState(false);
  // State to keep track of the current playback speed.
  const [playbackSpeed, setPlaybackSpeed] = useState(PlaybackSpeed.NORMAL);

  // Handles changing the playback speed on the fly.
  const changePlaybackSpeed = useCallback(
    (newSpeed: PlaybackSpeed) => {
      waveSurfer.setPlaybackRate(Number(newSpeed));
      setPlaybackSpeed(newSpeed);
    },
    [waveSurfer, setPlaybackSpeed]
  );

  return (
    <Dropdown open={isSpeedDropdownOpen} onOpenChange={setIsSpeedDropdownOpen}>
      <DropdownTrigger>
        <TextButton
          text={formatSpeed(playbackSpeed)}
          startIcon={Icon.SPEED}
          onClick={() => setIsSpeedDropdownOpen((prev) => !prev)}
          active={isSpeedDropdownOpen}
          tooltip="Change playback speed"
          color={ButtonColor.SECONDARY}
        />
      </DropdownTrigger>
      <DropdownContent>
        {Object.values(PlaybackSpeed).map((value) => (
          <DropdownItem
            key={value}
            label={formatSpeed(value)}
            active={value === playbackSpeed}
            onClick={() => {
              changePlaybackSpeed(value);
              setIsSpeedDropdownOpen(false);
            }}
          />
        ))}
      </DropdownContent>
    </Dropdown>
  );
};

export default SpeedButton;
