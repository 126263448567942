import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useState } from 'react';
import { Typography, TypographySize } from '../../components';
import { APP_URL } from '../../constants';
import { AccessDeniedPageStep, RequestAccessRoles } from '../../types';
import AccessDeniedLayout from './AccessDeniedLayout';

import ProspectExampleStep from './ProspectExampleStep';
import SelectRoleStep from './SelectRoleStep';
import WorkEmailStep from './WorkEmailStep';

const AccessDeniedPage = () => {
  const [currentStep, setCurrentStep] = useState(AccessDeniedPageStep.SELECT_ROLE);
  const [selectedRole, setSelectedRole] = useState<RequestAccessRoles | undefined>(undefined);
  const [icp, setIcp] = useState('');

  const { logout } = useAuth0();

  const onKeepReadingClick = useCallback(() => {
    logout({ logoutParams: { returnTo: APP_URL } });
  }, [logout]);

  switch (currentStep) {
    case AccessDeniedPageStep.PROSPECT_EXAMPLE:
      return (
        <ProspectExampleStep selectedRole={selectedRole} icp={icp} setIcp={setIcp} setCurrentStep={setCurrentStep} />
      );
    case AccessDeniedPageStep.WORK_EMAIL:
      return <WorkEmailStep selectedRole={selectedRole} icp={icp} setCurrentStep={setCurrentStep} />;
    case AccessDeniedPageStep.COMPLETE:
      return (
        <AccessDeniedLayout actionButtonText="Keep reading" onActionButtonClick={onKeepReadingClick}>
          <Typography size={TypographySize.H5}>Our team will be in touch in 1-2 business days!</Typography>
        </AccessDeniedLayout>
      );
    default:
      return (
        <SelectRoleStep selectedRole={selectedRole} setSelectedRole={setSelectedRole} setCurrentStep={setCurrentStep} />
      );
  }
};

export default AccessDeniedPage;
