import { BUTTON_VARIANT_CONFIG } from './Button.constants';
import { ButtonColor, ButtonVariant } from './Button.types';

/**
 * Determines the appropriate button class based on the button's state and properties.
 */
export const getButtonClass = ({
  color,
  isActive,
  isDisabled,
  isHovered,
  variant,
}: {
  color: ButtonColor;
  isActive: boolean;
  isDisabled: boolean;
  isHovered: boolean;
  variant: ButtonVariant;
}) => {
  const buttonStates = BUTTON_VARIANT_CONFIG[variant][color];

  if (!buttonStates) {
    console.warn(`Invalid button combination: color=${color}, variant=${variant}`);
    const fallbackStates = BUTTON_VARIANT_CONFIG[variant][ButtonColor.PRIMARY];
    if (!fallbackStates) {
      console.error(`No fallback found for variant: ${variant}`);
      return { bg: '', text: '' };
    }
    return fallbackStates.default;
  }

  if (isDisabled) return buttonStates.disabled;
  if (isActive) return buttonStates.active;
  if (isHovered) return buttonStates.hover;
  return buttonStates.default;
};
