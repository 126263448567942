import { useCallback, useState } from 'react';
import { Select, Typography, TypographySize, TypographyWeight } from '../../components';
import { AccessDeniedPageStep, RequestAccessRoles } from '../../types';
import AccessDeniedLayout from './AccessDeniedLayout';

const ROLE_LABEL: Record<RequestAccessRoles, string> = {
  [RequestAccessRoles.SALES_REP]: 'Sales representative',
  [RequestAccessRoles.SALES_MANAGER]: 'Sales manager',
  [RequestAccessRoles.SALES_ENABLEMENT]: 'Sales enablement',
  [RequestAccessRoles.SALES_LEADERSHIP]: 'Sales leadership',
  [RequestAccessRoles.OTHER]: 'Something else',
};

const ROLE_OPTIONS = Object.values(RequestAccessRoles).map((role) => ({
  label: ROLE_LABEL[role],
  value: role,
}));

interface SelectRoleStepProps {
  setSelectedRole: (role: RequestAccessRoles) => void;
  setCurrentStep: (step: AccessDeniedPageStep) => void;
  selectedRole?: RequestAccessRoles;
}

const SelectRoleStep = ({ selectedRole, setSelectedRole, setCurrentStep }: SelectRoleStepProps) => {
  const [error, setError] = useState('');

  const onNextClick = useCallback(() => {
    if (!selectedRole) return setError('Please select a role');
    setCurrentStep(AccessDeniedPageStep.PROSPECT_EXAMPLE);
  }, [selectedRole, setCurrentStep, setError]);

  return (
    <AccessDeniedLayout
      title="Welcome to FullyRamped!"
      description="Looks like you’re new around here!"
      actionButtonText="Next"
      onActionButtonClick={onNextClick}
    >
      <div className="flex flex-col items-center gap-4">
        <Typography size={TypographySize.H3} weight={TypographyWeight.SEMI_BOLD}>
          Which best describes your role?
        </Typography>
        <Select
          placeholder="Select one"
          options={ROLE_OPTIONS}
          selected={selectedRole && { label: ROLE_LABEL[selectedRole], value: selectedRole }}
          onChange={(newRole) => {
            setSelectedRole(newRole as RequestAccessRoles);
            setError('');
          }}
          error={error}
        />
      </div>
    </AccessDeniedLayout>
  );
};

export default SelectRoleStep;
