import { ComponentSize } from '../../../types';
import { ButtonColor, ButtonGroup, Dialog, DialogType, TextButton } from '../../shared';

interface GiveMicAccessModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onGiveAccess: () => void;
}

const GiveMicAccessModal = ({ isOpen, onCancel, onGiveAccess }: GiveMicAccessModalProps) => (
  <Dialog
    isOpen={isOpen}
    onClose={onCancel}
    title="Required permissions"
    description="FullyRamped needs access to your mic to make calls."
    type={DialogType.CONFIRM}
  >
    <ButtonGroup size={ComponentSize.MEDIUM}>
      <TextButton text="Give access" onClick={onGiveAccess} color={ButtonColor.PRIMARY} />
      <TextButton text="Cancel" onClick={onCancel} />
    </ButtonGroup>
  </Dialog>
);

export default GiveMicAccessModal;
