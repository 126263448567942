import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';

/**
 * A typed version of the `useSelector` hook from Redux.
 * It's intended to replace the plain `useSelector` hook in your application to ensure that all state selections are type-safe.
 *
 * Usage:
 * Instead of using `useSelector` directly, use `useAppSelector` to leverage TypeScript's type safety.
 */
const useAppSelector = useSelector.withTypes<RootState>();

export default useAppSelector;
