import cautionTapeImg from '../../../assets/caution-tape.png';
import { BANNER_HEIGHT } from '../../../constants';
import { Alignment, ComponentSize, TextColor } from '../../../types';
import { ButtonColor, TextButton } from '../Button';
import { Icons } from '../Icons';
import { Typography, TypographySize } from '../Typography';
import { BannerProps, BannerType } from './Banner.types';

const Banner = ({ text, endAction, icon, type = BannerType.DEFAULT }: BannerProps) => {
  const renderCautionImg = () => <img src={cautionTapeImg} className="w-8" />;

  const renderContent = () => (
    <div className="flex items-center justify-center gap-2 py-2" style={{ minHeight: BANNER_HEIGHT }}>
      {icon && <Icons icon={icon} size={ComponentSize.MEDIUM} color={TextColor.WHITE} />}
      <Typography color={TextColor.WHITE} size={TypographySize.H5} alignment={Alignment.CENTER}>
        {text}
      </Typography>
      {endAction && <TextButton text={endAction.title} onClick={endAction.onClick} color={ButtonColor.SECONDARY} />}
    </div>
  );

  return (
    <div className="bg-neutral-content">
      {type === BannerType.WARNING && (
        <div className="flex justify-between">
          {renderCautionImg()}
          {renderContent()}
          {renderCautionImg()}
        </div>
      )}
      {type !== BannerType.WARNING && renderContent()}
    </div>
  );
};

export default Banner;
