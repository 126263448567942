import clsx from 'clsx';
import { useMemo } from 'react';
import { ComponentSize } from '../../../../types';
import { Icons } from '../../Icons';
import { Tooltip, TooltipContent, TooltipTrigger } from '../../Tooltip';
import { BUTTON_SIZE_CONFIG } from '../Button.constants';
import '../Button.css';
import { ButtonColor, ButtonVariant } from '../Button.types';
import { getButtonClass } from '../Button.utils';
import ButtonContent from '../ButtonContent';
import useButtonHandlers from '../useButtonHandlers';
import { IconButtonProps } from './IconButton.types';

const IconButton = ({
  active = false,
  icon,
  iconColor,
  color = ButtonColor.PRIMARY,
  disabled = false,
  loading,
  rounded = false,
  size = ComponentSize.SMALL,
  tooltip,
  variant = ButtonVariant.FILLED,
  onClick,
  onHover,
  onKeyDown,
}: IconButtonProps) => {
  const shouldDisable = disabled || loading || !onClick;

  const { hover, handleOnClick, handleOnMouseEnter, handleOnMouseLeave } = useButtonHandlers({
    onClick,
    onHover,
    onKeyDown,
  });

  const { btnSizeClass, iconSize } = BUTTON_SIZE_CONFIG[size];

  const { bg: btnBgClass, text: textColor } = useMemo(
    () => getButtonClass({ color, isActive: active, isDisabled: shouldDisable, isHovered: hover, variant }),
    [active, color, hover, shouldDisable, variant]
  );

  return (
    <Tooltip>
      <TooltipTrigger>
        <div
          className={clsx('btn relative', rounded ? 'btn-circle' : 'btn-square', btnSizeClass, btnBgClass)}
          onClick={handleOnClick}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        >
          <ButtonContent loading={loading} size={size}>
            <Icons icon={icon} color={iconColor ?? textColor} size={iconSize} />
          </ButtonContent>
        </div>
      </TooltipTrigger>
      <TooltipContent>{tooltip}</TooltipContent>
    </Tooltip>
  );
};

export default IconButton;
