import { ComponentSize } from '../../../../types';
import { ButtonColor, ButtonVariant, IconButton } from '../../Button';
import { Icon } from '../../Icons';
import { TextInput } from '../../Input';
import { SearchProps } from './BaseSelect.types';

const BaseSelectSearch = ({ searchValue, setSearchValue, disabled, placeholder }: SearchProps) => {
  const disableClear = disabled || !searchValue;
  return (
    <TextInput
      placeholder={placeholder}
      value={searchValue}
      onChange={!disabled ? (e) => setSearchValue(e.target.value) : undefined}
      endElement={
        <IconButton
          icon={Icon.CLOSE}
          color={ButtonColor.SECONDARY}
          variant={ButtonVariant.GHOST}
          size={ComponentSize.X_SMALL}
          onClick={() => setSearchValue('')}
          disabled={disableClear}
          tooltip={disableClear ? undefined : 'Clear search'}
        />
      }
    />
  );
};

export default BaseSelectSearch;
