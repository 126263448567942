import { LDClient, useLDClient } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { LD_FeatureFlags } from '../../types';
import FeatureFlagContext, { FeatureFlags } from './LaunchDarklyContext';

/**
 * FeatureFlagProvider is a React component that stores and provides feature flags to its children.
 */
export const FeatureFlagProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const ldClient = useLDClient() as LDClient;
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>({});

  useEffect(() => {
    if (!ldClient) return;

    // Set streaming to true to enable real-time updates
    ldClient.setStreaming(true);

    const fetchFeatureFlag = async (flagKey: string) => {
      try {
        const flagValue = await ldClient.variation(flagKey, false);
        setFeatureFlags((prevFlags) => ({
          ...prevFlags,
          [flagKey]: flagValue,
        }));
      } catch (error) {
        console.error(`Error fetching feature flag ${flagKey}:`, error);
      }
    };

    const initializeFeatureFlags = async (flagKeys: string[]) => {
      for (const flagKey of flagKeys) {
        await fetchFeatureFlag(flagKey);
      }
    };

    const flagKeys = Object.values(LD_FeatureFlags);
    initializeFeatureFlags(flagKeys);

    flagKeys.forEach((flagKey) => {
      ldClient.on(`change:${flagKey}`, (value) =>
        setFeatureFlags((prevFlags) => ({
          ...prevFlags,
          [flagKey]: value,
        }))
      );
    });

    return () => {
      flagKeys.forEach((flagKey) => {
        ldClient.off(`change:${flagKey}`, () => {});
      });
    };
  }, [ldClient]);

  return <FeatureFlagContext.Provider value={featureFlags}>{children}</FeatureFlagContext.Provider>;
};

FeatureFlagProvider.propTypes = {
  children: PropTypes.node,
};
