import { Icon, Icons, Typography, TypographySize, TypographyWeight } from '../../components';
import { Alignment, TextColor } from '../../types';

const MESSAGE_PAGE_ICON_SIZE = 64;

interface MessagePageProps {
  icon: Icon;
  title: string;
  message: string;
}

const MessagePage = ({ icon, title, message }: MessagePageProps) => {
  return (
    <div className="flex h-screen flex-col items-center justify-center gap-4 bg-base-0 p-8">
      <Icons icon={icon} size={MESSAGE_PAGE_ICON_SIZE} />
      <Typography weight={TypographyWeight.SEMI_BOLD} size={TypographySize.H1} alignment={Alignment.CENTER}>
        {title}
      </Typography>
      <Typography
        color={TextColor.SECONDARY}
        weight={TypographyWeight.SEMI_BOLD}
        size={TypographySize.H2}
        alignment={Alignment.CENTER}
      >
        {message}
      </Typography>
    </div>
  );
};

export default MessagePage;
