import { Icon } from './components';
import { CustomRouterProvider, ToastProvider } from './context';
import { LaunchDarklyProvider } from './context/LaunchDarklyProvider';
import { useAppSelector, useSyncAuthState, useWindowResize } from './hooks';
import { useRUM } from './hooks/useRUM';
import { MessagePage } from './pages';

// Breakpoint for medium screen size.
const MEDIUM_SCREEN_WIDTH = 900;

/**
 * AppContent component manages the main content of the application.
 * It checks internet connection, window size, and renders appropriate messages or content.
 * It also sets up providers for feature flags, toast notifications, and routing.
 */
const AppContent = () => {
  // Synchronize Auth0 authentication state with Redux.
  useSyncAuthState();
  useRUM();

  const windowSize = useWindowResize();
  const isDesktop = windowSize > MEDIUM_SCREEN_WIDTH;

  const hasInternetConnection = useAppSelector((state) => state.connection.hasInternetConnection);

  if (!hasInternetConnection) {
    return (
      <MessagePage
        icon={Icon.WIFI_OFF}
        title="No internet connection"
        message="Please check your connection and refresh this page"
      />
    );
  }

  // If the window is too small, show the "View on desktop" page.
  if (!isDesktop) {
    return (
      <MessagePage
        icon={Icon.LAPTOP}
        title="Please view on desktop"
        message="For best experience, please open FullyRamped on a larger device."
      />
    );
  }

  return (
    <LaunchDarklyProvider>
      <ToastProvider>
        <CustomRouterProvider />
      </ToastProvider>
    </LaunchDarklyProvider>
  );
};

export default AppContent;
