import { useEffect } from 'react';
import { BasePracticeProspect } from '../../../../../../types';
import { Select, SelectOption, Typography } from '../../../../../shared';
import { parseCandidateProspectOption } from '../../../../../../utils';

const NO_PROSPECT_FOR_CANDIDATES_ERROR_MSG = 'Please contact support to add Practice Prospects for candidates.';

interface ProspectSelectorProps {
  isProspectsLoading: boolean;
  error: boolean;
  prospects: BasePracticeProspect[];
  setErrorMsg: (errorMsg: string) => void;
  setSelectedProspect: (selectedProspect?: SelectOption) => void;
  resetError: () => void;
  selectedProspect?: SelectOption;
}

const ProspectSelector = ({
  isProspectsLoading,
  error,
  prospects,
  setErrorMsg,
  setSelectedProspect,
  resetError,
  selectedProspect,
}: ProspectSelectorProps) => {
  // If no prospects with type CANDIDATES, display error msg and disable adding candidate
  useEffect(() => {
    if (!prospects.length) setErrorMsg(NO_PROSPECT_FOR_CANDIDATES_ERROR_MSG);
  }, [prospects]);

  // Create prospect select options
  const prospectOptions = prospects.map(parseCandidateProspectOption);

  // Handle prospect change
  const handleProspectChange = (selectedValue?: string) => {
    if (!selectedValue) return;

    // Clear prospect error
    resetError();

    const newProspect = prospectOptions.find((option) => option.value === selectedValue);
    setSelectedProspect(newProspect);
  };
  return (
    <div className="flex w-full items-center gap-4">
      <Typography noWrap>Practice Prospect</Typography>
      <Select
        options={prospectOptions}
        selected={selectedProspect}
        onChange={handleProspectChange}
        loading={isProspectsLoading}
        placeholder="Select prospect*"
        error={!!error}
      />
    </div>
  );
};

export default ProspectSelector;
