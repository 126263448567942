import { useAppDispatch } from '../../../hooks';
import useCheckMicPermissions, { MicPermissionState } from '../../../hooks/useCheckMicPermissions';

import { useCallback, useEffect, useState } from 'react';
import { closeWebCall } from '../../../redux/reducers';
import GiveMicAccessModal from '../GiveMicAccessModal';
import MicAccessBlockedModal from '../MicAccessBlockedModal';

interface WebCallModalWrapperProps {
  children?: React.ReactNode;
}

/**
 * Wrapper for the WebCallModal that handles mic permission states.
 */
const WebCallModalWrapper = ({ children }: WebCallModalWrapperProps) => {
  // State to manage the visibility of the mic access modals.
  const [isGiveMicAccessModalOpen, setIsGiveMicAccessModalOpen] = useState(false);
  const [isMicAccessBlockedModalOpen, setIsMicAccessBlockedModalOpen] = useState(false);

  const dispatch = useAppDispatch();

  const { isLoading: isMicPermissionsLoading, micPermissionState, requestMicAccess } = useCheckMicPermissions();

  // Handles closing the web call modal.
  const onCloseWebCall = useCallback(() => {
    dispatch(closeWebCall());
  }, [dispatch]);

  // Handles prompting the user to give microphone access and closing the GiveMicAccessModal.
  const onGiveAccess = useCallback(() => {
    requestMicAccess();
    setIsGiveMicAccessModalOpen(false);
  }, [requestMicAccess, setIsGiveMicAccessModalOpen]);

  // Handles trying again to get microphone access.
  const onTryAgain = useCallback(() => {
    // Do nothing if permissions are still denied.
    // Keeps the MicAccessBlockedModal open.
    if (micPermissionState === MicPermissionState.DENIED) return;

    // Otherwise, close the MicAccessBlockedModal and request microphone access if needed.
    setIsMicAccessBlockedModalOpen(false);
    if (micPermissionState === MicPermissionState.PROMPT) requestMicAccess();
  }, [micPermissionState, requestMicAccess, setIsMicAccessBlockedModalOpen]);

  useEffect(() => {
    if (isMicPermissionsLoading) return;

    if (micPermissionState === MicPermissionState.PROMPT && !isMicAccessBlockedModalOpen) {
      // Open the GiveMicAccessModal if the user has not been prompted for microphone access yet.
      setIsGiveMicAccessModalOpen(true);
    } else if (micPermissionState === MicPermissionState.DENIED) {
      // Open the MicAccessBlockedModal if the user has denied microphone access.
      setIsMicAccessBlockedModalOpen(true);
    } else if (micPermissionState === null) {
      // Close the web call modal if an unknown error occurred while checking microphone permissions.
      onCloseWebCall();
    }
  }, [isMicAccessBlockedModalOpen, isMicPermissionsLoading, micPermissionState, onCloseWebCall]);

  return (
    <>
      {/* Render the WebCallModal only if microphone permissions are granted */}
      {micPermissionState === MicPermissionState.GRANTED && children}

      {/* Open the GiveMicAccessModal only if microphone permissions are not granted nor denied */}
      <GiveMicAccessModal isOpen={isGiveMicAccessModalOpen} onCancel={onCloseWebCall} onGiveAccess={onGiveAccess} />

      {/* Open the MicAccessBlockedModal only if microphone permissions are blocked */}
      <MicAccessBlockedModal isOpen={isMicAccessBlockedModalOpen} onCancel={onCloseWebCall} onTryAgain={onTryAgain} />
    </>
  );
};

export default WebCallModalWrapper;
