import { useCallback } from 'react';
import { useSubmitLinkMutation } from '../services';
import { isValidURL } from '../utils';
import useHandleApiResponse from './useHandleApiResponse';

const SUBMIT_LINK_ERROR_MSG = 'Failed to submit link';

const useSubmitLink = () => {
  const [submitLink, { isLoading }] = useSubmitLinkMutation();
  const handleApiResponse = useHandleApiResponse(true);

  // Handler function to manage link submission
  const handleLinkSubmit = useCallback(
    async (linkInput: string) => {
      if (!linkInput) {
        return { error: 'Please enter a URL' };
      }

      if (!isValidURL(linkInput)) {
        return { error: 'Please enter a valid URL' };
      }

      try {
        const response = await submitLink(linkInput);
        let result: { error: string } | undefined;
        handleApiResponse({
          response,
          errorMsg: SUBMIT_LINK_ERROR_MSG,
          onError: (e) => {
            result = { error: e.message };
          },
        });
        return result;
      } catch (error) {
        console.error(`${SUBMIT_LINK_ERROR_MSG}: `, error);
        return { error: SUBMIT_LINK_ERROR_MSG };
      }
    },
    [handleApiResponse, submitLink]
  );

  return { submitLink: handleLinkSubmit, isLoading };
};

export default useSubmitLink;
