import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { api } from '../../services';
import { authReducer, callHistoryReducer, connectionReducer, modalReducer, practiceReducer } from '../reducers';

const rootReducer = combineReducers({
  auth: authReducer,
  callHistory: callHistoryReducer,
  modal: modalReducer,
  connection: connectionReducer,
  practice: practiceReducer,
  [api.reducerPath]: api.reducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'], // Specify which reducers should be persisted
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(api.middleware),
});

export const persistor = persistStore(store);

// Enable automated refetching of queries on certain actions, like focus or network reconnection.
setupListeners(store.dispatch);
