import { FocusEvent, KeyboardEvent, forwardRef } from 'react';
import clsx from 'clsx';
import { ComponentSize } from '../../../../types';
import '../Input.css';
import { TextAreaProps } from './TextArea.types';
import InputError from '../InputError';
import { TEXT_AREA_SIZE } from './TextArea.constants';
import { INPUT_CLASSES, INPUT_DISABLED_CLASSES, INPUT_ERROR_CLASSES } from '../Input.constants';

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      className,
      disabled,
      error,
      size = ComponentSize.SMALL,
      placeholder,
      value = '',
      onBlur,
      onChange,
      onKeyDown,
      rows = 3,
    },
    ref
  ) => {
    const handleOnBlur = (event: FocusEvent<HTMLTextAreaElement>) => {
      if (onBlur) {
        event.stopPropagation();
        onBlur(event);
      }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
      if (onKeyDown) {
        event.stopPropagation();
        onKeyDown(event);
      }
    };

    return (
      <div className={clsx('w-full', className)}>
        <label
          className={clsx(
            'textarea textarea-bordered',
            INPUT_CLASSES,
            TEXT_AREA_SIZE[size],
            disabled && 'textarea-disabled' && INPUT_DISABLED_CLASSES,
            error && INPUT_ERROR_CLASSES
          )}
        >
          <textarea
            ref={ref}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            onChange={onChange}
            className="w-full resize-none whitespace-break-spaces outline-none"
            onKeyDown={handleKeyDown}
            onBlur={handleOnBlur}
            readOnly={!onChange || disabled}
            rows={rows}
          />
        </label>
        {typeof error === 'string' && <InputError message={error} />}
      </div>
    );
  }
);

TextArea.displayName = 'TextArea';
export default TextArea;
