import { forwardRef, useMemo } from 'react';
import { BaseSelect } from '../BaseSelect';
import { MultiSelectProps } from './MultiSelect.types';

/**
 * MultiSelect is a dropdown component that allows users to select multiple options.
 * It extends the BaseSelect component and adds specific logic for multi-select behavior.
 */
const MultiSelect = forwardRef<HTMLDivElement, MultiSelectProps>(
  ({ clearable, loading, options, searchProps, selected, onChange, ...props }, ref) => {
    const filteredOptions = searchProps
      ? options.filter((option) => option.label.toLowerCase().includes(searchProps.searchValue.toLowerCase()))
      : options;

    const parsedOptions = useMemo(() => {
      // Move selected options to the top.
      const selectedValues = (selected || []).map((s) => s.value);

      const selectedOptions = filteredOptions
        .filter((option) => selectedValues.includes(option.value))
        .map((option) => ({ ...option, active: true }));

      const unselectedOptions = filteredOptions
        .filter((option) => !selectedValues.includes(option.value))
        .map((option, index) => ({ ...option, divider: index === 0 }));

      return [...selectedOptions, ...unselectedOptions];
    }, [filteredOptions, selected]);

    // Handles adding or removing a value from the selected list.
    const handleOptionSelection = (value: string) => {
      const newSelected = selected ? [...selected].map((option) => option.value) : [];
      if (newSelected.includes(value)) {
        // If the new value is already selected, remove it.
        onChange(newSelected.filter((v) => v !== value));
      } else {
        // Otherwise, add it.
        onChange([...newSelected, value]);
      }
    };

    return (
      <BaseSelect
        {...props}
        multiSelect
        ref={ref}
        clearable={clearable && !!selected && selected.length > 0}
        displayValue={loading ? 'Loading...' : selected?.[0]?.label || ''}
        displayValueColor={selected?.[0]?.color}
        selected={selected}
        options={parsedOptions}
        handleClear={() => onChange(undefined)}
        handleOptionSelection={handleOptionSelection}
        loading={loading}
        searchProps={searchProps}
      />
    );
  }
);

MultiSelect.displayName = 'MultiSelect';

export default MultiSelect;
