import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FilterKeys } from '../types';

/**
 * Custom hook that returns a function to update filter parameters in the URL.
 */
const useUpdateFilterParams = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Memoize the URLSearchParams object to avoid unnecessary recalculations.
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);

  // Updates the filter parameters in the URL, given the filter key and its new value.
  // If the new value is undefined, the filter will be removed.
  const updateFilterParams = useCallback(
    (key: FilterKeys, value?: string | string[], customPathname?: string) => {
      if (!Array.isArray(value) && value) {
        params.set(key, value);
      } else if (Array.isArray(value) && value?.length) {
        params.set(key, value.join(','));
      } else {
        params.delete(key);
      }

      navigate({ pathname: customPathname ?? location.pathname, search: params.toString() }, { replace: true });
    },
    [location.pathname, navigate, params]
  );

  return updateFilterParams;
};

export default useUpdateFilterParams;
