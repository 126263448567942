import { Avatar, Typography, TypographySize, TypographyWeight } from '../../../../components';
import { ComponentSize, TextColor } from '../../../../types';
import { formatDuration } from '../../../../utils';

type TranscriptRowProps = {
  speakerName: string;
  startTime: number;
  transcript: string;
  seekToAudio: () => void;
  speakerPicture?: string;
};

const TranscriptRow = ({ speakerName, startTime, transcript, seekToAudio, speakerPicture }: TranscriptRowProps) => {
  return (
    <div className="flex items-start gap-4">
      <Typography color={TextColor.SECONDARY} className="mt-2">
        {formatDuration(startTime)}
      </Typography>
      <div className="flex w-full cursor-pointer gap-4 rounded-lg p-2 hover:bg-success" onClick={seekToAudio}>
        <div className="h-4 flex-shrink-0">
          <Avatar imgSrc={speakerPicture} label={speakerName} size={ComponentSize.X_SMALL} />
        </div>
        <Typography size={TypographySize.H5} weight={TypographyWeight.SEMI_BOLD}>
          {speakerName}: <span className="font-normal">{transcript}</span>
        </Typography>
      </div>
    </div>
  );
};

export default TranscriptRow;
