import React from 'react';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import Auth0ClientInitializer from './Auth0ClientInitializer';

interface CustomAuth0ProviderProps {
  children: React.ReactNode;
}

/**
 * Handles the redirection after user authentication.
 * Updates the browser's URL without reloading the page.
 * @param appState - State passed from Auth0 after authentication.
 */
const onRedirectCallback = (appState?: AppState) => {
  const url = appState?.returnTo || window.location.pathname;
  window.history.replaceState({}, document.title, url);
};

// Configuration object for Auth0Provider, pulling settings from environment variables.
const providerConfig = {
  authorizationParams: {
    redirect_uri: window.location.origin, // URL to return to after login.
    audience: process.env.REACT_APP_AUTH0_DASHBOARD_API_AUDIENCE, // Audience for Auth0 API interactions.
  },
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
  domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
  useRefreshTokens: true, // Enable the use of refresh tokens
  cacheLocation: 'localstorage' as const, // Ensure tokens are stored in local storage to persist across page reloads
  onRedirectCallback,
};

/**
 * Custom Auth0 Provider component to wrap around the application.
 * This sets up the Auth0 context for authenticating users.
 */
const CustomAuth0Provider: React.FC<CustomAuth0ProviderProps> = ({ children }: CustomAuth0ProviderProps) => {
  // Return null if essential environment variables are missing, preventing runtime errors.
  if (!providerConfig.domain || !providerConfig.clientId) {
    console.error('Auth0 environment variables are not properly set.');
    return null;
  }

  return (
    <Auth0Provider {...providerConfig}>
      <Auth0ClientInitializer />
      {children}
    </Auth0Provider>
  );
};

export default CustomAuth0Provider;
