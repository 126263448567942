import clsx from 'clsx';
import React, { useCallback, useRef, useState } from 'react';
import { Badge, BadgeType, Typography, TypographySize } from '../..';
import { useDetectTextOverflow, useObserveElementResize } from '../../../hooks';
import { DropdownPlacement, Tag, TextColor } from '../../../types';
import ManageTagsDropdown from './ManageTagsDropdown';
import MoreLabel from './MoreLabel';
import { MANAGE_TAGS_BUTTON_WIDTH, MAX_NOTES_ROWS, MAX_TAG_WIDTH, TAG_GAP } from './TagsAndNotesCell.constants';
import { getMaxTagsHeight } from './TagsAndNotesCell.utils';

interface TagsAndNotesCellProps {
  prospectId: string;
  prospectTags: Tag[];
  /** Whether to enable the manage tags dropdown. */
  enableManageTags?: boolean;
  notes?: string;
}

const TagsAndNotesCell: React.FC<TagsAndNotesCellProps> = ({ prospectId, prospectTags, enableManageTags, notes }) => {
  // State to track visible tags.
  const [visibleTags, setVisibleTags] = useState<Tag[]>([]);
  // State to track tags hidden by overflow.
  const [hiddenTags, setHiddenTags] = useState<Tag[]>([]);
  // Whether the manage tags dropdown is open.
  const [isManageTagsDropdownOpen, setIsManageTagsDropdownOpen] = useState(false);
  // Whether the manage tags button is overflowing.
  const [isManageTagsButtonOverflowing, setIsManageTagsButtonOverflowing] = useState(false);

  // Refs
  const badgeContainerRef = useRef<HTMLDivElement>(null);
  const notesRef = useRef<HTMLDivElement>(null);

  // Custom hooks
  const isNotesOverflowing = useDetectTextOverflow(notesRef, MAX_NOTES_ROWS);

  const maxTagsHeight = getMaxTagsHeight();

  const createTempBadgeContainerElement = useCallback((badgeContainerElement: HTMLDivElement) => {
    const tempElement = badgeContainerElement.cloneNode(true) as HTMLDivElement;
    tempElement.style.position = 'absolute';
    tempElement.style.visibility = 'hidden';
    tempElement.style.width = `${badgeContainerElement.offsetWidth}px`;

    // Append the cloned element to the body.
    document.body.appendChild(tempElement);

    return tempElement;
  }, []);

  const checkTagsOverflow = useCallback(() => {
    const badgeContainerElement = badgeContainerRef.current;
    if (!badgeContainerElement || !prospectTags.length) return;

    // Clone the badge container to measure overflow.
    const tempElement = createTempBadgeContainerElement(badgeContainerElement);

    let visibleCount = 0;

    // Determine how many tags fit within the max height.
    for (let i = 0; i < tempElement.children.length; i++) {
      const child = tempElement.children[i] as HTMLElement;
      const childTop = child.offsetTop;

      if (childTop + child.offsetHeight <= maxTagsHeight) {
        visibleCount++;
      } else {
        break;
      }
    }

    setVisibleTags(prospectTags.slice(0, visibleCount));
    setHiddenTags(prospectTags.slice(visibleCount));

    // Cleanup: remove the temporary element from the body.
    document.body.removeChild(tempElement);
  }, [prospectTags.length, createTempBadgeContainerElement]);

  const checkManageTagsButtonOverflow = useCallback(() => {
    const badgeContainerElement = badgeContainerRef.current;
    if (!badgeContainerElement || !!hiddenTags.length) {
      setIsManageTagsButtonOverflowing(false);
      return;
    }

    // Clone the badge container to measure overflow.
    const tempElement = createTempBadgeContainerElement(badgeContainerElement);

    // Get the last tag's right position.
    const lastTag = tempElement.children[tempElement.children.length - 1] as HTMLElement;
    const lastTagRight = lastTag.offsetLeft + lastTag.offsetWidth;

    // Determine if there is enough space to render the manage tags button.
    // If not, set the overflow state to true.
    setIsManageTagsButtonOverflowing(lastTagRight + TAG_GAP + MANAGE_TAGS_BUTTON_WIDTH > tempElement.offsetWidth);

    // Cleanup: remove the temporary element from the body.
    document.body.removeChild(tempElement);
  }, [hiddenTags.length, createTempBadgeContainerElement]);

  const checkOverflow = useCallback(() => {
    checkTagsOverflow();
    checkManageTagsButtonOverflow();
  }, [checkTagsOverflow, checkManageTagsButtonOverflow]);

  // Observe changes in the badge container size.
  useObserveElementResize(badgeContainerRef, checkOverflow);

  const renderManageTagsButton = () =>
    enableManageTags && (
      <ManageTagsDropdown
        prospectTags={prospectTags}
        isOpen={isManageTagsDropdownOpen}
        prospectId={prospectId}
        setIsOpen={setIsManageTagsDropdownOpen}
      />
    );

  return (
    <div className="flex flex-col gap-1">
      {/* Tag container */}
      <div
        className="flex flex-wrap gap-1 overflow-hidden"
        ref={badgeContainerRef}
        style={{ maxHeight: maxTagsHeight }}
      >
        {!enableManageTags && !prospectTags.length && (
          <Typography size={TypographySize.CAPTION} color={TextColor.SECONDARY}>
            No tags or notes
          </Typography>
        )}
        {!prospectTags.length && renderManageTagsButton()}
        {/* Render selected tags. */}
        {prospectTags.map((tag, index) => {
          // If hidden tags exist, or the "Manage tags" button is overflowing, the "+X more" label should be shown.
          // We then hide the last visible tag and display the "+X more" label instead.
          const isLastVisibleTag = index === visibleTags.length - 1;
          const showMoreLabel = (hiddenTags.length > 0 || isManageTagsButtonOverflowing) && isLastVisibleTag;

          // If the prev tag was supposed to be the last visible tag, we hide the current tag.
          // This happens if there was space to show the next tag after the "+X more" label is rendered.
          const isPrevTagMoreLabel = index > visibleTags.length - 1;
          return (
            <div
              key={tag.id}
              className={clsx('relative inline-flex', isPrevTagMoreLabel && 'opacity-0')}
              style={{ gap: TAG_GAP }}
            >
              <div className={clsx(showMoreLabel && 'opacity-0')}>
                <Badge
                  label={tag.name}
                  dotColor={tag.color}
                  type={BadgeType.GHOST}
                  showDot
                  maxWidth={MAX_TAG_WIDTH}
                  onClick={enableManageTags ? () => setIsManageTagsDropdownOpen(true) : undefined}
                />
              </div>
              <div className={clsx(showMoreLabel && 'absolute left-0 top-0 flex')} style={{ gap: TAG_GAP }}>
                {showMoreLabel && <MoreLabel hiddenTags={[tag, ...hiddenTags]} />}
                {/* Render the "manage tags" button after the last visible tag. */}
                {isLastVisibleTag && renderManageTagsButton()}
              </div>
            </div>
          );
        })}
      </div>
      {/* Notes with overflow detection */}
      <Typography
        size={TypographySize.CAPTION}
        color={TextColor.SECONDARY}
        ref={notesRef}
        maxLines={MAX_NOTES_ROWS}
        tooltip={
          isNotesOverflowing
            ? {
                content: notes,
                placement: DropdownPlacement.BOTTOM,
              }
            : undefined
        } // Show full text in tooltip on hover
      >
        {notes}
      </Typography>
    </div>
  );
};

export default TagsAndNotesCell;
