import { TextInput, TextInputProps, Typography } from '../../shared';

interface SidebarSearchFilterProps extends TextInputProps {
  onSearchChange: (newValue: string) => void;
}

const SidebarSearchFilter = ({ onSearchChange, ...inputProps }: SidebarSearchFilterProps) => {
  return (
    <div className="flex flex-col gap-2">
      <Typography>Search</Typography>
      <TextInput {...inputProps} onChange={(e) => onSearchChange(e.target.value)} />
    </div>
  );
};

export default SidebarSearchFilter;
