import { User as Auth0User } from '@auth0/auth0-react';
import { Auth0Organization, Permissions } from './auth0.types';
import { AppOrganization } from './organization.types';

export enum Roles {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  SALES_REP = 'SALES_REP',
}

/**
 * Represents a user within the application,
 * with properties adapted to the application's naming conventions and usage.
 */
export type AppUser = {
  id: string;
  disabled?: boolean;
  createdAt?: string;
  email?: string;
  name?: string;
  permissions?: Permissions[];
  picture?: string;
  role?: Roles;
  intercomHmac?: string;
};

export type DBUser = {
  id: string;
  email: string;
  name: string;
  picture: string;
  createdAt: string;
  updatedAt: string;
};

export type OrgMember = DBUser & {
  role: Roles;
  disabled: boolean;
  onboarded: boolean;
};

export type UsersParams = {
  orgId?: string;
};

export type ImpersonateUserParams = {
  orgId: string;
  impersonateUserId: string;
};

export type ImpersonatedUser = AppUser & {
  organization: AppOrganization;
};

export type ImpersonatedUserResponse = {
  data: Auth0User & {
    organization: Auth0Organization;
  };
};
