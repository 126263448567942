import { useEffect, useRef, useState } from 'react';
import { ButtonColor, ButtonVariant, Icon, Spinner, TextButton, TextInput } from '../../../../shared';
import ScorecardSection from './ScorecardSection';
import { ComponentSize, ScorecardSectionType } from '../../../../../types';
import { v4 as uuidv4 } from 'uuid';

import { DragDropContext, Draggable, Droppable, DropResult } from '@hello-pangea/dnd';
import { INITIAL_SECTIONS } from '../../../../../constants';
import { useGetScorecardTemplateQuery } from '../../../../../services/api.services';
import ScorecardFormActionButtons from './ScorecardFormActionButtons';

interface ScorecardFormProps {
  onClose: () => void;
  scorecardId?: string;
}

const ScorecardForm = ({ scorecardId, onClose }: ScorecardFormProps) => {
  // API Hooks
  const { data: scorecard, isLoading: isScorecardLoading } = useGetScorecardTemplateQuery(scorecardId || '');

  // State
  const [sections, setSections] = useState<ScorecardSectionType[]>(INITIAL_SECTIONS);
  const [scorecardName, setScorecardName] = useState('');
  const [error, setError] = useState('');

  // Ref
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  // Update state when scorecard data is fetched
  useEffect(() => {
    if (scorecard) {
      setScorecardName(scorecard.name);
      setSections(scorecard.sections || INITIAL_SECTIONS);
    }
  }, [scorecard]);

  // Add a new section to scorecard state
  const handleAddSection = () => {
    // ID is a unique identifier for the section to handle reordering in frontend before saving
    setSections([...sections, { title: '', questions: [], id: uuidv4() }]);

    // Scroll to the added section
    setTimeout(() => {
      scrollContainerRef.current?.scrollTo({
        top: scrollContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }, 0);
  };

  // Update a section in scorecard state
  const handleUpdateSectionState = (index: number, updatedSection: ScorecardSectionType) => {
    const newSections = [...sections];
    newSections[index] = updatedSection;
    setSections(newSections);
  };

  // Remove a section from scorecard state
  const handleRemoveSection = (index: number) => setSections(sections.filter((_, i) => i !== index));

  // Handle scorecard name change
  const handleScorecardNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setScorecardName(e.target.value);
    setError('');
  };

  // Handle section drag end event
  const handleSectionDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const reorderedSections = Array.from(sections);
    const [movedSection] = reorderedSections.splice(result.source.index, 1);
    reorderedSections.splice(result.destination.index, 0, movedSection);

    setSections(reorderedSections);
  };

  // If scorecard is loading and there is a scorecard ID, show the loading spinner
  if (isScorecardLoading && scorecardId) {
    return (
      <div className="flex h-full items-center justify-center">
        <Spinner size={ComponentSize.MEDIUM} />
      </div>
    );
  }

  return (
    <DragDropContext onDragEnd={handleSectionDragEnd}>
      <div className="flex h-full flex-col justify-between">
        <div ref={scrollContainerRef} className="display-scrollbar-lg flex h-full flex-col gap-4 overflow-auto pb-4">
          <div className="flex flex-col gap-2">
            <div className="flex items-start gap-4">
              <TextButton text="Back" startIcon={Icon.CHEVRON_LEFT} onClick={onClose} color={ButtonColor.SECONDARY} />
              <TextInput
                placeholder="Scorecard name*"
                onChange={handleScorecardNameChange}
                value={scorecardName}
                error={error}
              />
            </div>
            <TextButton
              text="Add section"
              startIcon={Icon.PLUS}
              onClick={handleAddSection}
              variant={ButtonVariant.OUTLINE}
            />
          </div>
          <Droppable
            droppableId="sections"
            renderClone={(provided, _snapshot, rubric) => (
              <ScorecardSection
                section={sections[rubric.source.index]}
                dragHandleProps={provided.dragHandleProps || undefined}
                draggableProps={provided.draggableProps || undefined}
                innerRef={provided.innerRef}
              />
            )}
          >
            {(droppableProvided) => (
              <div
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
                className="flex flex-col gap-4"
              >
                {sections.map((section, index) => (
                  <Draggable draggableId={section.id} index={index} key={section.id}>
                    {(draggableProvided) => (
                      <ScorecardSection
                        section={section}
                        onUpdate={(updatedSection) => handleUpdateSectionState(index, updatedSection)}
                        onRemove={() => handleRemoveSection(index)}
                        dragHandleProps={draggableProvided.dragHandleProps || undefined}
                        draggableProps={draggableProvided.draggableProps || undefined}
                        innerRef={draggableProvided.innerRef}
                      />
                    )}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
        <ScorecardFormActionButtons
          scorecard={scorecard}
          scorecardName={scorecardName}
          sections={sections}
          handleCloseScorecardForm={onClose}
        />
      </div>
    </DragDropContext>
  );
};

export default ScorecardForm;
