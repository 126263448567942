import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { ComponentSize, TextColor } from '../../../types';
import { ButtonColor, TextButton } from '../Button';
import { INPUT_SIZE, TextInput, TextInputType } from '../Input';
import { Typography, TypographySize } from '../Typography';
import {
  COMPONENT_SIZE_TO_TYPOGRAPHY_SIZE,
  DEFAULT_FILE_INPUT_PLACEHOLDER,
  DEFAULT_MAX_FILE_SIZE,
} from './FileInput.constants';
import { FileInputProps } from './FileInput.types';

const FileInput = ({
  disabled,
  error,
  size = ComponentSize.SMALL,
  buttonText = 'Browse',
  selectedFile,
  setSelectedFile,
  maxFileSize = DEFAULT_MAX_FILE_SIZE,
  setError,
}: FileInputProps) => {
  // State to track when an item is being dragged over the component
  const [isDragging, setIsDragging] = useState(false);

  // Ref to the native file input element
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleGlobalDrop = (event: DragEvent) => {
      event.preventDefault();
      event.stopPropagation();

      if (event.dataTransfer?.files && event.dataTransfer.files.length > 0) {
        handleFileSelection(event.dataTransfer.files[0]);
      }
      setIsDragging(false);
    };

    const handleGlobalDragOver = (event: DragEvent) => {
      event.preventDefault();
      event.stopPropagation();
      setIsDragging(true);
    };

    const handleGlobalDragLeave = (event: DragEvent) => {
      event.preventDefault();
      event.stopPropagation();
      setIsDragging(false);
    };

    document.addEventListener('drop', handleGlobalDrop);
    document.addEventListener('dragover', handleGlobalDragOver);
    document.addEventListener('dragleave', handleGlobalDragLeave);

    return () => {
      document.removeEventListener('drop', handleGlobalDrop);
      document.removeEventListener('dragover', handleGlobalDragOver);
      document.removeEventListener('dragleave', handleGlobalDragLeave);
    };
  }, []);

  // Handler function to manage file selection
  const handleFileSelection = (file: File) => {
    if (file.size > maxFileSize) {
      setError('File size exceeds the maximum limit of 50 MB');
      setSelectedFile(undefined);
    } else {
      setError('');
      setSelectedFile(file);
    }
  };

  const resetState = () => {
    // clear selected file and error
    setSelectedFile(undefined);
    setError('');
  };

  // Handler function to manage file change
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      handleFileSelection(event.target.files[0]);
    } else {
      resetState();
    }
  };

  // Handler function to manage file click
  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const inputTextColor = selectedFile ? TextColor.PRIMARY : TextColor.TERTIARY;

  return (
    <div className={clsx('flex w-full flex-col', isDragging && 'opacity-50')}>
      <div className="flex w-full">
        {/* Custom button to trigger file input */}
        <TextButton
          size={size}
          disabled={disabled}
          text={buttonText}
          onClick={handleClick}
          color={ButtonColor.INVERTED}
          className="rounded-r-none"
        />
        <div
          className={clsx(
            'p-2-0 input input-bordered flex w-full cursor-pointer items-center rounded-l-none border-base-300 bg-base-0 !outline-none',
            INPUT_SIZE[size],
            disabled && 'input-disabled cursor-auto border-transparent bg-base-100 text-neutral',
            error && '!border-error-content bg-error'
          )}
          onClick={handleClick}
        >
          {/* Display the selected file name or "No file chosen" */}
          <Typography color={inputTextColor} size={COMPONENT_SIZE_TO_TYPOGRAPHY_SIZE[size]}>
            {selectedFile?.name ?? DEFAULT_FILE_INPUT_PLACEHOLDER}
          </Typography>

          {/* Hidden native file input, accepts any file type */}
          <TextInput type={TextInputType.FILE} ref={inputRef} onChange={handleFileChange} className="hidden" />
        </div>
      </div>

      <div className="h-4">
        <Typography size={TypographySize.CAPTION} color={TextColor.DESTRUCTIVE}>
          {error}
        </Typography>
      </div>
    </div>
  );
};

export default FileInput;
