import Intercom from '@intercom/messenger-js-sdk';
import { useEffect } from 'react';
import { useGenerateIntercomHMACMutation } from '../services';
import useAppSelector from './useAppSelector';

const APP_ID = process.env.REACT_APP_INTERCOM_APP_ID as string;
const ERROR_MSG = 'Failed to generate Intercom HMAC: ';

const useIntercom = () => {
  const { user, organization } = useAppSelector((state) => state.auth);
  const [generateIntercomHMAC] = useGenerateIntercomHMACMutation();

  useEffect(() => {
    const initIntercom = async () => {
      if (!user || !organization) return;

      let intercomHmac = user.intercomHmac;

      if (!intercomHmac) {
        try {
          const { data, error } = await generateIntercomHMAC(user.id);
          if (error) throw error;
          intercomHmac = data.intercom_hmac;
        } catch (error) {
          console.error(ERROR_MSG, error);
          return;
        }
      }

      Intercom({
        app_id: APP_ID,
        email: user.email,
        user_id: user.id,
        name: user.name,
        user_hash: intercomHmac,
        company: {
          id: organization.id,
          name: organization.name,
        },
        hide_default_launcher: true,
        Role: user.role,
      });
    };
    initIntercom();
  }, [organization, user, generateIntercomHMAC]);
};
export default useIntercom;
