import { useCallback, useState } from 'react';
import { MAX_LINES_TO_CLASSNAME } from '../constants';
import { TypographyMaxLines } from '../types';
import useObserveElementResize from './useObserveElementResize';

const useDetectTextOverflow = (
  // Reference to the element for which we're detecting overflow.
  textRef: React.RefObject<HTMLDivElement>,
  // The maximum number of lines before text is truncated.
  maxLines: TypographyMaxLines
) => {
  const [elementWidth, setElementWidth] = useState<number | null>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const checkOverflow = useCallback(() => {
    const textElement = textRef.current;
    if (!textElement) return;

    // Clone the text element to measure overflow.
    const tempElement = textElement.cloneNode(true) as HTMLDivElement;
    tempElement.style.position = 'absolute';
    tempElement.style.visibility = 'hidden';
    tempElement.style.width = `${elementWidth}px`;

    // Remove overflow classes from the inner content to accurately measure its full height.
    const innerContent = tempElement.querySelector('*');
    if (innerContent) {
      innerContent.classList.remove(...MAX_LINES_TO_CLASSNAME[maxLines].split(' '));
    }

    // Append the cloned element to the body to measure its height.
    document.body.appendChild(tempElement);

    // Check if the cloned element's height exceeds the original element's height.
    const isTruncated = tempElement.offsetHeight > textElement.offsetHeight;
    setIsOverflowing(isTruncated);

    // Cleanup: remove the temporary element from the body.
    document.body.removeChild(tempElement);
  }, [elementWidth, textRef, maxLines]);

  // Observe element resize to check for overflow when the window is resized.
  useObserveElementResize(textRef, (entries) => {
    for (const entry of entries) {
      const newWidth = entry.contentRect.width;
      setElementWidth(newWidth);
      checkOverflow();
    }
  });

  return isOverflowing;
};

export default useDetectTextOverflow;
