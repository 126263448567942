import { ComponentSize } from '../../../types';

export enum ButtonVariant {
  FILLED = 'filled',
  OUTLINE = 'outline',
  GHOST = 'ghost',
}

export enum ButtonColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DESTRUCTIVE = 'destructive',
  INVERTED = 'inverted',
}

export interface BaseButtonProps {
  // Applies active styles.
  active?: boolean;
  // Button color.
  color?: ButtonColor;
  // Disables the button.
  disabled?: boolean;
  // Applies loading styles.
  loading?: boolean;
  // Button size.
  size?: ComponentSize;
  // Tooltip text.
  tooltip?: string;
  // Button variant.
  variant?: ButtonVariant;
  // Button click handler.
  onClick?: (e: React.MouseEvent) => Promise<void> | void;
  // Button hover callback.
  onHover?: (hover: boolean) => void;
  // Button key down callback.
  onKeyDown?: (e: KeyboardEvent) => void;
}

export type ButtonComponent = React.ReactElement<BaseButtonProps>;
