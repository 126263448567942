import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { closeZeusModal } from '../../../redux/reducers';
import { useGetOrganizationsQuery, useGetUsersQuery } from '../../../services';
import { Roles } from '../../../types';
import { Dialog, Icon, Icons, Select, Typography, TypographySize } from '../../shared';
import ZeusModalActions from './ZeusModalActions';

const ZEUS_MODAL_WIDTH = 460;
const ZEUS_SELECT_WIDTH = 300;

const ZeusModal = () => {
  // State
  const [selectedOrgId, setSelectedOrgId] = useState<string | undefined>(undefined);
  const [selectedUserId, setSelectedUserId] = useState<string | undefined>(undefined);

  // Redux
  const dispatch = useAppDispatch();
  const impersonatedUser = useAppSelector((state) => state.auth.impersonatedUser);
  const isOpen = useAppSelector((state) => state.modal.isZeusModalOpen);

  // RTK Query
  const { data: organizations = [], isLoading: isLoadingOrgs } = useGetOrganizationsQuery();
  const { data: users = [], isLoading: isLoadingUsers } = useGetUsersQuery(
    { orgId: selectedOrgId },
    { skip: !selectedOrgId }
  );

  // Map organizations and users to Select options.
  const orgOptions = useMemo(() => organizations.map((org) => ({ label: org.name, value: org.id })), [organizations]);

  const userOptions = useMemo(() => {
    // The useGetUsersQuery returns the current organization's users by default if no orgId is provided,
    // so we check if an orgId is selected and show the list of users if so.
    // Skip only works before any data is fetched.
    if (!selectedOrgId) return [];

    // Super admins cannot impersonate other super admins.
    const usersWithoutSuperAdmins = users.filter((user) => user.role !== Roles.SUPER_ADMIN);
    return usersWithoutSuperAdmins.map((user) => {
      // Show both user name and email in case more than 1 user has the same name.
      const label = user.name ? `${user.name} | ${user.email}` : user.email || '';
      return { label, value: user.id };
    });
  }, [selectedOrgId, users]);

  const onChangeOrg = (selected?: string) => {
    if (selected !== selectedOrgId) {
      setSelectedOrgId(selected);
      // If the selected org is the same as the impersonated user's org,
      // set the selected user to the impersonated user's id.
      const isImpersonatedOrg = selected === impersonatedUser?.organization.id;
      setSelectedUserId(isImpersonatedOrg ? impersonatedUser?.id : undefined);
    }
  };

  useEffect(() => {
    if (isOpen && impersonatedUser) {
      // Set selected state with impersonated user if exists.
      setSelectedOrgId(impersonatedUser.organization.id);
      setSelectedUserId(impersonatedUser.id);
    } else if (!isOpen) {
      // Reset state on closing modal.
      setSelectedOrgId(undefined);
      setSelectedUserId(undefined);
    }
  }, [isOpen, impersonatedUser]);

  return (
    <Dialog
      icon={<Icons icon={Icon.ZEUS} color="text-info" />}
      isOpen={isOpen}
      onClose={() => {
        dispatch(closeZeusModal());
      }}
      title="Enter Zeus mode"
      description="Impersonate another user's account."
      width={ZEUS_MODAL_WIDTH}
    >
      <div className="flex flex-col gap-4">
        <div className="flex items-center justify-between">
          <Typography size={TypographySize.H5}>Organization</Typography>
          <Select
            options={orgOptions}
            onChange={onChangeOrg}
            width={ZEUS_SELECT_WIDTH}
            placeholder="Select organization"
            loading={isLoadingOrgs}
            selected={orgOptions.find((org) => org.value === selectedOrgId)}
          />
        </div>
        <div className="flex items-center justify-between">
          <Typography size={TypographySize.H5}>User</Typography>
          <Select
            options={userOptions}
            onChange={setSelectedUserId}
            width={ZEUS_SELECT_WIDTH}
            placeholder="Select user"
            loading={isLoadingUsers}
            selected={userOptions.find((user) => user.value === selectedUserId)}
          />
        </div>
        <ZeusModalActions selectedOrgId={selectedOrgId} selectedUserId={selectedUserId} />
      </div>
    </Dialog>
  );
};

export default ZeusModal;
