import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { setAuth0ClientInstance } from './auth0ClientSingleton';

const Auth0ClientInitializer: React.FC = () => {
  const auth0 = useAuth0();

  useEffect(() => {
    setAuth0ClientInstance(auth0);
  }, [auth0]);

  return null;
};

export default Auth0ClientInitializer;
