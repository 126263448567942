import { ButtonColor, TextButton, Typography, TypographySize } from '../../../shared';

interface TableEmptyStateProps {
  description: string;
  buttonText: string;
  handleButtonClick: () => void;
}

const TableEmptyState = ({ description, buttonText, handleButtonClick }: TableEmptyStateProps) => {
  return (
    <div className="mb-20 flex h-full flex-col items-center justify-center gap-4">
      <Typography size={TypographySize.H5}>{description}</Typography>
      <TextButton text={buttonText} onClick={handleButtonClick} color={ButtonColor.SECONDARY} />
    </div>
  );
};

export default TableEmptyState;
