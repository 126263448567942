import { ComponentSize, TagColor } from '../../../types';
import { Icon } from '../Icons';

// Maps to DaisyUI Badge classes.
export enum BadgeType {
  OUTLINE = 'badge-outline',
  ERROR = 'badge-error',
  SUCCESS = 'badge-success', // TODO: Remove once we implement media player scorecards.
  GHOST = 'badge-ghost', // TODO: Remove once we implement media player scorecards.
}

export interface BadgeProps {
  /** The label to display in the badge. */
  label: string;
  /**
   * Whether the badge is active.
   * If true, the badge will be displayed in an active state.
   */
  active?: boolean;
  /** The color of the dot in the badge. */
  dotColor?: TagColor | string;
  /** The start icon to display in the badge. */
  icon?: Icon;
  /** The maximum width of the badge. */
  maxWidth?: string | number;
  /** Whether the badge should display a dot. */
  showDot?: boolean;
  /** The size of the badge. */
  size?: ComponentSize;
  /** The type of badge to display. */
  type?: BadgeType;
  /** The click handler for the badge. */
  onClick?: () => Promise<void> | void;
}
