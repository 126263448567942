import { TextColor } from '../../../../../types';
import { Accordion, Icon, Typography, TypographySize } from '../../../../shared';
import ItemWrapper from '../ItemWrapper';
import ProspectDesignerItem from './ProspectDesignerItem';
import ProspectHotlineItem from './ProspectHotlineItem';

enum CustomProspectItemType {
  PROSPECT_HOTLINE = 'PROSPECT_HOTLINE',
  PROSPECT_DESIGNER = 'PROSPECT_DESIGNER',
}

interface CustomProspectTabProps {
  onClose: () => void;
}

const CustomProspectTab = ({ onClose }: CustomProspectTabProps) => {
  const items = [
    {
      id: CustomProspectItemType.PROSPECT_HOTLINE,
      title: <Typography size={TypographySize.H4}>Prospect hotline</Typography>,
      content: <ProspectHotlineItem onClose={onClose} />,
      icon: Icon.CONCIERGE_BELL,
    },
    {
      id: CustomProspectItemType.PROSPECT_DESIGNER,
      title: <Typography size={TypographySize.H4}>Prospect designer</Typography>,
      content: <ProspectDesignerItem />,
      icon: Icon.BRUSH,
      disabled: true,
    },
  ];

  return (
    <div className="flex flex-col gap-4">
      <Typography size={TypographySize.H5} color={TextColor.SECONDARY}>
        Tailor a new Practice Prospect to your ICP and scenario.
      </Typography>
      <Accordion items={items.map((item) => ({ ...item, content: <ItemWrapper>{item.content}</ItemWrapper> }))} />
    </div>
  );
};

export default CustomProspectTab;
