import { KeyboardEvent } from 'react';

import { ButtonColor, Icon, PhoneNumberInput, TextButton, TextInput } from '../../../../../shared';

interface CandidateInputsProps {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  countryCode: string;
  errors: Record<string, boolean>;
  setFirstName: (value: string) => void;
  setLastName: (value: string) => void;
  setEmail: (value: string) => void;
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  setCountryCode: React.Dispatch<React.SetStateAction<string>>;
  resetError: () => void;
  handleCreateCandidate: () => void;
  onBack: () => void;
}

const CandidateInputs = ({
  firstName,
  lastName,
  email,
  phoneNumber,
  countryCode,
  errors,
  setFirstName,
  setLastName,
  setEmail,
  setPhoneNumber,
  setCountryCode,
  resetError,
  handleCreateCandidate,
  onBack,
}: CandidateInputsProps) => {
  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleCreateCandidate();
    }
  };

  return (
    <>
      <div className="flex gap-2">
        <TextButton text="Back" startIcon={Icon.CHEVRON_LEFT} onClick={onBack} color={ButtonColor.SECONDARY} />
        <TextInput
          placeholder="First name*"
          onChange={(e) => {
            setFirstName(e.target.value);
            resetError();
          }}
          value={firstName}
          error={!!errors.firstName}
          onKeyDown={handleKeyDown}
        />
        <TextInput
          placeholder="Last name*"
          onChange={(e) => {
            setLastName(e.target.value);
            resetError();
          }}
          value={lastName}
          error={!!errors.lastName}
          onKeyDown={handleKeyDown}
        />
      </div>
      <div className="flex gap-2">
        <div className="w-1/2">
          <PhoneNumberInput
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            countryCode={countryCode}
            setCountryCode={setCountryCode}
            error={!!errors.phoneNumber}
            onKeyDown={handleKeyDown}
            resetError={resetError}
          />
        </div>
        <div className="flex w-1/2 gap-2">
          <TextInput
            placeholder="Email address*"
            onChange={(e) => {
              setEmail(e.target.value);
              resetError();
            }}
            value={email}
            error={!!errors.email}
            className="flex-grow"
            onKeyDown={handleKeyDown}
          />
        </div>
      </div>
    </>
  );
};

export default CandidateInputs;
