import { Auth0ContextInterface } from '@auth0/auth0-react';

let auth0ClientInstance: Auth0ContextInterface | null = null;

export const setAuth0ClientInstance = (instance: Auth0ContextInterface) => {
  auth0ClientInstance = instance;
};

export const getAuth0ClientInstance = () => {
  if (!auth0ClientInstance) {
    throw new Error('Auth0 client instance is not set');
  }
  return auth0ClientInstance;
};
