import { REACT_APP_ENV } from '../app.config';
import { PracticeProspect, ProspectType } from '../types';

export const AMELIA_RESEARCHER: PracticeProspect = {
  personaId: 'amelia-sales-researcher',
  accountName: 'FullyRamped',
  associatedPhoneNumber: REACT_APP_ENV === 'production' ? '+14159171029' : '+18086462818',
  firstName: 'Amelia',
  jobTitle: REACT_APP_ENV === 'production' ? 'Sales Researcher' : 'Hotline Operator',
  lastName: REACT_APP_ENV === 'production' ? 'Sales Researcher' : 'Researcher',
  tags: [],
  type: ProspectType.FULLYRAMPED,
  createdAt: new Date(),
  createdBy: 'system',
  lastModified: new Date(),
  lastModifiedBy: 'system',
};
