import { DraggableProvidedDraggableProps, DraggableProvidedDragHandleProps } from '@hello-pangea/dnd';
import { ScorecardQuestionType, QuestionType } from '../../../../../types';
import { ButtonColor, ButtonVariant, Icon, IconButton, Select, TextInput } from '../../../../shared';

const SELECT_INPUT_WIDTH = 144;

const QUESTION_TYPE_OPTIONS = [
  { value: QuestionType.YES_NO, label: 'Yes / No' },
  { value: QuestionType.RANGE, label: 'Range (1-5)' },
];

interface ScorecardQuestionProps {
  question: ScorecardQuestionType;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  draggableProps?: DraggableProvidedDraggableProps;
  innerRef?: React.Ref<HTMLDivElement>;
  onUpdate?: (updatedQuestion: ScorecardQuestionType) => void;
  onRemove?: () => void;
}

const ScorecardQuestion = ({
  question,
  onUpdate,
  onRemove,
  dragHandleProps,
  draggableProps,
  innerRef,
}: ScorecardQuestionProps) => {
  return (
    <div ref={innerRef} {...draggableProps}>
      <div className="flex gap-2">
        <div {...dragHandleProps}>
          <IconButton icon={Icon.GRIP_VERTICAL} color={ButtonColor.SECONDARY} variant={ButtonVariant.GHOST} />
        </div>
        <TextInput
          placeholder="Question text*"
          onChange={(e) => onUpdate?.({ ...question, text: e.target.value })}
          value={question.text}
        />
        <Select
          options={QUESTION_TYPE_OPTIONS}
          selected={QUESTION_TYPE_OPTIONS.find((option) => option.value === question.type)}
          onChange={(selected) => onUpdate?.({ ...question, type: selected as QuestionType })}
          placeholder="Question type*"
          width={SELECT_INPUT_WIDTH}
        />
        <IconButton
          icon={Icon.TRASH}
          onClick={onRemove}
          color={ButtonColor.DESTRUCTIVE}
          variant={ButtonVariant.GHOST}
        />
      </div>
    </div>
  );
};

export default ScorecardQuestion;
