import { Dispatch, SetStateAction, useCallback } from 'react';
import { useHandleApiResponse, useToast } from '../../../../../hooks';
import { useDisableUserMutation } from '../../../../../services';
import { AppUser } from '../../../../../types';
import { AlertType } from '../../../../shared';
import ConfirmModal from '../../../ConfirmModal';

const ERROR_MSG = 'Failed to disable user';

interface DisableConfirmModalProps {
  isOpen: boolean;
  user: { id: string; name?: string };
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setUsers: Dispatch<SetStateAction<AppUser[]>>;
}

const DisableConfirmModal = ({ isOpen, user, setIsOpen, setUsers }: DisableConfirmModalProps) => {
  const { showToast } = useToast();
  const handleApiResponse = useHandleApiResponse();

  const [disableUser, { isLoading }] = useDisableUserMutation();

  // Handles disabling a user.
  const handleDisableUser = useCallback(async () => {
    try {
      const response = await disableUser({ id: user.id });

      const onSuccess = () => {
        // Optimistically update user state.
        setUsers((prev) =>
          prev.map((prevUser) => {
            return prevUser.id === user.id ? { ...prevUser, disabled: true } : prevUser;
          })
        );
        setIsOpen(false);
      };

      handleApiResponse({ response, errorMsg: ERROR_MSG, onSuccess });
    } catch (error) {
      console.error(`${ERROR_MSG}: `, error);
      showToast({ message: ERROR_MSG, type: AlertType.ERROR });
    }
  }, [handleApiResponse, setIsOpen, setUsers, showToast]);

  return (
    <ConfirmModal
      isOpen={isOpen}
      buttonText="Disable"
      setIsOpen={setIsOpen}
      onConfirm={handleDisableUser}
      isLoading={isLoading}
      title="Confirm disable user"
      destructive
      confirmText={
        <>
          Are you sure you want to disable&nbsp;
          <span className="font-medium">{user.name}</span>?
        </>
      }
    />
  );
};

export default DisableConfirmModal;
