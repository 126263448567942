import clsx from 'clsx';
import { ComponentSize } from '../../../types';
import { SKELETON_SIZE } from './Skeleton.constants';
import { SkeletonProps } from './Skeleton.types';

const Skeleton = ({ size = ComponentSize.SMALL }: SkeletonProps) => {
  return <div className={clsx('skeleton w-full', SKELETON_SIZE[size])}></div>;
};

export default Skeleton;
