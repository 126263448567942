import { SerializedError } from '@reduxjs/toolkit';
import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { SKIP_AUTH_HEADER } from '../constants';
import { getAuth0ClientInstance } from '../context/CustomAuth0Provider';
import { setConnectionStatus } from '../redux/reducers/connectionReducer';
import { AppDispatch } from '../redux/store';
import { ApiPostError } from '../types';
import { isSerializedError } from '../utils';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL, // Set the base URL for all queries.
  prepareHeaders: async (headers) => {
    // Skip authentication for requests with 'Skip-Auth' header.
    if (headers.get(SKIP_AUTH_HEADER) === 'true') {
      // Remove custom header before sending the request
      headers.delete(SKIP_AUTH_HEADER);
      return headers;
    }

    const auth0Client = getAuth0ClientInstance();
    const token = await auth0Client.getAccessTokenSilently();
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    return headers;
  },
});

/**
 * Maps a FetchBaseQueryError or SerializedError to an ApiPostError.
 */
const mapFetchBaseQueryError = (error: FetchBaseQueryError | SerializedError): ApiPostError => {
  if (isSerializedError(error)) {
    return error;
  }

  // Ensure the message exists in the error response or provide a fallback.
  const message =
    typeof error.data === 'object' && error.data && 'message' in error.data && typeof error.data.message === 'string'
      ? error.data.message
      : 'An unknown error occurred';

  return {
    data: { message },
    status: error.status,
  };
};

const apiBaseQuery: BaseQueryFn<string | FetchArgs, unknown, ApiPostError> = async (args, api, extraOptions) => {
  const { dispatch } = api as { dispatch: AppDispatch };

  if (navigator.onLine === false) {
    // If there's no connection, dispatch an action to update the state
    dispatch(setConnectionStatus(false));
  }

  const result = await baseQuery(args, api, extraOptions);
  if (result.error) {
    // Map the error to an ApiPostError.
    return {
      error: mapFetchBaseQueryError(result.error),
    };
  }

  return result;
};

export default apiBaseQuery;
