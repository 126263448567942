import { ComponentSize } from '../../../types';
import { TypographySize } from '../Typography';

export const DEFAULT_FILE_INPUT_PLACEHOLDER = 'No file chosen';
export const DEFAULT_MAX_FILE_SIZE = 50 * 1024 * 1024; // 50MB in bytes

// Mapping ComponentSize to TypographySize
export const COMPONENT_SIZE_TO_TYPOGRAPHY_SIZE: Record<ComponentSize, TypographySize> = {
  [ComponentSize.X_SMALL]: TypographySize.CAPTION,
  [ComponentSize.SMALL]: TypographySize.PARAGRAPH,
  [ComponentSize.MEDIUM]: TypographySize.PARAGRAPH,
  [ComponentSize.LARGE]: TypographySize.H5,
};
