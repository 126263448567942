// -- NOTES --
// Maximum number of rows to display before notes overflow.
export const MAX_NOTES_ROWS = 2;
// Notes height.
export const NOTES_HEIGHT = 16;

// -- TAGS --
// Maximum tag width before overflow.
export const MAX_TAG_WIDTH = 180;
// Tag height.
export const TAG_HEIGHT = 16;
// Gap between tags.
export const TAG_GAP = 4;
// Add tags icon button width.
export const MANAGE_TAGS_BUTTON_WIDTH = 16;
