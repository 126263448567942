import { RangeProps, RangeSize } from './Range.types';
import { Typography } from '../Typography';
import clsx from 'clsx';
import { RANGE_SIZE } from './Range.constants';

const Range = ({
  max = 5,
  min = 1,
  onChange,
  showStep = true,
  size = RangeSize.MEDIUM,
  step = 1,
  value,
}: RangeProps) => {
  return (
    <div className="flex items-center gap-2">
      <input
        className={clsx('range', RANGE_SIZE[size])}
        type="range"
        min={min}
        max={max}
        value={value}
        step={step}
        onChange={(e) => onChange?.(Number(e.target.value))}
      />
      {showStep && <Typography> {`${value}/${max}`}</Typography>}
    </div>
  );
};

export default Range;
