import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { ComponentSize, TagColor } from '../../../types';
import { conditionalObject, convertToPx } from '../../../utils';
import { BadgeDot } from '../BadgeDot';
import { Icons } from '../Icons';
import { Tooltip, TooltipContent, TooltipTrigger } from '../Tooltip';
import {
  BADGE_HOVER_BG_COLOR,
  BADGE_HOVER_TEXT_COLOR,
  BADGE_ICON_SIZE,
  BADGE_SIZE,
  BADGE_TEXT_COLOR,
} from './Badge.constants';
import { BadgeProps, BadgeType } from './Badge.types';

const Badge = ({
  label,
  active,
  dotColor = TagColor.BLUE,
  showDot,
  icon,
  maxWidth,
  size = ComponentSize.SMALL,
  type = BadgeType.GHOST,
  onClick,
}: BadgeProps) => {
  const labelRef = useRef<HTMLDivElement>(null);

  const [hovered, setHovered] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const bgColor = active || hovered ? BADGE_HOVER_BG_COLOR[type] : type;
  const textColor = active || hovered ? BADGE_HOVER_TEXT_COLOR[type] : BADGE_TEXT_COLOR[type];

  const handleClick = onClick
    ? (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        onClick();
      }
    : undefined;

  useEffect(() => {
    if (labelRef.current) {
      setIsOverflowing(labelRef.current.scrollWidth > labelRef.current.clientWidth);
    }
  }, [label, maxWidth]);

  return (
    <Tooltip>
      <TooltipTrigger>
        <div
          className={clsx(
            'badge flex items-center justify-center gap-2',
            onClick ? 'cursor-pointer' : 'pointer-events-none',
            bgColor,
            BADGE_SIZE[size]
          )}
          style={{ maxWidth: convertToPx(maxWidth) }}
          onClick={handleClick}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          {showDot && <BadgeDot color={dotColor} />}
          {icon && <Icons icon={icon} size={BADGE_ICON_SIZE[size]} color={textColor} />}
          <div
            ref={labelRef}
            className={clsx('whitespace-nowrap', textColor)}
            style={{
              ...conditionalObject(!!maxWidth, {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }),
            }}
          >
            {label}
          </div>
        </div>
      </TooltipTrigger>
      {/* Show tooltip only when the label is overflowing */}
      {isOverflowing && <TooltipContent>{label}</TooltipContent>}
    </Tooltip>
  );
};

export default Badge;
