import { Dialog, DialogType, Spinner, Typography, TypographySize, TypographyWeight } from '../../components';
import fullyRampedLogo from '../../assets/logo-light.png';

const AccountSetupLoading = () => {
  return (
    <Dialog isOpen={true} type={DialogType.CONFIRM} className="flex h-[488px]">
      <div className="flex h-full flex-col items-center justify-center gap-8">
        <div className="flex flex-col items-center gap-4">
          <img src={fullyRampedLogo} alt="FullyRamped Logo" className="h-14 w-14" />
          <Typography size={TypographySize.H2} weight={TypographyWeight.SEMI_BOLD}>
            Creating your account...
          </Typography>
        </div>
        <Spinner />
      </div>
    </Dialog>
  );
};

export default AccountSetupLoading;
