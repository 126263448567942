import { useCallback } from 'react';
import { useUploadFile } from '../../../hooks';
import { ComponentSize } from '../../../types';
import { FileInput } from '../../shared';
import UploadModalSubmitSection from './UploadModalSubmitSection';
import UploadModalSubmitSuccess from './UploadModalSubmitSuccess';

interface UploadModalSubmitFileProps {
  error: string;
  isFileSubmitted: boolean;
  selectedFile: File | undefined;
  setError: React.Dispatch<React.SetStateAction<string>>;
  setIsFileSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedFile: React.Dispatch<React.SetStateAction<File | undefined>>;
}

const UploadModalSubmitFile = ({
  error,
  isFileSubmitted,
  selectedFile,
  setError,
  setIsFileSubmitted,
  setSelectedFile,
}: UploadModalSubmitFileProps) => {
  const { uploadFile, isLoading } = useUploadFile();

  const runSubmitFile = useCallback(async () => {
    if (!selectedFile) return;

    const result = await uploadFile(selectedFile);
    if (result?.error) {
      setError(result.error);
    } else {
      setSelectedFile(undefined);
      setIsFileSubmitted(true);
    }
  }, [selectedFile, uploadFile, setIsFileSubmitted, setSelectedFile]);

  if (isFileSubmitted) return <UploadModalSubmitSuccess handleSubmitAnother={() => setIsFileSubmitted(false)} />;

  return (
    <UploadModalSubmitSection
      title="Submit a file"
      onSubmit={runSubmitFile}
      isSubmitDisabled={!selectedFile}
      isLoading={isLoading}
    >
      <FileInput
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        size={ComponentSize.MEDIUM}
        setError={setError}
        error={error}
      />
    </UploadModalSubmitSection>
  );
};

export default UploadModalSubmitFile;
