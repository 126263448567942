import { ComponentSize, TextColor } from '../../../types';
import { TypographySize } from '../Typography';
import { ButtonColor, ButtonVariant } from './Button.types';

/**
 * Configurations based on the button size.
 */
export const BUTTON_SIZE_CONFIG: Record<
  ComponentSize,
  { btnSizeClass: string; iconSize: ComponentSize; textSize: TypographySize }
> = {
  [ComponentSize.X_SMALL]: {
    btnSizeClass: 'btn-xs',
    iconSize: ComponentSize.SMALL,
    textSize: TypographySize.CAPTION,
  },
  [ComponentSize.SMALL]: {
    btnSizeClass: 'btn-sm',
    iconSize: ComponentSize.MEDIUM,
    textSize: TypographySize.PARAGRAPH,
  },
  [ComponentSize.MEDIUM]: {
    btnSizeClass: 'btn-md',
    iconSize: ComponentSize.MEDIUM,
    textSize: TypographySize.PARAGRAPH,
  },
  [ComponentSize.LARGE]: {
    btnSizeClass: 'btn-lg',
    iconSize: ComponentSize.LARGE,
    textSize: TypographySize.H4,
  },
};

/**
 * Configurations based on the button variant.
 */
export const BUTTON_VARIANT_CONFIG: Record<
  ButtonVariant,
  Partial<Record<ButtonColor, Record<string, { bg: string; text: TextColor | string }>>>
> = {
  [ButtonVariant.FILLED]: {
    [ButtonColor.PRIMARY]: {
      default: { bg: '!bg-primary', text: TextColor.WHITE },
      active: { bg: '!bg-green-700', text: TextColor.WHITE },
      hover: { bg: '!bg-green-300', text: TextColor.WHITE },
      disabled: { bg: 'pointer-events-none !bg-green-200', text: TextColor.WHITE },
    },
    [ButtonColor.SECONDARY]: {
      default: { bg: '!bg-gray-300', text: TextColor.PRIMARY },
      active: { bg: '!bg-gray-500', text: TextColor.PRIMARY },
      hover: { bg: '!bg-gray-100', text: TextColor.PRIMARY },
      disabled: { bg: 'pointer-events-none !bg-gray-200', text: 'text-gray-700' },
    },
    [ButtonColor.DESTRUCTIVE]: {
      default: { bg: '!bg-error-content', text: TextColor.WHITE },
      active: { bg: '!bg-red-700', text: TextColor.WHITE },
      hover: { bg: '!bg-red-300', text: TextColor.WHITE },
      disabled: { bg: 'pointer-events-none !bg-red-200', text: TextColor.WHITE },
    },
    [ButtonColor.INVERTED]: {
      default: { bg: 'btn-neutral', text: TextColor.WHITE },
      active: { bg: '!bg-gray-800', text: TextColor.WHITE },
      hover: { bg: '!bg-gray-900', text: TextColor.WHITE },
      disabled: { bg: 'pointer-events-none !bg-gray-600', text: TextColor.WHITE },
    },
  },
  [ButtonVariant.OUTLINE]: {
    [ButtonColor.PRIMARY]: {
      default: { bg: 'btn-outline !border !border-primary', text: 'text-primary' },
      active: { bg: 'btn-outline !border !bg-transparent !border-green-700', text: 'text-green-700' },
      hover: { bg: 'btn-outline !border !bg-transparent !border-green-300', text: 'text-green-300' },
      disabled: { bg: 'pointer-events-none btn-outline !border !border-green-200', text: 'text-green-200' },
    },
    [ButtonColor.SECONDARY]: {
      default: { bg: 'btn-outline !border !border-neutral', text: TextColor.PRIMARY },
      active: { bg: 'btn-outline !border !bg-transparent !border-neutral', text: TextColor.PRIMARY },
      hover: { bg: 'btn-outline !border !bg-transparent !border-gray-800', text: 'text-gray-800' },
      disabled: { bg: 'pointer-events-none btn-outline !border !border-gray-700', text: 'text-gray-700' },
    },
    [ButtonColor.DESTRUCTIVE]: {
      default: { bg: 'btn-outline !border !border-error-content', text: TextColor.DESTRUCTIVE },
      active: { bg: 'btn-outline !border !bg-transparent !border-red-700', text: 'text-red-700' },
      hover: { bg: 'btn-outline !border !bg-transparent !border-red-300', text: 'text-red-300' },
      disabled: { bg: 'pointer-events-none btn-outline !border !border-red-200', text: 'text-red-200' },
    },
  },
  [ButtonVariant.GHOST]: {
    [ButtonColor.PRIMARY]: {
      default: { bg: '!bg-transparent', text: 'text-primary' },
      active: { bg: '!bg-transparent', text: 'text-green-700' },
      hover: { bg: '!bg-transparent', text: 'text-green-300' },
      disabled: { bg: 'pointer-events-none !bg-transparent', text: 'text-green-200' },
    },
    [ButtonColor.SECONDARY]: {
      default: { bg: '!bg-transparent', text: TextColor.PRIMARY },
      active: { bg: '!bg-transparent', text: TextColor.PRIMARY },
      hover: { bg: '!bg-transparent', text: 'text-gray-800' },
      disabled: { bg: 'pointer-events-none !bg-transparent', text: 'text-gray-700' },
    },
    [ButtonColor.DESTRUCTIVE]: {
      default: { bg: '!bg-transparent', text: TextColor.DESTRUCTIVE },
      active: { bg: '!bg-transparent', text: 'text-red-700' },
      hover: { bg: '!bg-transparent', text: 'text-red-300' },
      disabled: { bg: 'pointer-events-none !bg-transparent', text: 'text-red-200' },
    },
  },
};
