import { useAppDispatch, useAppSelector } from '../../../hooks';
import { closeWebCall, startNextWebCall } from '../../../redux/reducers';
import { PracticeProspect, TextColor } from '../../../types';
import { ButtonColor, TextButton, Typography, TypographySize } from '../../shared';
import WebCallProspect from './WebCallProspect';

interface WebCallUpNextProps {
  currentProspect: PracticeProspect;
  nextProspect?: PracticeProspect;
}

const WebCallUpNext = ({ currentProspect, nextProspect }: WebCallUpNextProps) => {
  const dispatch = useAppDispatch();
  const prospects = useAppSelector((state) => state.practice.prospects);

  const renderExitButton = () => (
    <TextButton
      text="Exit"
      color={ButtonColor.SECONDARY}
      fullWidth
      onClick={() => {
        dispatch(closeWebCall());
      }}
    />
  );

  if (!nextProspect) {
    return renderExitButton();
  }

  const isLastProspect =
    prospects.findIndex((prospect) => prospect.personaId === currentProspect.personaId) === prospects.length - 1;

  return (
    <div className="relative flex items-center justify-between gap-4 rounded-[4px] border border-primary p-4">
      <span className="left-1/5 absolute -top-2 transform bg-white px-2">
        <Typography color={TextColor.SECONDARY} size={TypographySize.CAPTION}>
          {isLastProspect ? 'From the top' : 'Up next'}
        </Typography>
      </span>
      <WebCallProspect prospect={nextProspect} />
      <div className="flex flex-col gap-2">
        <TextButton
          text="Start call"
          onClick={() => {
            dispatch(startNextWebCall());
          }}
        />
        {renderExitButton()}
      </div>
    </div>
  );
};

export default WebCallUpNext;
