import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { NavBar, Sidebar, WebCallModal } from '../../components';
import { AppRoutes } from '../../constants';
import { useAppDispatch, useAppSelector, useIntercom } from '../../hooks';
import { setCurrentCallDetails } from '../../redux/reducers';
import Banners from './Banners';

function Layout() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const currWebCall = useAppSelector((state) => state.practice.currWebCall);

  useIntercom();

  // Reset the call details when the user navigates away from the call history page.
  useEffect(() => {
    if (!location.pathname.includes(AppRoutes.CALL_HISTORY)) {
      dispatch(setCurrentCallDetails(undefined));
    }
  }, [location, dispatch]);

  return (
    <div className="flex h-screen flex-col">
      <Banners />
      <NavBar />
      <div className="flex flex-1 overflow-hidden">
        <Sidebar />
        <div className="flex-1 overflow-auto bg-base-0 p-4">
          <Outlet />
        </div>
      </div>
      {currWebCall && <WebCallModal currWebCall={currWebCall} />}
    </div>
  );
}

export default Layout;
