import { TooltipType } from '../types';

/** Extracts the placement from a tooltip object. */
export const getTooltipPlacement = (tooltip: TooltipType) => {
  return tooltip && typeof tooltip === 'object' && 'placement' in tooltip ? tooltip.placement : undefined;
};

/** Extracts the content from a tooltip object. */
export const getTooltipContent = (tooltip: TooltipType) => {
  return tooltip && typeof tooltip === 'object' && 'content' in tooltip ? tooltip.content : tooltip;
};
