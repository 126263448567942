import * as RadixAccordion from '@radix-ui/react-accordion';
import clsx from 'clsx';
import { forwardRef } from 'react';

const AccordionContent = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ children, className, ...props }, forwardedRef) => {
    return (
      <RadixAccordion.Content className={clsx('AccordionContent', className)} {...props} ref={forwardedRef}>
        <div className="AccordionContentText">{children}</div>
      </RadixAccordion.Content>
    );
  }
);

AccordionContent.displayName = 'AccordionContent';

export default AccordionContent;
