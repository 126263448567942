import clsx from 'clsx';
import { Orientation } from '../../../types';
import './Divider.css';
import { DividerProps } from './Divider.types';

/** Divider component for rendering a horizontal or vertical divider with optional text. */
const Divider = ({ className, text, type = Orientation.HORIZONTAL }: DividerProps) => {
  const isHorizontal = type === Orientation.HORIZONTAL;
  return (
    <div
      className={clsx('divider m-0', isHorizontal ? 'divider-vertical h-fit' : 'divider-horizontal w-fit', className)}
    >
      {text}
    </div>
  );
};

export default Divider;
