import { ButtonColor, TextButton, Typography, TypographySize } from '../../components';
import { useAppDispatch } from '../../hooks';
import { openUploadModal } from '../../redux/reducers';

/**
 * Renders a footer for the call practice page.
 */
const PracticePageFooter = () => {
  // Redux
  const dispatch = useAppDispatch();

  const handleEnhanceClick = () => {
    dispatch(openUploadModal());
  };

  return (
    <div className="flex flex-col items-center gap-4">
      <Typography size={TypographySize.H5}>Want to improve your prospects?</Typography>
      <TextButton text="Enhance" fullWidth onClick={handleEnhanceClick} color={ButtonColor.SECONDARY} />
    </div>
  );
};

export default PracticePageFooter;
