import { ScorecardQuestionType, ScorecardSectionType, YesNoAnswer } from '../types';
import { v4 as uuidv4 } from 'uuid';

export const INITIAL_QUESTION: ScorecardQuestionType = {
  id: uuidv4(),
  text: '',
};

export const INITIAL_SECTION: ScorecardSectionType = {
  id: uuidv4(),
  title: '',
  questions: [INITIAL_QUESTION],
};

export const YES_NO_QUESTION_OPTIONS = [
  { value: YesNoAnswer.YES, label: 'Yes' },
  { value: YesNoAnswer.NO, label: 'No' },
];
// Initial sections array with at least one section and at least one question
export const INITIAL_SECTIONS: ScorecardSectionType[] = [INITIAL_SECTION];
