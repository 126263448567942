import { REACT_APP_ENV } from '../../../../../app.config';
import { AMELIA_RESEARCHER } from '../../../../../constants';
import { useAppDispatch, useFeatureFlag, useToast } from '../../../../../hooks';
import { startWebCall } from '../../../../../redux/reducers';
import { LD_FeatureFlags, TextColor } from '../../../../../types';
import {
  AlertType,
  ButtonColor,
  ButtonVariant,
  Icon,
  IconButton,
  TextButton,
  Typography,
  TypographySize,
} from '../../../../shared';

interface ProspectHotlineItemProps {
  onClose: () => void;
}

const ProspectHotlineItem = ({ onClose }: ProspectHotlineItemProps) => {
  const dispatch = useAppDispatch();

  const { showToast } = useToast();
  const webCallFF = useFeatureFlag(LD_FeatureFlags.WEB_CALLING);

  const handleCopyPhoneNumber = () => {
    window.navigator.clipboard.writeText(AMELIA_RESEARCHER.associatedPhoneNumber);
    showToast({
      message: 'Phone number copied to clipboard',
      type: AlertType.SUCCESS,
    });
  };

  const handleCallNow = () => {
    if (webCallFF && REACT_APP_ENV !== 'development') {
      onClose();
      dispatch(startWebCall({ prospect: AMELIA_RESEARCHER, hideUpNext: true }));
    } else {
      window.location.href = `tel:${AMELIA_RESEARCHER.associatedPhoneNumber}`;
    }
  };

  return (
    <div className="flex items-center justify-between">
      <Typography size={TypographySize.H5} color={TextColor.SECONDARY}>
        Amelia answers the phone 24/7!
      </Typography>
      <div className="flex gap-2">
        <IconButton
          icon={Icon.COPY}
          onClick={handleCopyPhoneNumber}
          variant={ButtonVariant.GHOST}
          color={ButtonColor.SECONDARY}
          tooltip="Copy phone number"
        />
        <TextButton text="Call now" onClick={handleCallNow} />
      </div>
    </div>
  );
};

export default ProspectHotlineItem;
