import { createContext } from 'react';

// Define the context structure
export interface FeatureFlags {
  [key: string]: boolean;
}

// Create a context to hold feature flags
const FeatureFlagContext = createContext<FeatureFlags>({});

export default FeatureFlagContext;
