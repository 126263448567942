import { ButtonColor, TextButton, Typography, TypographySize } from '../../components';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { openSettingsModal, SettingsModalTabs } from '../../redux/reducers';
import { useGetPhoneNumbersQuery } from '../../services';
import { Roles, TextColor } from '../../types';

/**
 * Renders a footer for the call history page.
 * If the user is an admin or manager, it shows a message to check their team's phone numbers.
 * If the user is a sales rep, it shows a message to verify their own phone number.
 */
const CallHistoryFooter = () => {
  // Redux
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const isSuperAdmin = user?.role === Roles.SUPER_ADMIN;
  const isSalesRep = user?.role === Roles.SALES_REP;

  // Fetch phone number data.
  const { data: phoneNumberData = [] } = useGetPhoneNumbersQuery();

  // If the user is a super-admin, or if the user is a sales rep and has a phone number, return null.
  if (!user || isSuperAdmin || (isSalesRep && !!phoneNumberData.length)) return null;

  return (
    <div className="flex flex-col items-center gap-4">
      <Typography size={TypographySize.H5}>Can&apos;t find practice calls?</Typography>
      {isSalesRep && (
        <TextButton
          text="Verify your phone number"
          color={ButtonColor.SECONDARY}
          onClick={() => {
            dispatch(openSettingsModal(SettingsModalTabs.PROFILE));
          }}
        />
      )}
      {!isSalesRep && (
        <Typography color={TextColor.SECONDARY}>
          Make sure your team has added phone numbers to their profile.
        </Typography>
      )}
    </div>
  );
};

export default CallHistoryFooter;
