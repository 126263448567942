import { useEffect } from 'react';
import { ButtonColor, Select, Spinner, TextButton, Divider } from '../../../../components';
import { useAppDispatch } from '../../../../hooks';
import { openScorecardForm } from '../../../../redux/reducers/modalReducer';
import { useGetScorecardTemplateQuery, useGetScorecardTemplatesForSelectQuery } from '../../../../services';
import { BaseScorecardTemplate, ComponentSize, ScorecardTemplate } from '../../../../types';

interface NewCallScorecardProps {
  scorecardTemplate?: BaseScorecardTemplate | ScorecardTemplate;
  setScorecardTemplate: (scorecard: BaseScorecardTemplate | ScorecardTemplate | undefined) => void;
}

const NewCallScorecard = ({ scorecardTemplate, setScorecardTemplate }: NewCallScorecardProps) => {
  const dispatch = useAppDispatch();

  // Fetch all active scorecard templates
  const { data: scorecardTemplates, isLoading: isScorecardTemplatesLoading } = useGetScorecardTemplatesForSelectQuery();

  // Fetch selected template details
  const { data: selectedTemplateDetails, isLoading: isSelectedTemplateLoading } = useGetScorecardTemplateQuery(
    scorecardTemplate?.id ?? '',
    {
      skip: !scorecardTemplate,
    }
  );

  // Update scorecardTemplate when selected template details are fetched
  useEffect(() => {
    if (selectedTemplateDetails) {
      setScorecardTemplate(selectedTemplateDetails);
    }
  }, [selectedTemplateDetails, setScorecardTemplate]);

  // Prepare options for the Select component
  const scorecardOptions = scorecardTemplates?.map((scorecard) => ({
    value: scorecard.id,
    label: scorecard.name,
  }));

  // Handle scorecard selection change
  const handleScorecardChange = (selectedValue?: string) => {
    if (!selectedValue) {
      setScorecardTemplate(undefined);
      return;
    }

    const newSelectedScorecard = scorecardTemplates?.find((scorecard) => scorecard.id === selectedValue);
    setScorecardTemplate(newSelectedScorecard);
  };

  // Render "Create new" button
  const renderCreateNewScorecardButton = () => (
    <TextButton
      text="Create new"
      onClick={() => {
        dispatch(openScorecardForm());
      }}
      color={ButtonColor.SECONDARY}
      fullWidth
    />
  );

  const renderSpinner = () => (
    <div className="flex h-full items-center justify-center">
      <Spinner size={ComponentSize.SMALL} />
    </div>
  );

  // If scorecard templates are loading, show spinner
  if (isScorecardTemplatesLoading) return renderSpinner();

  // If there are no scorecard templates, only show the "Create new" button
  if (!scorecardTemplates?.length) return renderCreateNewScorecardButton();

  return (
    <>
      {/* Scorecard template selector */}
      <Select
        disabled={isSelectedTemplateLoading}
        options={scorecardOptions ?? []}
        placeholder="Select scorecard"
        selected={scorecardTemplate ? { value: scorecardTemplate.id, label: scorecardTemplate.name } : undefined}
        onChange={(selectedValue) => handleScorecardChange(selectedValue)}
      />
      {/* If selected template is loading, show spinner below the selector */}
      {isSelectedTemplateLoading && renderSpinner()}
      {/* Show "Create new" button if no scorecard is selected */}
      {!scorecardTemplate && (
        <div className="flex flex-col gap-4">
          <Divider text="OR" />
          {renderCreateNewScorecardButton()}
        </div>
      )}
    </>
  );
};

export default NewCallScorecard;
