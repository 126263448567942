import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { DropdownPlacement } from '../../../types';
import { Avatar, Dropdown, DropdownContent, DropdownTrigger, Typography, TypographySize } from '../../shared';
import './CommentAvatar.css';
import CommentMoreButton from './CommentMoreButton';

// Closed/opened avatar size in pixels.
const CLOSED_AVATAR_SIZE = 16;
const OPENED_AVATAR_SIZE = 32;

// Maximum width of the dropdown content.
const MAX_DROPDOWN_WIDTH = 300;

interface CommentAvatarProps {
  author: { name: string; imgSrc?: string };
  comment: string;
  commentId: string;
  timestamp: number;
  duration: number;
  waveformWidth: number;
}

const CommentAvatar = ({ author, comment, commentId, timestamp, duration, waveformWidth }: CommentAvatarProps) => {
  // State to manage whether the user is hovering over the avatar.
  const [isHovering, setIsHovering] = useState(false);
  // State to manage whether the dropdown is open.
  const [isOpen, setIsOpen] = useState(false);
  // State to manage the avatar's horizontal position in the comments panel.
  const [left, setLeft] = useState(0);

  // Handles calculating the avatar's position in the comments panel
  // based on timestamp, duration, and waveformWidth.
  useEffect(() => {
    if (duration) {
      const newPosition = (timestamp / duration) * waveformWidth - 8;
      setLeft(newPosition);
    }
  }, [isOpen, timestamp, duration, waveformWidth]);

  // Determine avatar size based on whether the dropdown is open.
  const avatarSize = isOpen ? OPENED_AVATAR_SIZE : CLOSED_AVATAR_SIZE;

  // Calculate the top position for the avatar.
  // When the dropdown is open, the avatar is slightly shifted upwards
  // This calculation is used to lift the avatar up to make room for the dropdown content
  const top = isOpen ? -OPENED_AVATAR_SIZE / 2 : undefined;

  // Determine the z-index based on whether the dropdown is open.
  // Higher z-index is used when the dropdown is open to ensure it appears above other elements.
  const zIndex = isOpen ? 'z-30' : 'z-20';

  return (
    <Dropdown
      open={isOpen}
      onOpenChange={setIsOpen}
      placement={DropdownPlacement.RIGHT_END}
      showArrow
      minWidth={MAX_DROPDOWN_WIDTH / 2}
      maxWidth={MAX_DROPDOWN_WIDTH}
      offset={8}
    >
      <DropdownTrigger
        onClick={() => setIsOpen(!isOpen)}
        className={clsx('avatar-container', zIndex, (isOpen || isHovering) && 'fully-visible')}
        style={{ left, top }}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <Avatar label={author.name} size={avatarSize} imgSrc={author.imgSrc} ring />
      </DropdownTrigger>
      <DropdownContent className="flex-col gap-2 rounded-xl bg-base-100 p-4">
        <div className="flex items-center justify-between">
          <Typography size={TypographySize.CAPTION}>{author.name}</Typography>
          <CommentMoreButton commentId={commentId} />
        </div>
        <Typography size={TypographySize.H5}>{comment}</Typography>
      </DropdownContent>
    </Dropdown>
  );
};

export default CommentAvatar;
