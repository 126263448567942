import { TypographySize } from '../Typography';
import { UserBadgeSize } from './UserBadge.types';

export const USER_BADGE_TITLE_SIZE: Record<UserBadgeSize, TypographySize> = {
  small: TypographySize.PARAGRAPH,
  medium: TypographySize.PARAGRAPH,
};

export const USER_BADGE_SUBTITLE_SIZE: Record<UserBadgeSize, TypographySize> = {
  small: TypographySize.CAPTION,
  medium: TypographySize.PARAGRAPH,
};
