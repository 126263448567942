import { Pagination } from './api.types';
import { Tag } from './tags.types';

export enum ProspectType {
  REPS = 'REPS',
  CANDIDATES = 'CANDIDATES',
  GTM = 'GTM',
  FULLYRAMPED = 'FULLYRAMPED',
}

// -- ENUMS --
export enum ProspectSortingFilters {
  ASSOCIATED_PHONE_NUMBER = 'associatedPhoneNumber',
  FIRST_NAME = 'firstName',
  CREATED_AT = 'createdAt',
}

// -- TYPES --
export type BasePracticeProspect = {
  accountName: string;
  firstName: string;
  lastName: string;
  personaId: string;
  jobTitle: string;
  associatedPhoneNumber: string;
  type: ProspectType;
};

type PracticeProspectWithoutTags = BasePracticeProspect & {
  createdAt: Date;
  createdBy: string;
  lastModified: Date;
  lastModifiedBy: string;
  notes?: string;
};

/** Represents a Practice Prospect as it is used across the app with tags as a flat array of Tag objects. */
export type PracticeProspect = PracticeProspectWithoutTags & { tags: Tag[] };

/** Represents a Practice Prospect as it comes from the API with tags as an array of objects with a tag property. */
export type DBPracticeProspect = PracticeProspectWithoutTags & { tags: { tag: Tag }[] };

export type PracticeProspectsResponse = {
  data: {
    prospects: DBPracticeProspect[];
    pagination: Pagination;
  };
};

export type PracticeProspectsSelectResponse = {
  data: {
    prospects: BasePracticeProspect[];
  };
};

/** Represents data related to a prospect. */
export type ProspectColumnData = {
  // The company where the prospect works.
  company: string;
  // The job title of the prospect.
  jobTitle: string;
  // The full name of the prospect.
  name: string;
  // The ID of the prospect.
  id?: string;
  // The tags associated with the prospect.
  tags?: Tag[];
};

export type ProspectColumn = {
  phoneNumber: string;
  prospect: ProspectColumnData;
  createdAt: Date;
  notes?: string;
};

/** Represents data related to a selected prospect. */
export type GetPracticeProspectsForSelectPayload = {
  type?: string;
  includeJobTitle?: boolean;
  includeAssociatedPhoneNumber?: boolean;
};
