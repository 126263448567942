import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../../hooks';
import TabHeader from '../TabHeader';
import CandidateForm from './CandidateForm';
import CandidateTable from './CandidateTable';

const HiringTab = () => {
  const [isAddingCandidate, setIsAddingCandidate] = useState(false);

  const isSettingsModalOpen = useAppSelector((state) => state.modal.settings.isOpen);

  useEffect(() => {
    // Reset the hiring tab state to view candidates table
    setIsAddingCandidate(false);
  }, [isSettingsModalOpen]);

  // Render the add candidate form
  if (isAddingCandidate) {
    return <CandidateForm onBack={() => setIsAddingCandidate(false)} />;
  }

  const handleCreateNewCandidate = () => {
    setIsAddingCandidate(true);
  };

  return (
    <div className="flex h-full w-full flex-col gap-4">
      <TabHeader title="Candidates" handleCreateNew={handleCreateNewCandidate} />
      <CandidateTable handleCreateNew={handleCreateNewCandidate} />
    </div>
  );
};

export default HiringTab;
