import { useCallback } from 'react';
import { AlertType, Icon } from '../../components';
import { useAppDispatch, useFeatureFlag, useToast } from '../../hooks';
import { startWebCall } from '../../redux/reducers/practiceReducer';
import { LD_FeatureFlags, PracticeFilterKeys, PracticeProspect } from '../../types';

const useProspectActions = (onClose: () => void, clickedProspect?: PracticeProspect) => {
  const dispatch = useAppDispatch();

  const { showToast } = useToast();
  const webCallFF = useFeatureFlag(LD_FeatureFlags.WEB_CALLING);

  // Handles copying the prospect's phone number to the clipboard.
  const onCopy = useCallback(
    (prospect: PracticeProspect) => {
      const phoneNumber = prospect.associatedPhoneNumber;
      navigator.clipboard.writeText(phoneNumber);
      showToast({ message: 'Phone number copied to clipboard', type: AlertType.SUCCESS });
    },
    [showToast]
  );

  // Handles initiating a phone call to the provided prospect.
  const onDial = useCallback(
    (prospect: PracticeProspect) => {
      if (webCallFF) {
        dispatch(startWebCall({ prospect }));
      } else {
        window.location.href = `tel:${prospect.associatedPhoneNumber}`;
      }
    },
    [webCallFF, dispatch]
  );

  // Handles copying the prospect's link to the clipboard.
  const onShare = useCallback(
    (prospect: PracticeProspect) => {
      // Generate the URL based on the current window location.
      const url = new URL(window.location.href);
      // Clear all existing search parameters.
      url.search = '';
      // Set the new query parameter.
      url.searchParams.set(PracticeFilterKeys.PROSPECT, prospect.personaId);

      // Copy the URL to the clipboard.
      navigator.clipboard.writeText(url.toString());
      showToast({ message: 'Link to prospect copied to clipboard', type: AlertType.SUCCESS });
    },
    [showToast]
  );

  // Handles performing the provided prospect action
  // by calling the action with the clicked prospect if it exists and then closing the modal.
  const handleProspectAction = useCallback(
    (action: (prospect: PracticeProspect) => void) => {
      if (!clickedProspect) return;
      action(clickedProspect);
      onClose();
    },
    [clickedProspect, onClose]
  );

  return [
    {
      label: 'Call',
      icon: Icon.PHONE,
      onClick: () => handleProspectAction(onDial),
    },
    {
      label: 'Share',
      icon: Icon.SHARE,
      successMsg: 'Link to practice prospect copied to clipboard',
      onClick: () => handleProspectAction(onShare),
    },
    {
      label: 'Copy phone number',
      icon: Icon.COPY,
      onClick: () => handleProspectAction(onCopy),
    },
  ];
};

export default useProspectActions;
