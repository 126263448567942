import { ComponentSize } from '../../../types';
import { TextColor } from '../../../types/colors.types';
import {
  ButtonColor,
  ButtonGroup,
  Dialog,
  DialogType,
  Icon,
  Icons,
  TextButton,
  Typography,
  TypographySize,
} from '../../shared';

const MIC_ACCESS_BLOCKED_MODAL_WIDTH = 440;

interface MicAccessBlockedModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onTryAgain: () => void;
}

const MicAccessBlockedModal = ({ isOpen, onCancel, onTryAgain }: MicAccessBlockedModalProps) => (
  <Dialog
    isOpen={isOpen}
    onClose={onCancel}
    title="Microphone access blocked"
    description="FullyRamped requires microphone access in order to make calls."
    icon={<Icons icon={Icon.CIRCLE_ALERT} color={TextColor.DESTRUCTIVE} />}
    width={MIC_ACCESS_BLOCKED_MODAL_WIDTH}
    type={DialogType.CONFIRM}
  >
    <div className="flex flex-col gap-4">
      <Typography size={TypographySize.H5}>
        Go to your <span className="font-medium">Browser Settings</span> and give FullyRamped microphone access to
        continue.
      </Typography>
      <ButtonGroup size={ComponentSize.MEDIUM}>
        <TextButton text="Try again" onClick={onTryAgain} color={ButtonColor.PRIMARY} />
        <TextButton text="Cancel" onClick={onCancel} />
      </ButtonGroup>
    </div>
  </Dialog>
);

export default MicAccessBlockedModal;
