import { Tag } from './tags.types';

/** Yes or no answer type */
export enum YesNoAnswer {
  YES = 'YES',
  NO = 'NO',
}

/** Question types */
export enum QuestionType {
  YES_NO = 'YES_OR_NO',
  RANGE = 'RANGE',
}

/** Scorecard statuses */
export enum ScorecardStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

/** Scorecard answer type */
export type ScorecardAnswer = {
  id?: string;
  response?: string;
  questionId: string;
};

/** Question type */
export type ScorecardQuestionType = {
  id: string;
  text: string;
  order?: number;
  type?: QuestionType;
  answers?: ScorecardAnswer[];
};

/** Section type */
export type ScorecardSectionType = {
  id: string;
  title: string;
  order?: number;
  questions: ScorecardQuestionType[];
};

export type BaseScorecardTemplate = {
  id: string;
  name: string;
};

/** Scorecard template type */
export type ScorecardTemplate = BaseScorecardTemplate & {
  lastEditor: {
    name: string;
    picture: string;
  };
  status: ScorecardStatus;
  updatedAt: string;
  isUsedInScorecards: boolean;
  sections?: ScorecardSectionType[];
};

/** Scorecard templates response type */
export type ScorecardTemplatesResponse = {
  data: {
    scorecardTemplates: ScorecardTemplate[];
  };
};

/** Scorecard templates select response type */
export type ScorecardTemplatesSelectResponse = {
  data: {
    scorecardTemplates: BaseScorecardTemplate[];
  };
};

/** Scorecard template response type */
export type ScorecardTemplateResponse = {
  data: {
    scorecardTemplate: ScorecardTemplate;
  };
};

/** Create scorecard template payload type */
export type CreateScorecardTemplatePayload = {
  name?: string;
  tags?: Tag[];
  sections?: ScorecardSectionType[];
};

/** Upload scorecard template payload type */
export type UpdateScorecardTemplatePayload = CreateScorecardTemplatePayload & {
  id: string;
};

/** Scorecard type */
export type Scorecard = {
  id: string;
  callSid: string;
  userId: string;
  user: {
    id: string;
    name: string;
    picture: string;
  };
  scorecardTemplateId: string;
  scorecardTemplate: {
    id: string;
    name: string;
    sections: ScorecardSectionType[];
  };
  createdAt: string;
  updatedAt: string;
};

/** Scorecard response type */
export type ScorecardResponse = {
  message: string;
  data: {
    scorecard: Scorecard;
  };
};

/** Create scorecard payload type */
export interface CreateScorecardPayload {
  callSid: string;
  scorecardTemplateId: string;
  answers: ScorecardAnswer[];
}

/** Update scorecard payload type */
export interface UpdateScorecardPayload {
  id: string;
  callSid: string;
  answers: ScorecardAnswer[];
}
