import { useCallback, useRef } from 'react';

const usePlayCallAudio = () => {
  const errorAudioRef = useRef(
    new Audio(`${process.env.REACT_APP_PUBLIC_BUCKET_BASE_URL}/twilio-audio/twilio-error.mp3`)
  );

  const hangUpAudioRef = useRef(
    new Audio(`${process.env.REACT_APP_PUBLIC_BUCKET_BASE_URL}/twilio-audio/end-call-audio.wav`)
  );

  const ringingSoundRef = useRef(
    new Audio(`${process.env.REACT_APP_PUBLIC_BUCKET_BASE_URL}/twilio-audio/outbound-call-ringing.wav`)
  );

  // Reduce hangup and ringing volume to 50%.
  hangUpAudioRef.current.volume = 0.5;
  ringingSoundRef.current.volume = 0.5;

  // Handles playing the hang-up audio.
  const playHangUpAudio = useCallback(async () => {
    if (!errorAudioRef.current.paused) return;

    const audio = hangUpAudioRef.current;
    await new Promise<void>((resolve) => {
      audio.play();

      // Play audio for 2 seconds.
      const timeoutId = setTimeout(() => {
        audio.pause();
        audio.currentTime = 0;
        resolve();
      }, 2000);

      // Clear the timeout if audio ends naturally.
      audio.onended = () => {
        clearTimeout(timeoutId);
        resolve();
      };
    });
  }, []);

  const pauseHangUpAudio = useCallback(() => {
    if (hangUpAudioRef.current.paused) return;
    hangUpAudioRef.current.pause();
    hangUpAudioRef.current.currentTime = 0;
  }, []);

  // Handles playing the error audio.
  const playErrorAudio = useCallback(
    async (setIsHangingUp: (isHangingUp: boolean) => void) => {
      const audio = errorAudioRef.current;
      await new Promise<void>((resolve) => {
        audio.play();
        audio.onended = async () => {
          setIsHangingUp(true);
          await playHangUpAudio();
          resolve();
        };
      });
    },
    [playHangUpAudio]
  );

  // Handles pausing the error audio.
  const pauseErrorAudio = useCallback(() => {
    if (errorAudioRef.current.paused) return;
    errorAudioRef.current.pause();
    errorAudioRef.current.currentTime = 0;
  }, []);

  // Handles playing the ringing audio.
  const playRingingAudio = useCallback(() => {
    if (!ringingSoundRef.current.paused) return;
    ringingSoundRef.current.loop = true;
    ringingSoundRef.current.play();
  }, []);

  // Handles pausing and resetting the ringing audio.
  const pauseRingingAudio = useCallback(() => {
    if (ringingSoundRef.current.paused) return;
    ringingSoundRef.current.pause();
    ringingSoundRef.current.currentTime = 0;
  }, []);

  return {
    playErrorAudio,
    pauseErrorAudio,
    playHangUpAudio,
    pauseHangUpAudio,
    playRingingAudio,
    pauseRingingAudio,
  };
};

export default usePlayCallAudio;
