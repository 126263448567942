import clsx from 'clsx';
import React from 'react';

// Interface for TableRow props extending HTML attributes for a table row.
interface TableRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  // Optional prop to indicate if the row is active.
  active?: boolean;
  // Optional click handler.
  onClick?: (e: React.MouseEvent) => void;
}

const TableRow = React.forwardRef<HTMLTableRowElement, TableRowProps>(({ active, onClick, ...props }, ref) => (
  <tr ref={ref} className={clsx(active && 'active', onClick && 'hover cursor-pointer')} onClick={onClick} {...props} />
));

TableRow.displayName = 'TableRow';

export default TableRow;
