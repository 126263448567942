import { KeyboardEvent, useCallback, useState } from 'react';
import { CANCELLED_VERIFICATION_ERROR_MSG, US_COUNTRY_CODE } from '../../../../../../constants';
import { useVerifyCallActions } from '../../../../../../hooks';
import { InitiateCallResponseData } from '../../../../../../types';
import { isValidPhoneNumber } from '../../../../../../utils';
import { ButtonVariant, PhoneNumberInput, TextButton } from '../../../../../shared';
import PhoneVerificationModal from '../../../../PhoneVerificationModal';

interface AddPhoneNumberProps {
  index: number;
  user: { id: string; phoneNumbers: string[] };
  onHide: () => void;
}

const AddPhoneNumber = ({ index, user, onHide }: AddPhoneNumberProps) => {
  // State
  const [callData, setCallData] = useState<InitiateCallResponseData | undefined>(undefined);
  const [callEnded, setCallEnded] = useState(false);
  const [countryCode, setCountryCode] = useState(US_COUNTRY_CODE); // Default to US country code.
  const [error, setError] = useState<string | undefined>(undefined);
  const [phoneNumber, setPhoneNumber] = useState('');

  const { initiateCall, isLoading } = useVerifyCallActions();

  const fullPhoneNumber = countryCode + phoneNumber;
  // Check the validity of  phone number
  const isVerifyEnabled = isValidPhoneNumber(fullPhoneNumber) && !error;

  const resetError = useCallback(() => {
    setError(undefined);
    setCallEnded(false);
  }, [setCallEnded, setError]);

  const onVerify = useCallback(async () => {
    // Check if the new number being saved already exists.
    if (user.phoneNumbers.includes(fullPhoneNumber)) {
      setError('Duplicate phone number');
      return;
    }

    resetError();

    const response = await initiateCall(countryCode, phoneNumber);
    if (response.data) {
      setCallData(response.data);
    } else {
      setError(response.error);
    }
  }, [countryCode, phoneNumber, initiateCall, resetError, setCallData, setError]);

  const onFinish = useCallback(() => {
    onHide();
    resetError();
  }, [onHide, resetError]);

  return (
    <>
      <div className="flex items-end gap-2">
        <PhoneNumberInput
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          countryCode={countryCode}
          setCountryCode={setCountryCode}
          onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter' && isVerifyEnabled) {
              onVerify();
            }
          }}
          isLoading={isLoading}
          error={error}
          resetError={resetError}
          showLabels={index === 0 && !user.phoneNumbers.length}
          message={callEnded ? CANCELLED_VERIFICATION_ERROR_MSG : undefined}
        />
        <TextButton
          text="Verify"
          onClick={onVerify}
          loading={isLoading}
          variant={ButtonVariant.OUTLINE}
          tooltip={isVerifyEnabled ? 'Initiate call to verify phone number' : undefined}
          disabled={!isVerifyEnabled}
          className={!!error || callEnded ? 'mb-4' : undefined}
        />
      </div>
      <PhoneVerificationModal
        callData={callData}
        setError={setError}
        userId={user.id}
        phoneNumber={fullPhoneNumber}
        onSuccess={onFinish}
        closeModal={() => setCallData(undefined)}
        setCallEnded={setCallEnded}
      />
    </>
  );
};

export default AddPhoneNumber;
