import { ButtonVariant } from '../../../../shared/Button/Button.types';
import { ButtonColor } from '../../../../shared/Button/Button.types';
import { TextButton } from '../../../../shared/Button';
import { ScorecardTemplate, ScorecardSectionType } from '../../../../../types/scorecards.types';
import {
  useArchiveScorecardTemplateMutation,
  useCreateScorecardTemplateMutation,
  useDeleteScorecardTemplateMutation,
  useUpdateScorecardTemplateMutation,
} from '../../../../../services';
import { useHandleApiResponse } from '../../../../../hooks';
import { Divider } from '../../../../shared';
import { useCallback, useState } from 'react';
import ScorecardInUseModal from './ScorecardInUseModal';
import ConfirmModal from '../../../ConfirmModal';

const SAVE_ERROR_MSG = 'Failed to save scorecard template';
const SAVE_SUCCESS_MSG = 'Scorecard template saved successfully';

const ARCHIVE_ERROR_MSG = 'Failed to archive scorecard';
const ARCHIVE_SUCCESS_MSG = 'Scorecard archived successfully';

const DELETE_ERROR_MSG = 'Failed to delete scorecard';
const DELETE_SUCCESS_MSG = 'Scorecard deleted successfully';

interface ScorecardFormActionButtonsProps {
  handleCloseScorecardForm: () => void;
  scorecard?: ScorecardTemplate;
  scorecardName?: string;
  sections?: ScorecardSectionType[];
}

const ScorecardFormActionButons = ({
  scorecard,
  scorecardName,
  sections,
  handleCloseScorecardForm,
}: ScorecardFormActionButtonsProps) => {
  // Mutations
  const [updateScorecardTemplate, { isLoading: isUpdatingScorecard }] = useUpdateScorecardTemplateMutation();
  const [createScorecardTemplate, { isLoading: isCreatingScorecard }] = useCreateScorecardTemplateMutation();

  const [deleteScorecardTemplate, { isLoading: isDeletingScorecard }] = useDeleteScorecardTemplateMutation();
  const [archiveScorecardTemplate, { isLoading: isArchivingScorecard }] = useArchiveScorecardTemplateMutation();

  // State
  const [isInUseModalOpen, setIsInUseModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  // Hooks
  const handleApiResponse = useHandleApiResponse();

  // Check if the scorecard is in use
  const isScorecardInUse = scorecard?.isUsedInScorecards;

  // Validate the scorecard data before saving
  // Scorecard name, at least 1 valid section and at least 1 question within each section are required
  const isSaveScorecardValid = () => {
    return (
      scorecardName?.trim() !== '' &&
      !!sections?.length &&
      sections?.every(
        (section) =>
          section.title.trim() !== '' &&
          section.questions.length > 0 &&
          section.questions.every((question) => !!question.text && !!question.type)
      )
    );
  };

  // Update the getSectionsWithOrder function
  const getSectionsWithOrder = useCallback(() => {
    return sections?.map((section, sectionIndex) => ({
      ...section,
      order: sectionIndex,
      questions: section.questions.map((question, questionIndex) => ({
        ...question,
        order: questionIndex,
      })),
    }));
  }, [sections]);

  // Delete a scorecard template
  const handleDeleteScorecard = useCallback(async () => {
    // If scorecard is not found/created, just close the modal on delete
    if (!scorecard || !scorecard.id) {
      handleCloseScorecardForm();
      return;
    }

    try {
      const response = await deleteScorecardTemplate(scorecard.id);

      await handleApiResponse({
        response,
        errorMsg: DELETE_ERROR_MSG,
        successMsg: DELETE_SUCCESS_MSG,
        onSuccess: handleCloseScorecardForm,
      });
    } catch (error) {
      console.error(`${DELETE_ERROR_MSG}: ${error}`);
    }
  }, [scorecard, deleteScorecardTemplate, handleApiResponse, handleCloseScorecardForm]);

  // Archive a scorecard template
  const handleArchiveScorecard = useCallback(async () => {
    if (!scorecard || !scorecard.id) {
      return;
    }

    try {
      const response = await archiveScorecardTemplate(scorecard.id);

      await handleApiResponse({
        response,
        errorMsg: ARCHIVE_ERROR_MSG,
        successMsg: ARCHIVE_SUCCESS_MSG,
        onSuccess: handleCloseScorecardForm,
      });
    } catch (error) {
      console.error(`${ARCHIVE_ERROR_MSG}: ${error}`);
    }
  }, [scorecard, archiveScorecardTemplate, handleApiResponse, handleCloseScorecardForm]);

  // Save the scorecard template
  const handleSaveScorecard = useCallback(
    async (name?: string) => {
      if (!sections?.length) return;

      const sectionsWithOrder = getSectionsWithOrder();
      const templateData = { name, sections: sectionsWithOrder };

      try {
        let response;
        if (scorecard?.id && !isScorecardInUse) {
          response = await updateScorecardTemplate({ id: scorecard.id, ...templateData });
        } else {
          response = await createScorecardTemplate(templateData);
        }

        await handleApiResponse({
          response,
          errorMsg: SAVE_ERROR_MSG,
          successMsg: SAVE_SUCCESS_MSG,
          onSuccess: handleCloseScorecardForm,
        });
      } catch (error) {
        console.error(`${SAVE_ERROR_MSG}: ${error}`);
      }
    },
    [
      sections,
      scorecard,
      isScorecardInUse,
      updateScorecardTemplate,
      createScorecardTemplate,
      handleApiResponse,
      handleCloseScorecardForm,
      getSectionsWithOrder,
    ]
  );

  // Confirm the new scorecard name and save the scorecard
  const handleConfirmNewScorecard = useCallback(
    (newScorecardName: string) => {
      if (!sections?.length) return;

      handleSaveScorecard(newScorecardName);
      setIsInUseModalOpen(false);
    },
    [sections?.length, handleSaveScorecard, setIsInUseModalOpen]
  );

  const destructiveAction = isScorecardInUse ? 'Archive' : 'Delete';
  const destructiveButtonOnClick = isScorecardInUse ? handleArchiveScorecard : handleDeleteScorecard;

  const destructiveButtonConfirmText = (
    <>
      Are you sure you want to {destructiveAction.toLowerCase()}&nbsp;
      <span className="font-medium">{scorecardName}</span>? This action cannot be undone.
    </>
  );

  return (
    <div className="flex flex-col gap-4">
      <Divider />
      <div className="flex items-end justify-end gap-2">
        <TextButton
          text={destructiveAction}
          onClick={() => setIsConfirmModalOpen(true)}
          variant={ButtonVariant.OUTLINE}
          color={ButtonColor.DESTRUCTIVE}
        />
        <TextButton
          text={isScorecardInUse ? 'Save as' : 'Save'}
          loading={isCreatingScorecard || isUpdatingScorecard}
          onClick={isScorecardInUse ? () => setIsInUseModalOpen(true) : () => handleSaveScorecard(scorecardName)}
          disabled={!isSaveScorecardValid()}
        />
      </div>
      <ScorecardInUseModal
        isOpen={isInUseModalOpen}
        setIsOpen={setIsInUseModalOpen}
        initialScorecardName={`Copy of ${scorecardName}`}
        onConfirm={handleConfirmNewScorecard}
      />
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        buttonText={destructiveAction}
        setIsOpen={setIsConfirmModalOpen}
        onConfirm={destructiveButtonOnClick}
        isLoading={isDeletingScorecard || isArchivingScorecard}
        title={`${destructiveAction} scorecard`}
        confirmText={destructiveButtonConfirmText}
        destructive
      />
    </div>
  );
};

export default ScorecardFormActionButons;
