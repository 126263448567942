import { useCallback, useEffect, useState } from 'react';

export enum MicPermissionState {
  GRANTED = 'granted',
  DENIED = 'denied',
  PROMPT = 'prompt',
}

const useCheckMicPermissions = () => {
  const [isCheckingPermissions, setIsCheckingPermissions] = useState(true);
  const [isPromptingMicPermissions, setIsPromptingMicPermissions] = useState(false);
  const [micPermissionState, setMicPermissionState] = useState<MicPermissionState | null>(null);

  useEffect(() => {
    // Check if mic permission is already enabled
    const checkMicPermissions = async () => {
      setIsCheckingPermissions(true);
      try {
        const permissionStatus = await navigator.permissions.query({ name: 'microphone' as PermissionName });
        setMicPermissionState(permissionStatus.state as MicPermissionState);

        // Listen for changes in permission state
        permissionStatus.onchange = () => {
          setMicPermissionState(permissionStatus.state as MicPermissionState);
        };
      } catch (error) {
        console.error('Error checking microphone permissions:', error);
        setMicPermissionState(null);
      } finally {
        setIsCheckingPermissions(false);
      }
    };

    checkMicPermissions();
  }, [setIsCheckingPermissions, setMicPermissionState]);

  // Function to request microphone access
  const requestMicAccess = useCallback(async () => {
    setIsPromptingMicPermissions(true);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      // If access is granted, stop all tracks to prevent the microphone from staying active
      stream.getTracks().forEach((track) => track.stop());
      setMicPermissionState(MicPermissionState.GRANTED);
    } catch (error) {
      setMicPermissionState(MicPermissionState.DENIED);
    } finally {
      setIsPromptingMicPermissions(false);
    }
  }, [setIsPromptingMicPermissions, setMicPermissionState]);

  return { micPermissionState, requestMicAccess, isLoading: isCheckingPermissions || isPromptingMicPermissions };
};

export default useCheckMicPermissions;
