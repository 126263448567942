export enum LD_ContextKinds {
  USER = 'user',
}

export enum LD_ContextRoles {
  SUPER_ADMIN = 'super-admin',
  SYSTEM_USER = 'system-user',
}

export enum LD_FeatureFlags {
  UNDER_CONSTRUCTION = 'under-construction',
  WEB_CALLING = 'release-web-calling',
  RELEASE_FLAGGING_CALLS = 'release-flagging-calls',
  RELEASE_INTEGRATIONS_TAB = 'release-integrations-tab',
  RELEASE_SCORECARDS = 'release-scorecards',
}
