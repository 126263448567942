import { ComponentSize, TextColor } from '../../../types';
import { BadgeType } from './Badge.types';

// Maps badge size to DaisyUI classes.
export const BADGE_SIZE: Record<ComponentSize, string> = {
  [ComponentSize.X_SMALL]: 'badge-xs',
  [ComponentSize.SMALL]: 'badge-sm',
  [ComponentSize.MEDIUM]: 'badge-md',
  [ComponentSize.LARGE]: 'badge-lg',
};

export const BADGE_ICON_SIZE: Record<ComponentSize, ComponentSize> = {
  [ComponentSize.X_SMALL]: ComponentSize.X_SMALL,
  [ComponentSize.SMALL]: ComponentSize.X_SMALL,
  [ComponentSize.MEDIUM]: ComponentSize.SMALL,
  [ComponentSize.LARGE]: ComponentSize.SMALL,
};

export const BADGE_TEXT_COLOR: Record<BadgeType, TextColor> = {
  [BadgeType.OUTLINE]: TextColor.PRIMARY,
  [BadgeType.ERROR]: TextColor.DESTRUCTIVE,
  [BadgeType.SUCCESS]: TextColor.SUCCESS,
  [BadgeType.GHOST]: TextColor.PRIMARY,
};

export const BADGE_HOVER_BG_COLOR: Record<BadgeType, string> = {
  [BadgeType.OUTLINE]: 'badge-neutral',
  [BadgeType.ERROR]: 'bg-base-300', // Currently unused. Remove once we implement media player scorecards.
  [BadgeType.SUCCESS]: 'bg-base-300', // Currently unused. Remove once we implement media player scorecards.
  [BadgeType.GHOST]: 'bg-base-300',
};

export const BADGE_HOVER_TEXT_COLOR: Record<BadgeType, TextColor> = {
  [BadgeType.OUTLINE]: TextColor.WHITE,
  [BadgeType.ERROR]: TextColor.DESTRUCTIVE,
  [BadgeType.SUCCESS]: TextColor.SUCCESS,
  [BadgeType.GHOST]: TextColor.PRIMARY,
};
