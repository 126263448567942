import { Alignment } from '../../../types';
import { TypographySize, TypographyWeight } from './Typography.types';

export const TYPOGRAPHY_ALIGNMENT: Record<Alignment, string> = {
  center: 'text-center',
  left: 'text-left',
};

export const TYPOGRAPHY_SIZE: Record<TypographySize, string> = {
  caption: 'text-xs',
  paragraph: 'text-sm',
  h5: 'text-base',
  h4: 'text-lg',
  h3: 'text-xl',
  h2: 'text-2xl',
  h1: 'text-4xl',
};

export const TYPOGRAPHY_WEIGHT: Record<TypographyWeight, string> = {
  normal: 'font-normal',
  medium: 'font-medium',
  'semi-bold': 'font-semibold',
  bold: 'font-bold',
};
