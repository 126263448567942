import { ComponentSize, TextColor } from '../../../../../types';
import { Icon, Icons, Typography, TypographySize, TypographyWeight } from '../../../../shared';

interface AITwinSubmitSuccessProps {
  handleSubmitAnother: () => void;
}

const AITwinSubmitSuccess = ({ handleSubmitAnother }: AITwinSubmitSuccessProps) => {
  return (
    <div className="flex flex-1 items-center justify-between gap-4">
      <div className="flex items-center gap-2">
        <Icons icon={Icon.CIRCLE_CHECK} color={TextColor.SUCCESS} size={ComponentSize.LARGE} />
        <Typography size={TypographySize.H4} weight={TypographyWeight.SEMI_BOLD}>
          Thank you!
        </Typography>
      </div>
      <Typography underline color={TextColor.SECONDARY} onClick={handleSubmitAnother}>
        Submit another?
      </Typography>
    </div>
  );
};

export default AITwinSubmitSuccess;
