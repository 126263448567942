import { useEffect, useRef, useState } from 'react';
import { SINGLE_AUDIO_WAVE_HEIGHT } from '../../../constants';
import { useGetCommentsQuery } from '../../../services';
import CommentAvatar from './CommentAvatar';

interface CommentsPanelProps {
  callSid: string;
  duration: number;
}

const CommentsPanel = ({ callSid, duration }: CommentsPanelProps) => {
  const panelRef = useRef<HTMLDivElement>(null);
  const [panelWidth, setPanelWidth] = useState(0);

  const { data: comments = [] } = useGetCommentsQuery(callSid);

  const parsedComments = comments.map(({ text, user, ...comment }) => ({
    comment: text,
    author: {
      name: user.name ?? user.email ?? '',
      imgSrc: user.picture,
    },
    ...comment,
  }));

  useEffect(() => {
    const updatePanelWidth = () => {
      if (panelRef.current) {
        setPanelWidth(panelRef.current.offsetWidth);
      }
    };

    // Initial width set
    updatePanelWidth();

    window.addEventListener('resize', updatePanelWidth);
    return () => {
      window.removeEventListener('resize', updatePanelWidth);
    };
  }, []);

  return (
    <div ref={panelRef} className="absolute left-0 z-10 h-4 w-full" style={{ top: -SINGLE_AUDIO_WAVE_HEIGHT / 2 }}>
      <div className="relative h-full w-full">
        {parsedComments.map(({ id, ...comment }) => (
          <CommentAvatar key={id} commentId={id} duration={duration} waveformWidth={panelWidth} {...comment} />
        ))}
      </div>
    </div>
  );
};

export default CommentsPanel;
