import { useCallback } from 'react';
import { useUploadFile } from '../../../../../hooks';
import { ButtonColor, ButtonVariant, FileInput, TextButton } from '../../../../shared';

interface AITwinSubmitFileProps {
  error: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
  setFileInput: React.Dispatch<React.SetStateAction<File | undefined>>;
  setIsFileSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  fileInput?: File;
}

const AITwinSubmitFile = ({ error, fileInput, setError, setFileInput, setIsFileSubmitted }: AITwinSubmitFileProps) => {
  const { uploadFile, isLoading: isUploadingFile } = useUploadFile();

  const runSubmit = useCallback(async () => {
    if (!fileInput) return;

    const result = await uploadFile(fileInput);

    if (result?.error) {
      setError(error);
    } else {
      setFileInput(undefined);
      setIsFileSubmitted(true);
    }
  }, [fileInput, uploadFile, setError, setFileInput, setIsFileSubmitted]);

  return (
    <div className="flex gap-2">
      <FileInput setSelectedFile={setFileInput} setError={setError} selectedFile={fileInput} error={error} />
      <TextButton
        key="submit"
        text="Submit"
        onClick={runSubmit}
        color={ButtonColor.SECONDARY}
        disabled={!fileInput}
        variant={ButtonVariant.OUTLINE}
        loading={isUploadingFile}
        className="mb-4"
      />
    </div>
  );
};

export default AITwinSubmitFile;
