import { Icon, Icons, Typography, TypographySize } from '../../../../components';
import { ComponentSize, TextColor } from '../../../../types';
import { formatDuration } from '../../../../utils';

type SummaryRowProps = {
  title: string;
  startTime: number;
  seekToAudio: () => void;
};

const SummaryRow = ({ title, startTime, seekToAudio }: SummaryRowProps) => {
  return (
    <div className="flex items-center gap-4">
      <div className="flex cursor-pointer items-center gap-1" onClick={seekToAudio}>
        <Icons icon={Icon.PLAY} size={ComponentSize.X_SMALL} color={TextColor.LINK} />
        <Typography size={TypographySize.H5} underline color={TextColor.LINK}>
          {formatDuration(startTime)}
        </Typography>
      </div>
      <Typography size={TypographySize.H5}>{title}</Typography>
    </div>
  );
};

export default SummaryRow;
