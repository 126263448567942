import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SETTINGS_ACTIVE_TAB_PARAM, SETTINGS_OPEN_PARAM } from '../../../constants';
import { useAppDispatch, useAppSelector, useFeatureFlag } from '../../../hooks';
import {
  SettingsModalTabs,
  closeSettingsModal,
  openSettingsModal,
  setSettingsActiveTab,
} from '../../../redux/reducers';
import { LD_FeatureFlags, Orientation, Permissions } from '../../../types';
import { conditionalArray } from '../../../utils';
import { Dialog, DialogType, Tab, Tabs, TabsType } from '../../shared';
import { HiringTab, OrganizationTab, ProfileTab, ScorecardsTab } from './tabs';
import { IntegrationsTab } from './tabs/IntegrationsTab';

const SettingsModal = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { permissions = [] } = useAppSelector((state) => state.auth.user) || {};
  const { isHiringModuleEnabled } = useAppSelector((state) => state.auth.organization) || {};
  const { isOpen, activeTab } = useAppSelector((state) => state.modal.settings);

  // Determine if the current user can view the hiring tab.
  const canViewHiring = permissions.includes(Permissions.VIEW_CANDIDATE);

  const integrationsFF = useFeatureFlag(LD_FeatureFlags.RELEASE_INTEGRATIONS_TAB);
  const scorecardsFF = useFeatureFlag(LD_FeatureFlags.RELEASE_SCORECARDS);

  const allFlagsLoaded = integrationsFF !== undefined && scorecardsFF !== undefined;

  const tabs: Tab[] = useMemo(
    () => [
      {
        id: SettingsModalTabs.PROFILE,
        title: 'Your account',
        content: <ProfileTab />,
      },
      {
        id: SettingsModalTabs.ORGANIZATION,
        title: 'Organization',
        content: <OrganizationTab />,
      },
      ...conditionalArray(scorecardsFF, {
        id: SettingsModalTabs.SCORECARDS,
        title: 'Scorecards',
        content: <ScorecardsTab />,
      }),
      ...conditionalArray(canViewHiring, {
        id: SettingsModalTabs.HIRING,
        title: 'Hiring',
        content: <HiringTab />,
        disabled: !isHiringModuleEnabled,
      }),
      ...conditionalArray(integrationsFF, {
        id: SettingsModalTabs.INTEGRATIONS,
        title: 'Integrations',
        content: <IntegrationsTab />,
      }),
    ],
    [isHiringModuleEnabled, integrationsFF, scorecardsFF, canViewHiring]
  );

  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  // Effect to open modal based on URL query params on mount or when location.search changes
  useEffect(() => {
    if (!allFlagsLoaded) return;

    // If the SETTINGS_OPEN_PARAM isn't present, do not proceed.
    if (!queryParams.has(SETTINGS_OPEN_PARAM)) {
      // Ensure the modal is closed if the param is not present.
      if (isOpen) dispatch(closeSettingsModal());
      return;
    }

    const openSettings = queryParams.get(SETTINGS_OPEN_PARAM);

    // If SETTINGS_OPEN_PARAM is not 'true', ensure the modal is closed and remove the param.
    if (openSettings !== 'true') {
      if (isOpen) dispatch(closeSettingsModal());
      return;
    }

    const defaultTabId = SettingsModalTabs.PROFILE;

    // If the SETTINGS_ACTIVE_TAB_PARAM isn't present, open to the default tab.
    if (!queryParams.has(SETTINGS_ACTIVE_TAB_PARAM)) {
      dispatch(openSettingsModal(defaultTabId));
      return;
    }

    // Get the tab param and check if it is valid.
    const tabParam = queryParams.get(SETTINGS_ACTIVE_TAB_PARAM);
    const tabId = tabParam as SettingsModalTabs;
    const isValidTabId = Object.values(SettingsModalTabs).includes(tabId);

    let targetTabId = defaultTabId;

    // If the tab param is a valid tab, consider setting it as the target tab.
    if (isValidTabId) {
      const tab = tabs.find((tab) => tab.id === tabId);
      const canOpen = !!tab && !tab.disabled;
      if (canOpen) {
        targetTabId = tabId;
      }
    }

    dispatch(openSettingsModal(targetTabId));
  }, [dispatch, queryParams, tabs, allFlagsLoaded]);

  // Effect to update URL query params when modal state changes.
  useEffect(() => {
    if (!allFlagsLoaded) return;

    if (isOpen) {
      // When modal opens, set SETTINGS_OPEN_PARAM to true and SETTINGS_ACTIVE_TAB_PARAM to activeTab.
      queryParams.set(SETTINGS_OPEN_PARAM, 'true');
      queryParams.set(SETTINGS_ACTIVE_TAB_PARAM, activeTab);
    } else {
      // When modal closes, clear SETTINGS_OPEN_PARAM and SETTINGS_ACTIVE_TAB_PARAM.
      queryParams.delete(SETTINGS_OPEN_PARAM);
      queryParams.delete(SETTINGS_ACTIVE_TAB_PARAM);
    }

    // Update the URL with the new query params.
    navigate({ pathname: location.pathname, search: queryParams.toString() }, { replace: true });
  }, [activeTab, isOpen, navigate, queryParams, allFlagsLoaded]);

  return (
    <Dialog
      title="Settings"
      isOpen={isOpen}
      onClose={() => dispatch(closeSettingsModal())}
      type={DialogType.SETTINGS}
      className="h-5/6 max-h-[700px]"
    >
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={(newActiveTab) => dispatch(setSettingsActiveTab(newActiveTab as SettingsModalTabs))}
        type={TabsType.BOXED}
        orientation={Orientation.VERTICAL}
      />
    </Dialog>
  );
};

export default SettingsModal;
