import { TextColor } from '../../../../../types';
import { TextInput, Typography } from '../../../../shared';
import TabHeader from '../TabHeader';
import IntegrationsSection from './IntegrationsSection';

const IntegrationsTab = () => {
  const onConnect = () => {
    return Math.random() < 0.5;
  };

  return (
    <div className="flex flex-col gap-8">
      <TabHeader title="Integrations" />
      <IntegrationsSection
        title="Gong OAuth"
        description="FullyRamped will gain read permissions to read data from your Gong instance. User must have Tech Admin role in Gong."
        onConnect={onConnect}
      />
      <IntegrationsSection
        title="Gong API Key"
        description={
          <>
            If OAuth is not available, you can provide an API access key instead. See Gong instructions&nbsp;
            <a
              className="underline"
              href="https://help.gong.io/docs/receive-access-to-the-api"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            .
          </>
        }
        onConnect={onConnect}
      >
        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-2">
            <Typography color={TextColor.SECONDARY}>Username / Access Key</Typography>
            <TextInput />
          </div>
          <div className="flex flex-col gap-2">
            <Typography color={TextColor.SECONDARY}>Password / Access Key Secret</Typography>
            <TextInput />
          </div>
        </div>
      </IntegrationsSection>
    </div>
  );
};

export default IntegrationsTab;
