import { Dispatch, SetStateAction } from 'react';
import { CustomSortingState, SortingOrder, TextColor } from '../../../types';
import { Icon, Icons } from '../Icons';

const SORT_ICON = {
  [SortingOrder.ASC]: Icon.ARROW_SORT_ASC,
  [SortingOrder.DESC]: Icon.ARROW_SORT_DESC,
};

interface SortableHeaderProps {
  // Current sorting state of the table.
  sorting: CustomSortingState;
  // Sorting ID of the current column.
  sortingId: string;
  // Column header.
  title: string;
  // Callback function to set the sorting state.
  setSorting: Dispatch<SetStateAction<CustomSortingState>>;
}

const SortableHeader = ({ sorting, sortingId, title, setSorting }: SortableHeaderProps) => {
  // Current sort order of the column.
  // Undefined if the table is not sorted by this column.
  const sortOrder = sorting.sortBy === sortingId ? sorting.sortOrder : undefined;

  // Icon reflecting the current sort order.
  const icon = sortOrder ? SORT_ICON[sortOrder] : Icon.ARROW_SORT_NONE;
  // Tooltip reflecting the next sort order.
  const tooltip = sortOrder === SortingOrder.DESC ? 'Sort ascending' : 'Sort descending';
  // Callback to toggle the sort order.
  const onClick = () =>
    setSorting({
      sortBy: sortingId,
      sortOrder: sortOrder === SortingOrder.DESC ? SortingOrder.ASC : SortingOrder.DESC,
    });

  return (
    <div className="flex items-center gap-2">
      <span>{title}</span>
      <Icons icon={icon} color={TextColor.SECONDARY} onClick={onClick} tooltip={tooltip} />
    </div>
  );
};

export default SortableHeader;
