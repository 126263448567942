import { Typography, TypographySize } from '../Typography';
import { TextColor } from '../../../types';

interface InputErrorProps {
  message: string;
}

const InputError = ({ message }: InputErrorProps) => (
  <div className="h-4">
    <Typography size={TypographySize.CAPTION} color={TextColor.DESTRUCTIVE}>
      {message}
    </Typography>
  </div>
);

export default InputError;
