import { ComponentSize, TextColor } from '../../../types';

export enum Icon {
  ARCHIVE = 'archive',
  ARROW_SORT_ASC = 'arrow-sort-ascending',
  ARROW_SORT_DESC = 'arrow-sort-descending',
  ARROW_SORT_NONE = 'arrows-sort-none',
  BAR_CHART = 'bar-chart',
  BRUSH = 'brush',
  CAPTION = 'caption',
  CHECK = 'check',
  CHEVRON_DOWN = 'chevron-down',
  CHEVRON_LEFT = 'chevron-left',
  CHEVRON_RIGHT = 'chevron-right',
  CHEVRON_UP = 'chevron-up',
  CHEVRONS_UP_DOWN = 'chevrons-up-down',
  CIRCLE_ALERT = 'circle-alert',
  CIRCLE_CHECK = 'circle-check',
  CIRCLE_HELP = 'circle-help',
  CIRCLE_X = 'circle-x',
  CLOSE = 'close',
  COMMENT = 'comment',
  CONCIERGE_BELL = 'concierge-bell',
  COPY = 'copy',
  DUPLICATE = 'copy-plus',
  EDIT = 'pencil',
  ELLIPSIS_VERTICAL = 'ellipsis-vertical',
  FAST_FORWARD = 'fastforward',
  FLAG = 'flag',
  GRIP_VERTICAL = 'grip-vertical',
  HANDSHAKE = 'handshake',
  HEADPHONES = 'headphones',
  HISTORY = 'history',
  INFO = 'info',
  INTEGRATIONS = 'integrations',
  LAPTOP = 'laptop',
  LINK = 'link',
  LOCK = 'lock',
  LOG_OUT = 'log-out',
  MAXIMIZE = 'maximize-2',
  MINIMIZE = 'minimize-2',
  MIC_ON = 'mic',
  MIC_OFF = 'mic-off',
  MINUS = 'minus',
  OPEN = 'open',
  ORGANIZATION = 'organization',
  PAUSE = 'pause',
  PHONE = 'phone',
  PHONE_OUTGOING = 'phone-outgoing',
  PLAY = 'play',
  PLUS = 'plus',
  REWIND = 'rewind',
  SCORECARDS = 'tally-5',
  SEARCH = 'search',
  SEND = 'send',
  SETTINGS = 'settings',
  SHARE = 'share',
  SPEED = 'speed',
  TRASH = 'trash',
  TRIANGLE_ALERT = 'triangle-alert',
  USER = 'user-round',
  USER_CHECK = 'user-check',
  USER_PLUS = 'user-plus',
  USER_SEARCH = 'user-search',
  WIFI_OFF = 'wifi-off',
  UPLOAD = 'upload',
  TRAFFIC_CONE = 'traffic-cone',
  WAND = 'wand-sparkles',
  ZEUS = 'zeus',
}

export type IconColor = TextColor | string;

export interface IconsProps {
  icon: Icon;
  className?: string;
  color?: IconColor;
  size?: ComponentSize | number;
  tooltip?: string;
  onClick?: () => void;
}

export type IconComponent = React.ReactElement<IconsProps>;
