import clsx from 'clsx';
import { ReactNode } from 'react';
import { ComponentSize } from '../../../types';
import { Spinner } from '../Spinner';

interface ButtonContentProps {
  children: ReactNode;
  size: ComponentSize;
  loading?: boolean;
}

const ButtonContent = ({ children, size, loading }: ButtonContentProps) => {
  return (
    <>
      {loading && (
        <span className="absolute inset-0 flex items-center justify-center">
          <Spinner size={size} />
        </span>
      )}
      <span className={clsx('flex items-center gap-2', loading && 'invisible')}>{children}</span>
    </>
  );
};

export default ButtonContent;
