import { createContext } from 'react';
import { AlertProps } from '../../components';

export interface ToastProps extends AlertProps {
  id: number;
}

interface ToastContextType {
  showToast: (props: AlertProps) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export default ToastContext;
