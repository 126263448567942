/** Width of the transcript section in pixels */
export const TRANSCRIPT_WIDTH = 800;

/** Width of the summary section in pixels */
export const SUMMARY_WIDTH = 800;

/** Width of the stats panel in pixels */
export const STATS_PANEL_WIDTH = 200;

/** Width of the scorecards section in pixels */
export const SCORECARDS_WIDTH = 324;
