import { useDispatch } from 'react-redux';
import type { AppDispatch } from '../redux/store';

/**
 * A typed version of the `useDispatch` hook from Redux.
 * It's intended to replace the plain `useDispatch` hook in your application to leverage TypeScript's type safety.
 *
 * Usage:
 * Instead of using `useDispatch` directly, use `useAppDispatch` to ensure that all dispatched actions are type-safe and comply with the types defined in your Redux store's dispatch function.
 */
const useAppDispatch = useDispatch.withTypes<AppDispatch>();

export default useAppDispatch;
