import { useCallback, useMemo } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { ComponentSize } from '../../types';
import { ButtonColor, Icon, IconButton } from '../shared';

interface MediaControlsProps {
  currentTime: number;
  duration: number;
  waveSurfer: React.MutableRefObject<WaveSurfer | null>;
}

const MediaControls = ({ currentTime, duration, waveSurfer }: MediaControlsProps) => {
  const isPlaying = useMemo(() => waveSurfer.current?.isPlaying(), [waveSurfer.current?.isPlaying()]);

  // Seek the audio by a specific offset.
  const seekAudio = useCallback(
    (offset: number) => {
      if (waveSurfer.current && duration) {
        const newTime = Math.max(currentTime + offset, 0);
        // If the new time is greater than the full duration of the audio,
        // reset the audio to the beginning and pause.
        if (newTime > duration) {
          waveSurfer.current.seekTo(0);
          waveSurfer.current.pause();
        } else {
          // Otherwise, seek to the new time.
          waveSurfer.current.seekTo(newTime / duration);
        }
      }
    },
    [waveSurfer.current, currentTime, duration]
  );

  // Rewind the audio by 15 seconds.
  const onRewind = () => {
    seekAudio(-15);
  };

  // Fast forward the audio by 15 seconds.
  const onFastForward = () => {
    seekAudio(15);
  };

  // Toggle play/pause state.
  const togglePlayPause = useCallback(() => {
    if (waveSurfer.current) {
      waveSurfer.current.playPause();
    }
  }, [waveSurfer.current]);

  return (
    <div className="flex items-center gap-2">
      <IconButton
        icon={Icon.REWIND}
        size={ComponentSize.MEDIUM}
        onClick={onRewind}
        tooltip="Rewind by 15s"
        color={ButtonColor.SECONDARY}
      />
      <IconButton
        icon={isPlaying ? Icon.PAUSE : Icon.PLAY}
        onClick={togglePlayPause}
        size={ComponentSize.LARGE}
        color={ButtonColor.SECONDARY}
      />
      <IconButton
        icon={Icon.FAST_FORWARD}
        color={ButtonColor.SECONDARY}
        size={ComponentSize.MEDIUM}
        onClick={onFastForward}
        tooltip="Fast forward by 15s"
      />
    </div>
  );
};

export default MediaControls;
