import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { closeZeusModal, setImpersonatedUser } from '../../../redux/reducers';
import { useGetImpersonatedUserQuery } from '../../../services';
import { ComponentSize, ImpersonateUserParams } from '../../../types';
import { ButtonColor, ButtonGroup, TextButton } from '../../shared';

interface ZeusModalActionsProps {
  selectedOrgId?: string;
  selectedUserId?: string;
}

const ZeusModalActions = ({ selectedOrgId, selectedUserId }: ZeusModalActionsProps) => {
  const [selectedUser, setSelectedUser] = useState<ImpersonateUserParams | undefined>();

  const dispatch = useAppDispatch();
  const impersonatedUser = useAppSelector((state) => state.auth.impersonatedUser);

  // RTK Query hook to fetch the impersonated user data when the selected user state is set.
  const { data } = useGetImpersonatedUserQuery(selectedUser || { orgId: '', impersonateUserId: '' }, {
    skip: !selectedUser,
  });

  // Start impersonation by setting the selected user state.
  // The selected user state is used to fetch the impersonated user data.
  const handleImpersonateUser = () => {
    if (selectedOrgId && selectedUserId) {
      setSelectedUser({ orgId: selectedOrgId, impersonateUserId: selectedUserId });
    }
  };

  // Cancel impersonation by clearing the impersonated user in Redux if it exists and closing the modal.
  const handleCancel = () => {
    dispatch(closeZeusModal());
    if (impersonatedUser) {
      dispatch(setImpersonatedUser(undefined));
    }
  };

  // Handles setting the impersonated user in Redux and closing the modal.
  useEffect(() => {
    if (data && selectedUser) {
      dispatch(setImpersonatedUser(data));
      dispatch(closeZeusModal());
    }
  }, [data, selectedUser, dispatch]);

  return (
    <ButtonGroup size={ComponentSize.MEDIUM}>
      <TextButton
        text="Impersonate user"
        onClick={handleImpersonateUser}
        color={ButtonColor.PRIMARY}
        disabled={!selectedUserId || selectedUserId === impersonatedUser?.id}
        loading={!!selectedUser && !data} // Loading state is shown while the impersonated user is being fetched.
      />
      <TextButton text={impersonatedUser ? 'Exit Zeus mode' : 'Cancel'} onClick={handleCancel} />
    </ButtonGroup>
  );
};

export default ZeusModalActions;
