import clsx from 'clsx';
import { TagColor } from '../../../types';
import { conditionalObject } from '../../../utils';

const BADGE_DOT_COLOR: Record<TagColor, string> = {
  [TagColor.BLUE]: 'bg-tags-blue',
  [TagColor.INDIGO]: 'bg-tags-indigo',
  [TagColor.LIME]: 'bg-tags-lime',
  [TagColor.ORANGE]: 'bg-tags-orange',
  [TagColor.PINK]: 'bg-tags-pink',
  [TagColor.PURPLE]: 'bg-tags-purple',
  [TagColor.RED]: 'bg-tags-red',
  [TagColor.TEAL]: 'bg-tags-teal',
  [TagColor.YELLOW]: 'bg-tags-yellow',
};

const isTagColor = (color: string): color is TagColor => {
  return Object.values(TagColor).includes(color as TagColor);
};

interface BadgeDotProps {
  color: TagColor | string;
}

const BadgeDot = ({ color }: BadgeDotProps) => {
  return (
    <div
      className={clsx('h-2 w-2 rounded-full', isTagColor(color) && BADGE_DOT_COLOR[color as TagColor])}
      style={{
        ...conditionalObject(!isTagColor(color), { backgroundColor: color }),
      }}
    />
  );
};

export default BadgeDot;
