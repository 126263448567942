import * as React from 'react';

const Table = React.forwardRef<HTMLTableElement, React.HTMLAttributes<HTMLTableElement>>(({ ...props }, ref) => (
  <div className="rounded-md border">
    <table ref={ref} className="table w-full table-fixed" {...props} />
  </div>
));

Table.displayName = 'Table';

export default Table;
