import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import { useEffect } from 'react';
import { REACT_APP_ENV } from '../app.config';
import { RUM_APPLICATION_VERSION } from '../constants';

export const useRUM = () => {
  useEffect(() => {
    try {
      if (REACT_APP_ENV === 'development') {
        return;
      }

      const RUM_IDENTITY_POOL_ID = process.env.REACT_APP_AWS_RUM_IDENTITY_POOL_ID;
      const RUM_APPLICATION_ID = process.env.REACT_APP_AWS_RUM_APPLICATION_ID;
      const RUM_REGION = process.env.REACT_APP_AWS_RUM_REGION;
      const ENABLE_RUM = process.env.REACT_APP_ENABLE_RUM === 'true';

      if (!ENABLE_RUM || !RUM_IDENTITY_POOL_ID || !RUM_APPLICATION_ID || !RUM_REGION) {
        console.error('RUM is disabled, required environment variables are not set, or environment is development');
        return;
      }

      const config: AwsRumConfig = {
        sessionSampleRate: 1, // 100% sampling rate
        identityPoolId: RUM_IDENTITY_POOL_ID,
        telemetries: ['performance', 'errors', 'http'],
        allowCookies: true,
        enableXRay: false,
      };

      const APPLICATION_ID: string = RUM_APPLICATION_ID;

      new AwsRum(APPLICATION_ID, RUM_APPLICATION_VERSION, RUM_REGION, config);
    } catch (error) {
      console.error('Error initializing CloudWatch RUM:', error);
    }
  }, []);
};
