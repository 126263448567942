import { withLDProvider } from 'launchdarkly-react-client-sdk';
import React, { useMemo } from 'react';
import { REACT_APP_ENV } from '../../app.config';
import { useAppSelector } from '../../hooks';
import { LD_ContextKinds } from '../../types/launch-darkly.types';
import { FeatureFlagProvider } from './FeatureFlagProvider';

const CLIENT_SIDE_ID = process.env.REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID as string;

interface LDProviderProps {
  children?: React.ReactNode;
}

/**
 * LaunchDarklyProvider uses the LaunchDarkly React SDK to initialize LaunchDarkly and provide context to its children.
 * It uses useMemo to reinitialize LaunchDarkly when the user changes or logs out.
 */
export default function LaunchDarklyProvider({ children }: LDProviderProps) {
  const { user } = useAppSelector((state) => state.auth);

  const context = {
    kind: LD_ContextKinds.USER,
    key: user ? user.id : 'anonymous',
    role: user ? user.role : undefined,
    custom: {
      environment: REACT_APP_ENV,
    },
    anonymous: !user,
  };

  const LDProviderHOC = useMemo(() => {
    return withLDProvider({
      clientSideID: CLIENT_SIDE_ID,
      context,
      deferInitialization: true,
      options: {
        streaming: false,
      },
    })(FeatureFlagProvider);
  }, [user?.id]);

  // @ts-expect-error because Launch Darkly is not typed perfectly
  return <LDProviderHOC>{children}</LDProviderHOC>;
}
