import { useState } from 'react';
import { TextColor } from '../../../../../types';
import { Accordion, Typography, TypographySize } from '../../../../shared';
import ItemWrapper from '../ItemWrapper';
import AITwinSubmitFile from './AITwinSubmitFile';
import AITwinSubmitLink from './AITwinSubmitLink';
import AITwinSubmitSuccess from './AITwinSubmitSuccess';

enum UploadType {
  FILE = 'FILE',
  LINK = 'LINK',
}

const AITwinTab = () => {
  const [fileInput, setFileInput] = useState<File | undefined>(undefined);
  const [linkInput, setLinkInput] = useState('');

  const [fileInputError, setFileInputError] = useState('');
  const [linkInputError, setLinkInputError] = useState('');

  const [isFileSubmitted, setIsFileSubmitted] = useState(false);
  const [isLinkSubmitted, setIsLinkSubmitted] = useState(false);

  const items = [
    {
      id: UploadType.FILE,
      title: <Typography size={TypographySize.H4}>Upload call transcript</Typography>,
      content: isFileSubmitted ? (
        <AITwinSubmitSuccess handleSubmitAnother={() => setIsFileSubmitted(false)} />
      ) : (
        <AITwinSubmitFile
          error={fileInputError}
          setError={setFileInputError}
          setFileInput={setFileInput}
          fileInput={fileInput}
          setIsFileSubmitted={setIsFileSubmitted}
        />
      ),
    },
    {
      id: UploadType.LINK,
      title: <Typography size={TypographySize.H4}>Share call link</Typography>,
      content: isLinkSubmitted ? (
        <AITwinSubmitSuccess handleSubmitAnother={() => setIsLinkSubmitted(false)} />
      ) : (
        <AITwinSubmitLink
          error={linkInputError}
          setError={setLinkInputError}
          setLinkInput={setLinkInput}
          linkInput={linkInput}
          setIsLinkSubmitted={setIsLinkSubmitted}
        />
      ),
    },
  ];

  return (
    <div className="flex flex-col gap-4">
      <Typography color={TextColor.SECONDARY} size={TypographySize.H5}>
        Practice Prospects based on real calls.
      </Typography>
      <Accordion items={items.map((item) => ({ ...item, content: <ItemWrapper>{item.content}</ItemWrapper> }))} />
    </div>
  );
};

export default AITwinTab;
