import { useEffect, useMemo, useState } from 'react';
import { Call, CallSpeaker, Utterance } from '../../../../types';
import TranscriptRow from './TranscriptRow';
import { useAppSelector } from '../../../../hooks';
import { getCallerInfo } from '../../../../utils';
import { TRANSCRIPT_WIDTH } from '../../../../constants';

interface TranscriptTabProps {
  seekAudio: (startTime: number) => void;
  call: Call;
}

const TranscriptTab = ({ call, seekAudio }: TranscriptTabProps) => {
  const user = useAppSelector((state) => state.auth.user);
  const [transcriptionData, setTranscriptData] = useState<Utterance[]>([]);

  // Get prospect name from call data.
  const prospectName = useMemo(() => {
    if (call) {
      const firstName = call.practiceProspect.firstName;
      const lastName = call.practiceProspect.lastName;
      return `${firstName} ${lastName}`;
    } else {
      return '';
    }
  }, [call?.practiceProspect]);

  // Get agent details from call data and user info.
  const agentDetails: { name: string; picture?: string } = useMemo(() => {
    if (call && user) {
      return getCallerInfo(call, user);
    } else {
      return { name: '' };
    }
  }, [call, user]);

  // Fetch transcription data.
  useEffect(() => {
    if (call?.transcriptionPath) {
      fetch(call.transcriptionPath)
        .then((response) => response.json())
        .then((data) => {
          setTranscriptData(data);
        })
        .catch((error) => {
          console.error('Error fetching transcription data:', error);
        });
    }
  }, [call?.transcriptionPath]);

  return (
    <div className="flex h-full flex-col px-4" style={{ maxWidth: TRANSCRIPT_WIDTH }}>
      {transcriptionData.map((transcript) => (
        <TranscriptRow
          key={transcript.id}
          startTime={transcript.start}
          seekToAudio={() => seekAudio(transcript.start)}
          speakerName={transcript.speaker === CallSpeaker.AGENT ? agentDetails.name : prospectName}
          speakerPicture={transcript.speaker === CallSpeaker.AGENT ? agentDetails.picture : undefined}
          {...transcript}
        />
      ))}
    </div>
  );
};

export default TranscriptTab;
