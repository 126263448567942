import clsx from 'clsx';
import { ComponentSize, TextColor } from '../../../types';
import { Tooltip, TooltipContent, TooltipTrigger } from '../Tooltip';
import { ICON_MAP, ICON_SIZE } from './Icons.constants';
import { IconsProps } from './Icons.types';

const Icons = ({
  icon,
  className,
  color = TextColor.PRIMARY,
  size = ComponentSize.SMALL,
  tooltip,
  onClick,
}: IconsProps) => {
  const IconComponent = ICON_MAP[icon];

  if (!IconComponent) return null;

  return (
    <Tooltip>
      <TooltipTrigger>
        <IconComponent
          className={clsx(color, onClick && 'cursor-pointer', className)}
          size={typeof size === 'number' ? size : ICON_SIZE[size]}
          onClick={onClick}
        />
      </TooltipTrigger>
      <TooltipContent>{tooltip}</TooltipContent>
    </Tooltip>
  );
};

export default Icons;
