import { createContext, useContext } from 'react';
import { useDropdown } from './useDropdown';

type ContextType =
  | (ReturnType<typeof useDropdown> & {
      // Function to set the ARIA label ID.
      setLabelId: React.Dispatch<React.SetStateAction<string | undefined>>;
      // Function to set the ARIA description ID.
      setDescriptionId: React.Dispatch<React.SetStateAction<string | undefined>>;
    })
  | null;

/** Creates a React context for the dropdown with a default value of null. */
const DropdownContext = createContext<ContextType>(null);

/**
 * Custom hook to use the DropdownContext.
 * Throws an error if the context is not found, ensuring that
 * the component is used within a Dropdown provider.
 */
export const useDropdownContext = () => {
  const context = useContext(DropdownContext);

  if (context == null) {
    throw new Error('Dropdown components must be wrapped in <Dropdown />');
  }

  return context;
};

export default DropdownContext;
