import * as RadixAccordion from '@radix-ui/react-accordion';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { ComponentSize, TextColor } from '../../../types';
import { Icon, Icons } from '../Icons';

interface AccordionTriggerProps {
  isActive?: boolean;
  disabled?: boolean;
}

const AccordionTrigger = forwardRef<HTMLButtonElement, React.HTMLAttributes<HTMLButtonElement> & AccordionTriggerProps>(
  ({ children, className, isActive, disabled, ...props }, forwardedRef) => {
    return (
      <RadixAccordion.Header className="AccordionHeader">
        <RadixAccordion.Trigger
          className={clsx(
            'AccordionTrigger flex w-full items-center justify-between',
            disabled && 'pointer-events-none',
            className
          )}
          {...props}
          ref={forwardedRef}
        >
          {children}
          <Icons
            size={ComponentSize.LARGE}
            icon={isActive ? Icon.MINUS : Icon.PLUS}
            color={disabled ? TextColor.TERTIARY : TextColor.SECONDARY}
          />
        </RadixAccordion.Trigger>
      </RadixAccordion.Header>
    );
  }
);

AccordionTrigger.displayName = 'AccordionTrigger';

export default AccordionTrigger;
