import { Dispatch, SetStateAction } from 'react';
import { RadioGroup, Typography, TypographySize, TypographyWeight, Range } from '../../../../components';
import { YES_NO_QUESTION_OPTIONS } from '../../../../constants';
import { QuestionType, ScorecardSectionType, TextColor, ScorecardAnswer } from '../../../../types';
import CallScorecardActionButtons from './CallScorecardActionButtons';
import clsx from 'clsx';

interface CallScorecardSectionsProps {
  /** Array of sections in the scorecard */
  sections: ScorecardSectionType[];
  /** Function to close the scorecard */
  handleCloseScorecard: () => void;
  /** Function to clear all answers */
  handleClearAnswers: () => void;
  /** Function to set the scorecard answers */
  setAnswers: Dispatch<SetStateAction<ScorecardAnswer[] | undefined>>;
  /** Flag indicating if the user can edit the scorecard */
  canUserEdit: boolean;
  /** ID of the scorecard */
  scorecardId?: string;
  /** ID of the scorecard template */
  scorecardTemplateId?: string;
  /** Name of the scorecard template */
  scorecardName?: string;
  /** Array of current scorecard answers */
  answers?: ScorecardAnswer[];
}

const CallScorecardSections = ({
  scorecardId,
  scorecardTemplateId,
  scorecardName,
  sections,
  answers,
  setAnswers,
  canUserEdit,
  handleCloseScorecard,
  handleClearAnswers,
}: CallScorecardSectionsProps) => {
  // Handles changing the answer for a question
  const handleAnswerChange = (questionId: string, value: string) => {
    // Check if user have permission to edit scorecard
    if (!canUserEdit) return;

    setAnswers((prevAnswers) => {
      // If there are no previous answers, create a new array with the current answer
      if (!prevAnswers) return [{ questionId, response: value }];

      let answerFound = false;
      // Map through existing answers, updating the matching question or keeping others as is
      const updatedAnswers = prevAnswers.map((answer) => {
        if (answer.questionId === questionId) {
          answerFound = true;
          return { ...answer, response: value }; // Update existing answer
        }
        return answer; // Keep other answers unchanged
      });

      // If no matching question was found, add the new answer to the array
      if (!answerFound) {
        updatedAnswers.push({ questionId, response: value });
      }

      // Return the updated answers array
      return updatedAnswers;
    });
  };

  // Retrieves the answer for a specific question
  const getAnswerForQuestion = (questionId: string) => {
    return answers?.find((answer) => answer.questionId === questionId)?.response || '';
  };

  return (
    <div className={clsx('flex h-full flex-grow flex-col justify-between gap-8', !canUserEdit && 'pb-4')}>
      {sections?.map((section) => (
        <div key={section.id} className="flex flex-col gap-2">
          <Typography size={TypographySize.H3} weight={TypographyWeight.SEMI_BOLD} color={TextColor.SECONDARY}>
            {section.title}
          </Typography>
          {section.questions.map((question) => (
            <div className="flex flex-col gap-2" key={question.id}>
              <Typography size={TypographySize.H5}>{question.text}</Typography>
              {question.type === QuestionType.RANGE && (
                <Range
                  value={Number(getAnswerForQuestion(question.id)) || 1}
                  onChange={(value) => handleAnswerChange?.(question.id, value.toString())}
                />
              )}
              {question.type === QuestionType.YES_NO && (
                <RadioGroup
                  options={YES_NO_QUESTION_OPTIONS}
                  value={getAnswerForQuestion(question.id)}
                  onChange={(e) => handleAnswerChange?.(question.id, e.target.value)}
                />
              )}
            </div>
          ))}
        </div>
      ))}
      {canUserEdit && (
        <CallScorecardActionButtons
          handleClearAnswers={handleClearAnswers}
          handleCloseScorecard={handleCloseScorecard}
          scorecardId={scorecardId}
          scorecardTemplateId={scorecardTemplateId}
          scorecardName={scorecardName}
          answers={answers}
        />
      )}
    </div>
  );
};

export default CallScorecardSections;
