import * as React from 'react';
import { TooltipOptions } from './Tooltip.types';
import TooltipContext from './TooltipContext';
import { useTooltip } from './useTooltip';

/** Tooltip component that provides context for tooltip functionality. */
function Tooltip({ children, ...options }: { children: React.ReactNode } & TooltipOptions) {
  // Use the useTooltip hook to get tooltip state and methods.
  const tooltip = useTooltip(options);

  // Provide the tooltip context to its children.
  return <TooltipContext.Provider value={tooltip}>{children}</TooltipContext.Provider>;
}

export default Tooltip;
