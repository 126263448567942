import React from 'react';
import { AvatarComponent } from '../Avatar/Avatar.types';

interface AvatarGroupProps {
  children: AvatarComponent[];
}

const AvatarGroup = ({ children }: AvatarGroupProps) => {
  return (
    <div className="avatar-group -space-x-5 rtl:space-x-reverse">
      {children.map((child) => React.cloneElement(child, { ring: true }))}
    </div>
  );
};

export default AvatarGroup;
