export enum AlertType {
  ERROR = 'error',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export interface AlertProps {
  message: string;
  title?: string;
  type?: AlertType;
}
