import clsx from 'clsx';
import { BANNER_HEIGHT, NAVBAR_HEIGHT } from '../../../constants';
import { useAppSelector } from '../../../hooks';

interface CallDetailsDrawerLayoutProps {
  isDrawerMounted: boolean;
  children: React.ReactNode;
  mediaPlayerRef?: React.RefObject<HTMLDivElement>;
}

const CallDetailsDrawerLayout = ({ isDrawerMounted, children, mediaPlayerRef }: CallDetailsDrawerLayoutProps) => {
  const banners = useAppSelector((state) => state.modal.banners);
  return (
    <div
      className={clsx(
        'fixed bottom-0 left-0 right-0 overflow-hidden bg-white transition-all duration-700 ease-in-out',
        isDrawerMounted ? 'translate-y-0' : 'translate-y-full'
      )}
      style={{
        paddingBottom: mediaPlayerRef?.current?.clientHeight,
        top: NAVBAR_HEIGHT + banners.length * BANNER_HEIGHT,
      }}
    >
      {children}
    </div>
  );
};

export default CallDetailsDrawerLayout;
