import dayjs from 'dayjs';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  ComponentSize,
  DateFormat,
  ScorecardStatus,
  ScorecardTemplate,
  SortingOrder,
  TextColor,
} from '../../../../../types';
import {
  Avatar,
  ButtonColor,
  Icon,
  IconButton,
  Spinner,
  Typography,
  TypographySize,
  TypographyWeight,
} from '../../../../shared';
import TableEmptyState from '../TableEmptyState';
import {
  useDuplicateScorecardTemplateMutation,
  useGetScorecardTemplatesQuery,
} from '../../../../../services/api.services';
import { useHandleApiResponse } from '../../../../../hooks';

const ERROR_MSG = 'Failed to duplicate scorecard';
const SUCCESS_MSG = 'Scorecard duplicated successfully!';

interface ScorecardsTableProps {
  onCreateNewScorecard: () => void;
  onEditScorecard: (scorecard: ScorecardTemplate) => void;
}

const ScorecardsTable = ({ onCreateNewScorecard, onEditScorecard }: ScorecardsTableProps) => {
  // APIs
  const { data: scorecardTemplates, isLoading } = useGetScorecardTemplatesQuery({
    sorting: {
      sortBy: 'updatedAt',
      sortOrder: SortingOrder.DESC,
    },
  });
  const [duplicateScorecardTemplate] = useDuplicateScorecardTemplateMutation();

  // Ref to the table
  const tableRef = useRef<HTMLDivElement>(null);

  const handleApiResponse = useHandleApiResponse();

  // Handles loading icon in duplicated scorecard
  const [duplicatingScorecardID, setDuplicatingScorecardID] = useState<string | null>(null);
  // State to scroll to the top of the table when a new scorecard is duplicated
  const [shouldScrollToTop, setShouldScrollToTop] = useState(false);

  // Scroll to the top of the table when a new scorecard is duplicated
  useEffect(() => {
    if (shouldScrollToTop && tableRef.current) {
      tableRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      setShouldScrollToTop(false);
    }
  }, [shouldScrollToTop]);

  const onDuplicateSuccess = useCallback(() => {
    // Reset the duplicating scorecard ID to null
    setDuplicatingScorecardID(null);
    // Set the flag to scroll to the top of the table
    setShouldScrollToTop(true);
  }, [setDuplicatingScorecardID, setShouldScrollToTop]);

  // Handles Scorecard Duplication
  const handleDuplicateScorecard = useCallback(
    async (scorecard: ScorecardTemplate) => {
      setDuplicatingScorecardID(scorecard.id);
      try {
        const response = await duplicateScorecardTemplate(scorecard.id);
        handleApiResponse({
          response,
          errorMsg: ERROR_MSG,
          successMsg: SUCCESS_MSG,
          onSuccess: onDuplicateSuccess,
        });
      } catch (error) {
        console.error(`${ERROR_MSG}: ${error}`);
      }
    },
    [duplicateScorecardTemplate, handleApiResponse, onDuplicateSuccess]
  );

  if (isLoading) {
    return (
      <div className="flex h-full items-center justify-center">
        <Spinner size={ComponentSize.MEDIUM} />
      </div>
    );
  }

  if (!scorecardTemplates?.length) {
    return (
      <TableEmptyState
        description="No scorecard templates"
        buttonText="Create new scorecard template"
        handleButtonClick={onCreateNewScorecard}
      />
    );
  }

  // Checks if a scorecard is archived
  const isScorecardArchived = (scorecard: ScorecardTemplate) => scorecard.status === ScorecardStatus.ARCHIVED;

  const archivedTextColor = TextColor.TERTIARY;

  return (
    <div className="display-scrollbar-lg flex h-full max-h-full flex-col gap-4 overflow-auto" ref={tableRef}>
      {scorecardTemplates?.map((scorecard) => (
        <div key={scorecard.id} className="flex items-center justify-between">
          <div className="flex flex-col gap-1">
            <Typography
              size={TypographySize.H5}
              color={isScorecardArchived(scorecard) ? archivedTextColor : undefined}
              weight={TypographyWeight.SEMI_BOLD}
            >
              {scorecard.name}
            </Typography>
            <div className="flex items-center gap-2">
              <Avatar
                size={ComponentSize.X_SMALL}
                label={scorecard.lastEditor.name}
                imgSrc={scorecard.lastEditor.picture}
                disabled={isScorecardArchived(scorecard)}
              />
              <Typography
                size={TypographySize.CAPTION}
                color={isScorecardArchived(scorecard) ? archivedTextColor : undefined}
              >
                {scorecard.lastEditor.name}
              </Typography>
              <Typography
                size={TypographySize.CAPTION}
                color={isScorecardArchived(scorecard) ? archivedTextColor : TextColor.SECONDARY}
              >
                {dayjs(scorecard.updatedAt).format(DateFormat.MONTH_DAY_TIME)}
              </Typography>
            </div>
          </div>

          <div className="flex gap-1">
            <IconButton
              icon={Icon.EDIT}
              color={ButtonColor.SECONDARY}
              size={ComponentSize.MEDIUM}
              onClick={() => onEditScorecard(scorecard)}
              disabled={scorecard.status === ScorecardStatus.ARCHIVED}
              tooltip="Edit"
            />
            <IconButton
              icon={Icon.DUPLICATE}
              color={ButtonColor.SECONDARY}
              size={ComponentSize.MEDIUM}
              onClick={() => handleDuplicateScorecard(scorecard)}
              loading={duplicatingScorecardID === scorecard.id}
              tooltip="Duplicate"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default ScorecardsTable;
