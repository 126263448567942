import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataTableAction, Icon } from '../../components';
import { AppRoutes } from '../../constants';
import { useAppDispatch, useShareCall } from '../../hooks';
import { setCurrentCallDetails } from '../../redux/reducers';
import { Call, CallProcessingStatus } from '../../types';
import { conditionalArray } from '../../utils';

/**
 * Custom hook to generate actions for call rows in the data table.
 */
const useCallActions = (onClose: () => void, clickedCall?: Call): DataTableAction[] => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { onShare } = useShareCall(clickedCall?.callSid, undefined, onClose);

  // Handles opening the media player to the specified call.
  const onListen = useCallback(
    (call: Call) => {
      dispatch(setCurrentCallDetails({ callSid: call.callSid, autoPlay: true }));
    },
    [dispatch]
  );

  // Handles opening the call details page of the selected call.
  const onOpen = useCallback(
    (call: Call) => {
      // Open the call details drawer
      // Set the callSid in Redux
      dispatch(setCurrentCallDetails({ callSid: call.callSid, isCallDetailsDrawerOpen: true }));
      // Navigate to the call history page with the callSid as a parameter
      navigate(`${AppRoutes.CALL_HISTORY}/${call.callSid}`);
    },
    [navigate]
  );

  // Handles opening the practice page filtered to the selected prospect.
  const onViewProspect = useCallback(
    (call: Call) => {
      const prospectId = call.practiceProspect.personaId;
      const searchParams = new URLSearchParams({ prospect: prospectId });
      navigate({ pathname: AppRoutes.PRACTICE, search: searchParams.toString() });
    },
    [navigate]
  );

  // Handles performing the provided call action
  // by calling the action with the clicked call if it exists and then closing the modal.
  const handleCallAction = useCallback(
    (action: (call: Call) => void) => {
      if (!clickedCall) return;
      action(clickedCall);
      onClose();
    },
    [clickedCall, onClose]
  );

  return [
    // Show 'Listen' action only if the call's audioPath exists.
    ...conditionalArray(!!clickedCall?.audioPath, {
      label: 'Listen',
      icon: Icon.PLAY,
      onClick: () => handleCallAction(onListen),
    }),
    // Show 'Processing...' if the call is still undergoing processing.
    ...conditionalArray(clickedCall?.processingStatus === CallProcessingStatus.PROCESSING, {
      label: 'Processing...',
      icon: Icon.OPEN,
    }),
    // Show 'Processing error' if processing has failed.
    ...conditionalArray(clickedCall?.processingStatus === CallProcessingStatus.PROCESSING_FAILED, {
      label: 'Processing error',
      icon: Icon.OPEN,
    }),
    // Show 'Open' action if processing has succeeded.
    ...conditionalArray(clickedCall?.processingStatus === CallProcessingStatus.PROCESSED, {
      label: 'Call details',
      icon: Icon.OPEN,
      onClick: () => handleCallAction(onOpen),
    }),
    {
      // 'View prospect' action.
      label: 'View prospect',
      icon: Icon.USER_SEARCH,
      onClick: () => handleCallAction(onViewProspect),
    },
    // Show 'Share' action if processing has succeeded.
    ...conditionalArray(clickedCall?.processingStatus === CallProcessingStatus.PROCESSED, {
      label: 'Share',
      icon: Icon.SHARE,
      successMsg: 'Link to call copied to clipboard',
      onClick: () => handleCallAction(onShare),
    }),
  ];
};

export default useCallActions;
