import { useMemo } from 'react';
import {
  FilterOptionsAndSelections,
  useAppSelector,
  useGetFiltersFromParams,
  useUpdateFilterParams,
} from '../../hooks';
import { useGetUsersForSelectQuery } from '../../services';
import { AppUser, CallHistoryFilterKeys, CallHistoryFilters as CallHistoryFiltersType, Roles } from '../../types';
import { formatDisplayedPhoneNumber, getSelectedOptions, parseOptionalSelectOption } from '../../utils';
import { SidebarDateFilter, SidebarSelectFilter } from './SidebarFilterFields';

/**
 * Creates caller options from user data.
 */
const getCallerOptions = (userData?: { users: AppUser[]; phoneNumbers?: string[] }) => {
  const mappedUsers = userData?.users.map(({ id, name = '' }) => ({ value: id, label: name })) || [];
  const mappedPhoneNumbers = (userData?.phoneNumbers || []).map((phoneNumber) => ({
    value: phoneNumber,
    label: formatDisplayedPhoneNumber(phoneNumber),
  }));
  return mappedUsers.concat(mappedPhoneNumbers);
};

interface CallHistoryFiltersProps {
  prospectOptionsAndSelections: FilterOptionsAndSelections;
  tagOptionsAndSelections: FilterOptionsAndSelections;
}

const CallHistoryFilters = ({ prospectOptionsAndSelections, tagOptionsAndSelections }: CallHistoryFiltersProps) => {
  const { user } = useAppSelector((state) => state.auth);
  const isSalesRep = user?.role === Roles.SALES_REP;

  // Retrieve filters from URL parameters.
  const filters: CallHistoryFiltersType = useGetFiltersFromParams();

  // Hook to update filter parameters in the URL.
  const updateFilterParams = useUpdateFilterParams();

  // Fetch users and phone numbers for the caller options.
  const { data: userData, isLoading: isLoadingUsers } = useGetUsersForSelectQuery();

  // Memoize caller and prospect options to avoid unnecessary recalculations.
  const callerOptions = useMemo(() => getCallerOptions(userData), [userData]);

  // Find the selected caller option based on the current filters.
  const selectedCallers = useMemo(
    () => getSelectedOptions(callerOptions, filters.caller),
    [callerOptions, filters.caller]
  );

  return (
    <>
      {/* Only show the Caller filter if the user is not a Sales Representative */}
      {!isSalesRep && (
        <SidebarSelectFilter
          options={callerOptions}
          placeholder="Select caller"
          selected={selectedCallers}
          title="Caller"
          onChange={(newValue) => updateFilterParams(CallHistoryFilterKeys.CALLER, newValue)}
          loading={isLoadingUsers}
        />
      )}
      {/* Prospect filter */}
      <SidebarSelectFilter
        placeholder="Select prospect"
        title="Prospect"
        onChange={(newValue) => updateFilterParams(CallHistoryFilterKeys.PROSPECT, newValue)}
        {...prospectOptionsAndSelections}
      />
      {/* Tags filter */}
      <SidebarSelectFilter
        title="Tags"
        placeholder="Select tag"
        onChange={(newValue) => updateFilterParams(CallHistoryFilterKeys.TAGS, newValue)}
        {...tagOptionsAndSelections}
      />
      {/* Call Date filter */}
      <SidebarDateFilter
        filterKey={CallHistoryFilterKeys.CALL_DATE}
        title="Call date"
        onChange={(newValue) => updateFilterParams(CallHistoryFilterKeys.CALL_DATE, newValue)}
        selected={parseOptionalSelectOption(filters.callDate)}
      />
    </>
  );
};

export default CallHistoryFilters;
