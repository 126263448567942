import { useRef } from 'react';
import { useDetectTextOverflow } from '../../../hooks';
import { ComponentSize, DropdownPlacement, PracticeProspect, TextColor } from '../../../types';
import { Avatar, Typography, TypographySize, TypographyWeight } from '../../shared';

const NAME_MAX_LINES = 2;
const ACCOUNT_MAX_LINES = 1;
const JOB_TITLE_MAX_LINES = 1;

interface WebCallProspectProps {
  prospect: PracticeProspect;
}

const WebCallProspect = ({ prospect }: WebCallProspectProps) => {
  const fullName = `${prospect.firstName} ${prospect.lastName}`;

  const nameRef = useRef<HTMLDivElement>(null);
  const isNameOverflowing = useDetectTextOverflow(nameRef, NAME_MAX_LINES);

  const accountRef = useRef<HTMLDivElement>(null);
  const isAccountOverflowing = useDetectTextOverflow(accountRef, ACCOUNT_MAX_LINES);

  const jobTitleRef = useRef<HTMLDivElement>(null);
  const isJobTitleOverflowing = useDetectTextOverflow(jobTitleRef, JOB_TITLE_MAX_LINES);

  const getTooltip = (text: string) => ({ content: text, placement: DropdownPlacement.BOTTOM });

  return (
    <div className="flex items-center gap-4">
      <Avatar label={fullName} size={ComponentSize.MEDIUM} />
      <div className="flex flex-col gap-1">
        <Typography
          size={TypographySize.H5}
          weight={TypographyWeight.SEMI_BOLD}
          ref={nameRef}
          maxLines={NAME_MAX_LINES}
          tooltip={isNameOverflowing ? getTooltip(fullName) : undefined}
        >
          {fullName}
        </Typography>
        <Typography
          size={TypographySize.CAPTION}
          color={TextColor.SECONDARY}
          ref={accountRef}
          maxLines={ACCOUNT_MAX_LINES}
          tooltip={isAccountOverflowing ? getTooltip(prospect.accountName) : undefined}
        >
          {prospect.accountName}
        </Typography>
        <Typography
          size={TypographySize.CAPTION}
          color={TextColor.SECONDARY}
          ref={jobTitleRef}
          maxLines={JOB_TITLE_MAX_LINES}
          tooltip={isJobTitleOverflowing ? getTooltip(prospect.jobTitle) : undefined}
        >
          {prospect.jobTitle}
        </Typography>
      </div>
    </div>
  );
};

export default WebCallProspect;
