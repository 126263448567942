import { useState } from 'react';
import { TextColor } from '../../../../../types';
import { Icon, Icons, TextButton, Typography, TypographySize, TypographyWeight } from '../../../../shared';

interface IntegrationsSectionProps {
  title: string;
  description: React.ReactNode;
  onConnect: () => boolean;
  children?: React.ReactNode;
}
const IntegrationsSection = ({ children, title, description, onConnect }: IntegrationsSectionProps) => {
  const [connectionStatus, setConnectionStatus] = useState<boolean | undefined>(undefined);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-1">
        <Typography size={TypographySize.H5} weight={TypographyWeight.SEMI_BOLD}>
          {title}
        </Typography>
        <Typography size={TypographySize.H5} color={TextColor.SECONDARY}>
          {description}
        </Typography>
      </div>
      {children}
      <div className="flex items-center gap-4">
        <TextButton
          text="Connect"
          onClick={() => {
            const status = onConnect();
            setConnectionStatus(status);
          }}
          disabled={connectionStatus === true}
        />
        {connectionStatus !== undefined && (
          <div className="flex items-center gap-2">
            <Icons
              icon={connectionStatus ? Icon.CHECK : Icon.CLOSE}
              color={connectionStatus ? 'text-primary' : TextColor.DESTRUCTIVE}
            />
            <Typography color={connectionStatus ? 'text-primary' : TextColor.DESTRUCTIVE}>
              {connectionStatus ? 'Connected' : 'Connection failed'}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default IntegrationsSection;
