import clsx from 'clsx';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { ROLE_LABEL } from '../../../../../constants';
import { useAppSelector, useHandleApiResponse, useToast } from '../../../../../hooks';
import { useEditUserRoleMutation } from '../../../../../services';
import { AppUser, ComponentSize, Permissions, Roles } from '../../../../../types';
import { getRoleOptions, isHigherRole } from '../../../../../utils';
import { AlertType, Select, SelectOption, UserBadge } from '../../../../shared';
import DisableConfirmModal from './DisableConfirmModal';
import EnableConfirmModal from './EnableConfirmModal';
import RevokeConfirmModal from './RevokeConfirmModal';

const ROLE_EDIT_ERROR_MSG = 'Failed to edit user role';

const parseRole = (role: Roles) => ({ label: ROLE_LABEL[role], value: role });

enum UserActions {
  DISABLE = 'disable',
  ENABLE = 'enable',
  REVOKE_INVITE = 'revoke-invite',
}

interface UserRowProps {
  rowUser: {
    id: string;
    disabled?: boolean;
    email?: string;
    isInvitee?: boolean;
    name?: string;
    picture?: string;
    role?: Roles;
  };
  setUsers: Dispatch<SetStateAction<AppUser[]>>;
}

const UserRow = ({ rowUser, setUsers }: UserRowProps) => {
  // State
  const [isDisableConfirmOpen, setIsDisableConfirmOpen] = useState(false);
  const [isEnableConfirmOpen, setIsEnableConfirmOpen] = useState(false);
  const [isRevokeConfirmOpen, setIsRevokeConfirmOpen] = useState(false);

  // Custom hooks
  const { showToast } = useToast();
  const handleApiResponse = useHandleApiResponse();

  // Mutations
  const [editUserRole, { isLoading: isEditLoading }] = useEditUserRoleMutation();

  const currUser = useAppSelector((state) => state.auth.user);

  if (!currUser?.id || !currUser.role) return null;

  const isCurrUser = currUser.id === rowUser.id;
  const isSuperAdmin = currUser.permissions?.includes(Permissions.ADMIN_ACCESS);
  const canEditRole = !isCurrUser && (isSuperAdmin || isHigherRole(currUser.role, rowUser.role));

  // Role select options.
  const getSelectOptions = useCallback(() => {
    if (!canEditRole) return [];

    if (rowUser.isInvitee) {
      return [{ label: 'Revoke invitation', value: UserActions.REVOKE_INVITE, destructive: true }];
    }

    // Only Super Admins can re-enable users.
    if (rowUser.disabled) {
      if (currUser.permissions?.includes(Permissions.ADMIN_ACCESS)) {
        return [{ label: 'Enable user', value: UserActions.ENABLE }];
      } else {
        return [];
      }
    }

    // A user can only assign roles that are lower than theirs,
    // so we filter out roles that are higher than the current user's.
    const options: SelectOption[] = getRoleOptions(currUser);

    // Filter out SUPER_ADMIN_ROLE if the rowUser's email doesn't end with "@fullyramped.com"
    const filteredOptions = options.filter(
      (option) =>
        option.value !== Roles.SUPER_ADMIN ||
        rowUser.email?.endsWith('@fullyramped.com') ||
        rowUser.role === Roles.SUPER_ADMIN
    );

    const optionsWithDisable = filteredOptions.concat({
      label: 'Disable user',
      value: UserActions.DISABLE,
      destructive: true,
      divider: true,
    });

    return optionsWithDisable;
  }, [currUser, rowUser]);

  // Handles editing a user's role.
  const handleEditUserRole = useCallback(
    async (newRole: Roles) => {
      try {
        const response = await editUserRole({ id: rowUser.id, role: newRole });

        const onSuccess = () => {
          // Optimistically update user state.
          setUsers((prev) =>
            prev.map((user) => {
              return user.id === rowUser.id ? { ...user, role: newRole } : user;
            })
          );
        };

        handleApiResponse({ response, errorMsg: ROLE_EDIT_ERROR_MSG, onSuccess });
      } catch (error) {
        console.error(`${ROLE_EDIT_ERROR_MSG}: `, error);
        showToast({ message: ROLE_EDIT_ERROR_MSG, type: AlertType.ERROR });
      }
    },
    [handleApiResponse, setUsers, showToast]
  );

  // Handle selection change in role select dropdown.
  const onSelectChange = useCallback(
    (value?: string) => {
      switch (value) {
        case UserActions.DISABLE:
          // On selecting 'Disable user', open the Disable confirm modal.
          setIsDisableConfirmOpen(true);
          break;
        case UserActions.REVOKE_INVITE:
          // On selecting 'Revoke invitation', open the Revoke invitation confirm modal.
          setIsRevokeConfirmOpen(true);
          break;
        case UserActions.ENABLE:
          // On selecting 'Enable user', open the Enable confirm modal.
          setIsEnableConfirmOpen(true);
          break;
        default:
          // Otherwise, handle editing the user's role.
          handleEditUserRole(value as Roles);
      }
    },
    [handleEditUserRole, setIsRevokeConfirmOpen]
  );

  // Get selected role for the dropdown.
  const getSelectedRole = useCallback(() => {
    if (rowUser.disabled) {
      return { label: 'Disabled', value: 'disabled' };
    } else if (rowUser.role) {
      return parseRole(rowUser.role);
    }
  }, [rowUser.disabled, rowUser.role, parseRole]);

  return (
    <>
      <div className={clsx('flex justify-between py-4', rowUser.disabled && 'opacity-50')}>
        {/* User details */}
        <UserBadge
          title={`${rowUser.name} ${isCurrUser ? '(You)' : ''}`}
          subtitle={rowUser.name !== rowUser.email ? rowUser.email : undefined}
          size={ComponentSize.MEDIUM}
          imgSrc={rowUser.picture}
          isInvitee={rowUser.isInvitee}
        />
        {/* End actions */}
        <div className="flex w-44 items-center gap-2">
          <Select
            selected={getSelectedRole()}
            options={getSelectOptions()}
            onChange={onSelectChange}
            loading={isEditLoading}
            tooltip={!canEditRole ? 'You do not have permission to edit this role' : undefined}
            placeholder="Select role"
          />
        </div>
      </div>
      <DisableConfirmModal
        isOpen={isDisableConfirmOpen}
        setIsOpen={setIsDisableConfirmOpen}
        user={rowUser}
        setUsers={setUsers}
      />
      <EnableConfirmModal
        isOpen={isEnableConfirmOpen}
        setIsOpen={setIsEnableConfirmOpen}
        user={rowUser}
        setUsers={setUsers}
      />
      <RevokeConfirmModal isOpen={isRevokeConfirmOpen} setIsOpen={setIsRevokeConfirmOpen} user={rowUser} />
    </>
  );
};

export default UserRow;
