import { Getter, PaginationState } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DataTableColumnDef,
  DataTableWithActions,
  SortableHeader,
  TagsAndNotesCell,
  Typography,
  TypographySize,
  UserBadge,
} from '../../components';
import { DATE_COLUMN_WIDTH, PAGINATION_PAGE_SIZE } from '../../constants';
import { useAppDispatch, useAppSelector, useFeatureFlag, useGetFiltersFromParams } from '../../hooks';
import { setPracticeProspectsList, startWebCall, updateLastLoginTime } from '../../redux/reducers';
import { useGetPracticeProspectsMutation } from '../../services';
import {
  CustomSortingState,
  DateFormat,
  LD_FeatureFlags,
  PracticeFilters,
  ProspectColumn,
  ProspectColumnData,
  ProspectSortingFilters,
  SortingOrder,
  TextColor,
} from '../../types';
import { formatDisplayedPhoneNumber, getDateFilter } from '../../utils';
import PracticePageFooter from './PracticePageFooter';
import useProspectActions from './useProspectActions';

const NOTES_COLUMN_WIDTH = '50%';
const PHONE_NUMBER_COLUMN_WIDTH = '15%';

const PracticePage = () => {
  // State to track the clicked row.
  const [clickedRowIndex, setClickedRowIndex] = useState<number | undefined>(undefined);
  // State for pagination settings.
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: PAGINATION_PAGE_SIZE,
  });
  // State for sorting settings.
  const [sorting, setSorting] = useState<CustomSortingState>({
    sortBy: ProspectSortingFilters.CREATED_AT,
    sortOrder: SortingOrder.DESC,
  });

  // Redux
  const dispatch = useAppDispatch();
  const lastLoginTime = useAppSelector((state) => state.auth.lastLoginTime);
  const prospects = useAppSelector((state) => state.practice.prospects);

  // Mutations and queries
  const [getPracticeProspects, { data, isLoading }] = useGetPracticeProspectsMutation();
  const totalPages = data?.pagination.totalPages || 0;

  const clickedProspect = useMemo(() => {
    if (clickedRowIndex === undefined) return;
    return prospects[clickedRowIndex];
  }, [prospects, clickedRowIndex]);

  // Custom hooks
  const webCallFF = useFeatureFlag(LD_FeatureFlags.WEB_CALLING);
  const actions = useProspectActions(() => setClickedRowIndex(undefined), clickedProspect);
  const filters: PracticeFilters = useGetFiltersFromParams();
  const { isUploadModalEnabled } = useAppSelector((state) => state.auth.organization) || {};

  // Reset to page 1 when filters change.
  useEffect(() => {
    if (pagination.pageIndex !== 1) {
      setPagination((prev) => ({ ...prev, pageIndex: 1 }));
    }
  }, [filters, setPagination]);

  // Fetch prospects whenever filters, pagination or sorting settings change.
  useEffect(() => {
    getPracticeProspects({
      accountName: filters.prospectAccount,
      createdDate: getDateFilter(filters.dateCreated),
      personaId: filters.prospect,
      search: filters.search,
      tags: filters.tags,
      pagination,
      sorting,
    });
  }, [filters, pagination, sorting]);

  // This effect sets the list of prospects for the Practice Page in state.
  // It allows for optimistic updates to the state instead of refetching from the server.
  useEffect(() => {
    if (isLoading) return;
    dispatch(setPracticeProspectsList(data?.prospects || []));
    return () => {
      dispatch(setPracticeProspectsList([]));
    };
  }, [data?.prospects, isLoading]);

  // Parse prospects data to match the table format.
  // This includes transforming and combining data fields to fit the DataTable's expected row structure.
  const parsedProspects: ProspectColumn[] = prospects.map((prospect) => ({
    phoneNumber: prospect.associatedPhoneNumber,
    prospect: {
      id: prospect.personaId,
      company: prospect.accountName,
      jobTitle: prospect.jobTitle,
      name: `${prospect.firstName} ${prospect.lastName}`,
      tags: prospect.tags,
    },
    notes: prospect.notes,
    createdAt: prospect.createdAt,
  }));

  const isNewSinceLastLogin = useCallback(
    (date: Date): boolean => {
      if (lastLoginTime) {
        // If the last login time is set,
        // check if the date is after the last login time.
        return dayjs(date).isAfter(dayjs(lastLoginTime));
      } else {
        // If the last login time is not set, update it with the current time
        // and return false.
        dispatch(updateLastLoginTime());
        return false;
      }
    },
    [dispatch, lastLoginTime]
  );

  const renderSortableHeader = useCallback(
    (sortingId: ProspectSortingFilters, title: string) => (
      <SortableHeader title={title} sorting={sorting} setSorting={setSorting} sortingId={sortingId} />
    ),
    [sorting, setSorting]
  );

  // Define columns for the data table.
  const columns: DataTableColumnDef<ProspectColumn>[] = useMemo(
    () => [
      {
        // Column definition for displaying formatted phone numbers that are clickable for dialing.
        accessorKey: 'phoneNumber',
        header: () => renderSortableHeader(ProspectSortingFilters.ASSOCIATED_PHONE_NUMBER, 'Phone number'),
        width: PHONE_NUMBER_COLUMN_WIDTH,
        cell: ({ row }) => {
          const number = row.original.phoneNumber;
          const formattedNumber = formatDisplayedPhoneNumber(number);
          return (
            <Typography
              underline
              onClick={() => {
                if (webCallFF) {
                  dispatch(startWebCall({ prospect: prospects[row.index] }));
                }
              }}
            >
              {!webCallFF && <a href={`tel:${number}`}>{formattedNumber}</a>}
              {webCallFF && formattedNumber}
            </Typography>
          );
        },
      },
      {
        accessorKey: 'prospect',
        header: () => renderSortableHeader(ProspectSortingFilters.FIRST_NAME, 'Prospect'),
        cell: ({ getValue }: { getValue: Getter<ProspectColumnData> }) => {
          const { name, jobTitle, company } = getValue();
          return <UserBadge title={name} subtitle={`${company} | ${jobTitle}`} />;
        },
      },
      {
        // Column for any additional notes.
        accessorKey: 'tagsAndNotes',
        header: 'Tags & Notes',
        width: NOTES_COLUMN_WIDTH,
        cell: ({ row }) => {
          const { prospect, notes } = row.original;
          return (
            prospect.id &&
            prospect.tags && (
              <TagsAndNotesCell notes={notes} prospectId={prospect.id} prospectTags={prospect.tags} enableManageTags />
            )
          );
        },
      },
      {
        // Column for displaying the date created,
        // highlighting if it was within the last two days.
        accessorKey: 'createdAt',
        header: () => renderSortableHeader(ProspectSortingFilters.CREATED_AT, 'Date created'),
        width: DATE_COLUMN_WIDTH,
        cell: ({ getValue }: { getValue: Getter<Date> }) => {
          const dateString = getValue();
          if (!dateString) {
            return <Typography size={TypographySize.CAPTION}>N/A</Typography>;
          }

          const dateObj = new Date(getValue());
          const isNew = isNewSinceLastLogin(dateObj);
          return (
            <div className="flex flex-col gap-1">
              {isNew && (
                <Typography color={TextColor.DESTRUCTIVE} size={TypographySize.CAPTION}>
                  NEW
                </Typography>
              )}
              <Typography size={TypographySize.CAPTION}>{dayjs(dateObj).format(DateFormat.MONTH_DAY)}</Typography>
            </div>
          );
        },
      },
    ],
    [isNewSinceLastLogin, prospects, renderSortableHeader, webCallFF]
  );

  return (
    <div className="flex flex-col items-center gap-8">
      <DataTableWithActions
        actions={actions}
        columns={columns}
        isLoading={isLoading}
        data={parsedProspects}
        clickedRowIndex={clickedRowIndex}
        setClickedRowIndex={setClickedRowIndex}
        paginationControls={{ pagination, totalPages, setPagination }}
      />
      {isUploadModalEnabled && <PracticePageFooter />}
    </div>
  );
};

export default PracticePage;
