import { NUM_LOADING_SKELETONS } from '../../../../../../constants';
import { useGetCandidatesQuery } from '../../../../../../services';
import { Divider, Skeleton } from '../../../../../shared';
import TableEmptyState from '../../TableEmptyState';
import CandidateRow from './CandidateRow';

interface CandidateTableProps {
  handleCreateNew: () => void;
}

const CandidateTable = ({ handleCreateNew }: CandidateTableProps) => {
  const { data: candidates = [], isLoading } = useGetCandidatesQuery();

  if (isLoading) {
    return (
      <>
        {Array.from({ length: NUM_LOADING_SKELETONS }, (_, index) => (
          <div key={index}>
            <div className="flex justify-between py-4">
              <Skeleton />
            </div>
            {index < NUM_LOADING_SKELETONS - 1 && <Divider />}
          </div>
        ))}
      </>
    );
  }
  return (
    <>
      {!candidates.length && (
        <TableEmptyState
          description="Your organization has no candidates at the moment."
          buttonText="Create new candidate"
          handleButtonClick={handleCreateNew}
        />
      )}
      {!!candidates.length && (
        <div className="display-scrollbar-lg h-full max-h-full overflow-auto">
          {candidates.map(({ candidateId, ...candidate }, index) => (
            <div key={candidateId}>
              <CandidateRow candidateId={candidateId} {...candidate} />
              {index < candidates.length - 1 && <Divider />}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default CandidateTable;
