import { parsePhoneNumber } from 'libphonenumber-js';

/**
 * Capitalizes the first letter of a given string.
 */
export function capitalizeFirstLetter(str: string): string {
  if (str.length === 0) return str;
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

/**
 * Capitalizes the first letter of the string or all words in the string based on the flag.
 */
const capitalizeString = (str: string, capitalizeAllWords?: boolean): string => {
  if (capitalizeAllWords) {
    // Split the string into words, capitalize each word, and join them back with spaces
    return str.split(' ').map(capitalizeFirstLetter).join(' ');
  } else {
    // Capitalize only the first word of the string
    return capitalizeFirstLetter(str.toLowerCase());
  }
};

/**
 * Converts a camel-case string to a label format.
 * If capitalizeAllWords is true, all words will be capitalized.
 * Otherwise, only the first word will be capitalized.
 */
export function camelCaseToLabel(str?: string, capitalizeAllWords?: boolean) {
  if (!str) return '';

  // Convert the camel case string into space-separated words.
  const label = str.replace(/([A-Z])/g, ' $1').trim();
  return capitalizeString(label, capitalizeAllWords);
}

/**
 * Converts a kebab-case string to a label format.
 * If capitalizeAllWords is true, all words will be capitalized.
 * Otherwise, only the first word will be capitalized.
 */
export function kebabCaseToLabel(str?: string, capitalizeAllWords?: boolean) {
  if (!str) return '';

  // Replace hyphens with spaces.
  const label = str.replace(/-/g, ' ').trim();
  return capitalizeString(label, capitalizeAllWords);
}

/**
 * Converts a snake_case string to a label format.
 * If capitalizeAllWords is true, all words will be capitalized.
 * Otherwise, only the first word will be capitalized.
 */
export function snakeCaseToLabel(str?: string, capitalizeAllWords?: boolean) {
  if (!str) return '';

  // Replace underscores with spaces.
  const label = str.replace(/_/g, ' ').trim();
  return capitalizeString(label, capitalizeAllWords);
}

/**
 * Formats a duration in seconds into a string of format H:MM:SS or M:SS.
 */
export function formatDuration(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);

  const formattedMinutes = `${hours && minutes < 10 ? '0' : ''}${minutes}`;
  const formattedSeconds = `${secs < 10 ? '0' : ''}${secs}`;

  return `${hours ? `${hours}:` : ''}${formattedMinutes}:${formattedSeconds}`;
}

/**
 * Formats a duration in seconds into a string of format H h M m S s.
 */
export function formatDurationVerbose(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);

  const formattedHours = hours > 0 ? `${hours}h ` : '';
  const formattedMinutes = minutes > 0 ? `${minutes}m ` : '';
  const formattedSeconds = `${secs}s`;

  return `${formattedHours}${formattedMinutes}${formattedSeconds}`.trim();
}

/**
 * Formats a US phone number string to the standard format as (XXX) XXX-XXXX.
 */

export const formatUSPhoneNumber = (phoneNumber: string) => {
  // Remove all non-digit characters except for the leading +
  const cleanedNumber = phoneNumber.replace(/(?!^\+)\D/g, '');

  // Use regex to match and format the phone number, accounting for optional +1
  const matches = cleanedNumber.match(/^(\+1)?(\d{3})(\d{3})(\d{4})$/);

  if (matches) {
    // Destructure the matches array to get the relevant parts
    const [, countryCode, areaCode, centralOfficeCode, lineNumber] = matches;
    const formattedNumber = `(${areaCode}) ${centralOfficeCode}-${lineNumber}`;
    return countryCode ? `${countryCode} ${formattedNumber}` : formattedNumber;
  }
  // Return the original if it doesn't match the expected pattern
  return phoneNumber;
};

/**
 * Formats a phone number string to the standard format with the country code.
 */
export function formatDisplayedPhoneNumber(phoneNumber: string) {
  if (phoneNumber === 'Anonymous') {
    // Return unformatted text if phone number is 'Anonymous'.
    return phoneNumber;
  }

  try {
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
    if (!parsedPhoneNumber.isValid() || parsedPhoneNumber.isPossible() === false) {
      // Return unformatted text if invalid
      return phoneNumber;
    }

    const formattedNumber = parsedPhoneNumber.formatInternational();

    if (parsedPhoneNumber.country === 'US') return formatUSPhoneNumber(formattedNumber);

    return formattedNumber;
  } catch (error) {
    console.error('Failed to format phone number: ', phoneNumber, error);
    // If parsing fails, return the original unformatted phone number
    return phoneNumber;
  }
}

/** Utility function to convert size values to string with 'px' if needed. */
export const convertToPx = (value?: number | string) => (typeof value === 'number' ? `${value}px` : value);
