import clsx from 'clsx';
import { FocusEvent, KeyboardEvent, forwardRef, useCallback } from 'react';
import { ComponentSize } from '../../../../types';
import { Icons } from '../../Icons';
import { INPUT_CLASSES, INPUT_DISABLED_CLASSES, INPUT_ERROR_CLASSES } from '../Input.constants';
import '../Input.css';
import InputError from '../InputError';
import { INPUT_SIZE } from './TextInput.constants';
import { TextInputProps, TextInputType } from './TextInput.types';

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      className,
      disabled,
      startElement,
      startIcon,
      error,
      size = ComponentSize.SMALL,
      endElement,
      placeholder,
      type = TextInputType.TEXT,
      value = '',
      width,
      onBlur,
      onChange,
      onClick,
      onKeyDown,
    },
    ref
  ) => {
    // The input is not editable if it has no onChange handler or is disabled.
    const readOnly = !onChange || disabled;
    // The input field is clickable if it has an onClick handler and is not disabled.
    const clickable = onClick && !disabled;

    const handleClick = useCallback(
      (e: React.MouseEvent<HTMLInputElement>) => {
        // If the input is not editable or clickable, do not handle the click event.
        if (readOnly && !clickable) return;

        // Stop event propagation and the default action unless the input is a file input.
        // This allows the file input to open the file explorer.
        if (type !== TextInputType.FILE) {
          e.stopPropagation();
          e.preventDefault();
        }

        if (onClick) {
          onClick(e);
        }
      },
      [readOnly, clickable, type, onClick]
    );

    const handleBlur = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        if (onBlur) {
          event.stopPropagation();
          onBlur(event);
        }
      },
      [onBlur]
    );

    const handleKeyDown = useCallback(
      (event: KeyboardEvent<HTMLInputElement>) => {
        if (onKeyDown) {
          event.stopPropagation();
          onKeyDown(event);
        }
      },
      [onKeyDown]
    );

    return (
      <div className={clsx(!width && 'w-full', className)} style={{ width }}>
        <label
          className={clsx(
            'input input-bordered',
            INPUT_CLASSES,
            INPUT_SIZE[size],
            disabled && 'input-disabled' && INPUT_DISABLED_CLASSES,
            error && INPUT_ERROR_CLASSES
          )}
        >
          {startElement && <div className="min-w-fit">{startElement}</div>}
          {startIcon && <Icons icon={startIcon} />}
          <input
            ref={ref}
            type={type}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            onChange={onChange}
            className={clsx('w-full', clickable && 'cursor-pointer')}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            readOnly={readOnly}
            data-1p-ignore={type !== TextInputType.PASSWORD}
            onClick={handleClick}
          />
          {endElement && endElement}
        </label>
        {typeof error === 'string' && <InputError message={error} />}
      </div>
    );
  }
);

TextInput.displayName = 'TextInput';
export default TextInput;
