import { useCallback, useState } from 'react';
import { useHandleApiResponse, useToast } from '../../../../../../hooks';
import { useDeleteCandidateMutation } from '../../../../../../services';
import { TextColor } from '../../../../../../types';
import { formatDisplayedPhoneNumber } from '../../../../../../utils';
import {
  AlertType,
  ButtonColor,
  ButtonVariant,
  Icon,
  IconButton,
  Typography,
  TypographySize,
} from '../../../../../shared';
import ConfirmModal from '../../../../ConfirmModal';

const ERROR_MSG = 'Failed to archive candidate';

interface CandidateRowProps {
  candidateId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

const CandidateRow = ({ candidateId, firstName, lastName, phoneNumber }: CandidateRowProps) => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const fullName = `${firstName} ${lastName}`;

  const { showToast } = useToast();
  const handleApiResponse = useHandleApiResponse();

  const [deleteCandidate, { isLoading }] = useDeleteCandidateMutation();

  const handleArchiveCandidate = useCallback(async () => {
    try {
      const response = await deleteCandidate(candidateId);
      handleApiResponse({ response, errorMsg: ERROR_MSG, onSuccess: () => setIsConfirmOpen(false) });
    } catch (error) {
      console.error(`${ERROR_MSG}: `, error);
      showToast({ message: ERROR_MSG, type: AlertType.ERROR });
    }
  }, [candidateId, handleApiResponse, deleteCandidate]);

  return (
    <>
      <div className="py-4">
        <div className="flex items-center justify-between">
          <Typography size={TypographySize.H5}>{fullName}</Typography>
          <div className="flex items-center gap-4">
            <Typography size={TypographySize.H5} color={TextColor.SECONDARY}>
              {formatDisplayedPhoneNumber(phoneNumber)}
            </Typography>
            <IconButton
              icon={Icon.ARCHIVE}
              onClick={() => setIsConfirmOpen(true)}
              variant={ButtonVariant.GHOST}
              color={ButtonColor.DESTRUCTIVE}
              tooltip="Archive candidate"
            />
          </div>
        </div>
      </div>
      <ConfirmModal
        title="Archive candidate"
        buttonText="Archive"
        isLoading={isLoading}
        confirmText={
          <>
            Are you sure you want to archive&nbsp;
            <span className="font-medium">{fullName}</span>? This action cannot be undone.
          </>
        }
        isOpen={isConfirmOpen}
        setIsOpen={setIsConfirmOpen}
        onConfirm={handleArchiveCandidate}
      />
    </>
  );
};

export default CandidateRow;
