import { useCallback } from 'react';
import { useSubmitLink } from '../../../../../hooks';
import { ButtonColor, ButtonVariant, TextButton, TextInput } from '../../../../shared';

interface AITwinSubmitLinkProps {
  error: string;
  linkInput: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
  setLinkInput: React.Dispatch<React.SetStateAction<string>>;
  setIsLinkSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
}

const AITwinSubmitLink = ({ error, linkInput, setError, setLinkInput, setIsLinkSubmitted }: AITwinSubmitLinkProps) => {
  const { submitLink, isLoading: isSubmittingLink } = useSubmitLink();

  const onLinkChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setLinkInput(e.target.value);
      setError('');
    },
    [setError, setLinkInput]
  );

  const runSubmit = useCallback(async () => {
    if (!linkInput || !!error) return;

    const result = await submitLink(linkInput);

    if (result?.error) {
      setError(result.error);
    } else {
      setLinkInput('');
      setIsLinkSubmitted(true);
    }
  }, [error, linkInput, submitLink, setError, setIsLinkSubmitted, setLinkInput, setIsLinkSubmitted]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        runSubmit();
      }
    },
    [runSubmit]
  );

  return (
    <div className="flex gap-2">
      <TextInput placeholder="https://" value={linkInput} onChange={onLinkChange} error={error} onKeyDown={onKeyDown} />
      <TextButton
        text="Submit"
        onClick={runSubmit}
        color={ButtonColor.SECONDARY}
        disabled={!linkInput}
        variant={ButtonVariant.OUTLINE}
        loading={isSubmittingLink}
        className="mb-4"
      />
    </div>
  );
};

export default AITwinSubmitLink;
